const images = {
  //gig homepage images
  image2: require("./image2.png"),
  tata_logo_image: require("./tata_logo.svg").default,
  adani_logo_image: require("./adani_logo.svg").default,
  lt_logo_image: require("./lt_logo.svg").default,
  adityabirla_logo_image: require("./adityabirla_logo.svg").default,
  allen_logo_image: require("./allen_logo.svg").default,
  strategic_consulting_image: require("./strategic_consulting_icon.svg")
    .default,
  business_strategy_image: require("./business_strategy_icon.svg").default,
  salesforce_effectiveness_image: require("./salesforce_effectiveness_icon.svg")
    .default,
  supply_chain_image: require("./supply_chain_icon.svg").default,
  hr_consulting_image: require("./hr_consulting_icon.svg").default,
  hr_outsourcing_image: require("./hr_outsourcing_icon.svg").default,
  project_management_image: require("./project_management_icon.svg").default,
  line_bar_image: require("./line_bar_image.svg").default,
  business_partners_image: require("./business_partners_image.png"),
  taqa_image: require("./taqa_image.png"),
  blog_image1: require("./blog_image1.png"),
  blog_image2: require("./blog_image2.png"),
  blog_image3: require("./blog_image3.png"),
  profile1: require("./profile1.jpg"),
  profile2: require("./profile2.jpg"),
  profile3: require("./profile3.jpg"),
  profile4: require("./profile4.jpg"),
  profile5: require("./profile5.jpg"),
  profile6: require("./profile6.jpg"),
  x_icon: require("./x_icon.svg").default,
  facebook_icon: require("./facebook_icon.svg").default,
  instagram_icon: require("./instagram_icon.svg").default,
  linkedin_icon: require("./linkedin_icon.svg").default,
  ezyconsult_white_image: require("./ezyconsult_image.svg").default,
  ezyconsult_icon: require("./ezyconsult_icon.png"),
  logo_final_full_dark: require("./logo_final_full_dark.png"),
  logo_final_full_lite: require("./logo_final_full_lite.png"),

  //prosculpt
  login_image: require("./login-image.svg").default,
  employee_symbol: require("./employee_symbol.svg").default,
  otp_verification: require("./otp-verification.svg").default,
  circle_tick: require("./circle_tick.svg").default,
  ezyconseil_logo_color: require("./ezyconseil_logo_color.svg").default,
  ezyconseil_logo_white: require("./ezyconseil_logo_white.svg").default,
  ezyconseil_logo_white_png: require("./ezyconseil_logo_white_png.png"),

  //no results
  no_result: require("./no-results-found.svg").default,
  no_saved_results: require("./no-saved-results.svg").default,
  editIcon: require("./editIcon.svg").default,
  // footer icons
  twitter: require("./twitter.svg").default,
  youtube: require("./youtube.svg").default,
  instagram: require("./instagram.svg").default,
  facebook: require("./facebook.svg").default,
  cielLogo: require("./cielLogo.svg").default,
  mafoiLogo: require("./mafoi.svg").default,
  line: require("./line.svg").default,
  intership_image: require("./internship_image.svg").default,
  pdf_icon: require("./pdf-icon.svg").default,
  mp4_icon: require("./mp4-icon.svg").default,
  // Student flow
  successTick: require("./SuccessTick.svg").default,
  // campus flow
  NoIndustry: require("./NoIndustry.svg").default,
  pdfImage: require("./pdfImage.svg").default,
  // community
  shareIcon: require("./shareIcon.svg").default,
  alert: require("./alert.svg").default,
  logout: require("./logout.svg").default,
  empty_dashboard_campus: require("./empty-dashboard-campus.png"),
  proscultp_logo_colored: require("./proscultp-logo-colored.png"),
  EZY_LOGO_white: require("./ezyconsult_image.svg").default,
  pdf: require("./pdf.svg").default,
  no_jobs_view: require("./no-jobs-view.svg").default,
  separator: require("./separator.svg").default
};

export default images;
