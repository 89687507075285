import create from "zustand";

const getStore = create((set) => ({
  // selectedStudentNavBarItem: { id: 0, name: "Dashboard" },
  // setSelectedStudentNavBarItem: (selectedStudentNavBarItem) =>
  //   set({ selectedStudentNavBarItem }),
  loading: false,
  setLoading: (loading) => set({ loading }),
}));

export default getStore;
