import { gql } from "@apollo/client";

export const CREATE_ENQUIRY = gql`
  mutation createEnquiry($payload: EnquiryInput!) {
    createEnquiry(payload: $payload) {
      status
      message
    }
  }
`;

export const UPDATE_ENQUIRY = gql`
  mutation updateEnquiry($payload: UpdateEnquiryInput!) {
    updateEnquiry(payload: $payload) {
      status
      message
    }
  }
`;

export const DELETE_ENQUIRY = gql`
  mutation deleteEnquiry($id: String!) {
    deleteEnquiry(id: $id) {
      status
      message
    }
  }
`;

export const GET_ALL_ENQUIRIES = gql`
  query getAllEnquiries($filter: EnquiryFilterInput, $limit: Int, $page: Int) {
    getAllEnquiries(filter: $filter, limit: $limit, page: $page) {
      count
      enquiry {
        id
        companyName
        industryId
        projectCategoryId
        domain {
          id
          service {
            id
            name
          }
          details
        }
        user {
          id
          firstName
          lastName
        }
        # websiteLink
        # requirement
        spocName
        spocDesignation
        # spocPhone
        # spocEmail
        createdDate
        enquiryStatus
        # comment
        city {
          id
          name
        }
        createdDate
      }
    }
  }
`;

export const GET_ENQUIRY = gql`
  query getEnquiry($id: String!) {
    getEnquiry(id: $id) {
      id
      companyName
      industryId
      projectCategoryId
      domain {
        id
        service {
          id
          name
        }
        details
      }
      # userId
      websiteLink
      requirement
      spocName
      spocDesignation
      spocEmail
      spocPhone
      enquiryStatus
      comment
      city {
        id
        name
      }
      state {
        id
        name
      }
    }
  }
`;
