import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Checkbox,
  Button
} from "@mui/material";
import { colors } from "../../../styles/colors";
import { Box } from "@mui/system";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import images from "assets/images/images";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import { CheckBox, DeleteOutline } from "@mui/icons-material";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../components/CommonModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddCertificationDetails = (props) => {
  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    isEditing,
    setIsEditing,
    data,
    disabled
  } = props;

  //   const { rolePermissions } = getJwtTokenDetails();

  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    handleSubmit();
  };

  const handleSpinTheWheelChange = (event) => {
    const { name, checked } = event.target;
    const spinTheWheelCheckboxesArr = values.priorities?.map(
      (item) => item.spinTheWheel
    );
    const spinTheWheelCount = spinTheWheelCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (spinTheWheelCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };
  const handleMysteryBoxChange = (event) => {
    const { name, checked } = event.target;
    const mysteryBoxCheckboxesArr = values.priorities?.map(
      (item) => item.mysteryBox
    );
    const mysteryBoxCount = mysteryBoxCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (mysteryBoxCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };

  const handleAdd = () => {
    const tempId = data?.length + 1;
    data.push({
      id: tempId,
      priority: "Priority 4",
      minValue: "0",
      maxValue: "1000",
      name: "lorem"
    });
  };

  // const certificationsTemp = data?.certification;
  // ? JSON.parse(data?.certification)
  // : null;

  // console.log("certificationsTemp", certificationsTemp);

  // const certificationsData = certificationsTemp
  //   ? JSON.parse(certificationsTemp)
  //   : [];

  // const certificationsArr = values.certifications || certificationsTemp || [];
  // const certificationsArr = values.certifications || certificationsData || [];

  const certificationsTemp = data?.certification;

  let certificationsArr =
    values.certifications && values.certifications?.length > 0
      ? [...values.certifications]
      : certificationsTemp
      ? [...certificationsTemp]
      : [];

  console.log("certificationsArr", certificationsArr);

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>(null);

  const FormModal = () => {
    const [saveClicked, setSaveClicked] = React.useState<any>(false);
    const [name, setName] = React.useState<any>(selectedRow?.name || "");
    const [issuedBy, setIssuedBy] = React.useState<any>(
      selectedRow?.issuedBy || ""
    );
    const [issuedOn, setIssuedOn] = React.useState<any>(
      selectedRow?.issuedOn || null
    );

    const index = selectedIndex;

    return (
      <Modal open={open} setOpen={setOpen}>
        <Grid container spacing={2} mt={-9}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start"
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight={500}
              color={colors.fontLight}
              textAlign="center"
            >
              Certification details
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Title<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={name || ""}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                disabled={disabled}
              />
              {/* {errors.certifications &&
                errors.certifications?.length > 0 &&
                errors?.certifications[index] &&
                errors?.certifications[index]?.name && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 14
                    }}
                  >
                    {errors?.certifications[index]?.name}
                  </Typography>
                )} */}
              {saveClicked && name === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Certifying Organization
              <span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                type="max"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={issuedBy || ""}
                onChange={(e) => {
                  setIssuedBy(e.target.value);
                }}
                disabled={disabled}
              />
              {/* {errors.certifications &&
                errors.certifications?.length > 0 &&
                errors?.certifications[index] &&
                errors?.certifications[index]?.issuedBy && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.certifications[index]?.issuedBy}
                  </Typography>
                )} */}
              {saveClicked && issuedBy === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Issued On
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={issuedOn}
                  onChange={(value) => {
                    setIssuedOn(value);
                  }}
                  disabled={disabled || false}
                  renderInput={(params) => (
                    <TextField size="small" {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
              {/* {errors.certifications &&
                errors.certifications?.length > 0 &&
                errors?.certifications[index] &&
                errors?.certifications[index]?.issuedOn && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.certifications[index]?.issuedOn}
                  </Typography>
                )} */}
              {saveClicked && issuedOn === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="end"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (name !== "" && issuedBy !== "" && issuedOn !== "") {
                  if (selectedRow) {
                    const temp = certificationsArr?.map((item, index2) => {
                      if (index !== index2) {
                        return item;
                      } else {
                        return {
                          name,
                          issuedBy,
                          issuedOn
                        };
                      }
                    });
                    console.log("certifications temp", temp);
                    setFieldValue("certifications", [...temp]);
                    setOpen(false);
                  } else {
                    setFieldValue("certifications", [
                      {
                        name,
                        issuedBy,
                        issuedOn
                      },
                      ...certificationsArr
                    ]);
                    setOpen(false);
                  }
                } else {
                  setSaveClicked(true);
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  };

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: colors.white,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "0px 0px 4px 4px "
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: colors.white }}>
            <TableRow>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Name of Certification
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Certifying Organization
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Issued On
                </Typography>
              </TableCell>
              {!disabled && (
                <TableCell sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Actions
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {certificationsArr && certificationsArr?.length > 0 ? (
              certificationsArr?.map((row, index) => (
                <>
                  <TableRow key={row?.id}>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>{row?.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>{row?.issuedBy}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>
                          {row?.issuedOn
                            ? dayjs(row?.issuedOn).format("DD-MM-YYYY")
                            : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    {!disabled && (
                      <TableCell
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Box
                          color={colors.primaryBlue}
                          onClick={() => {
                            setSelectedRow(row);
                            setSelectedIndex(index);
                            setOpen(true);
                          }}
                          sx={{
                            mr: 1,
                            cursor: "pointer",
                            color: colors.fontLight,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignContent: "center",
                              marginRight: "5px",
                              paddingLeft: "2px",
                              marginTop: "-3px"
                            }}
                          >
                            <img src={images.editIcon} alt="" />
                          </span>
                        </Box>
                        <Box
                          color={colors.primaryBlue}
                          onClick={() => {
                            let temp = [...certificationsArr];
                            temp.splice(index, 1);
                            setFieldValue("certifications", temp);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: colors.danger,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <DeleteOutline fontSize="small" color="inherit" />
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  {index === certificationsArr?.length - 1 && !disabled && (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        sx={{
                          py: 0,
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <Button
                          onClick={() => {
                            setSelectedRow(null);
                            setOpen(true);
                          }}
                        >
                          Add{" "}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <>
                {disabled ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      No Data
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                        display: "flex",
                        justifyContent: "end"
                      }}
                    >
                      <Button
                        onClick={() => {
                          setSelectedRow(null);
                          setOpen(true);
                        }}
                      >
                        Add{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
            <FormModal />
          </TableBody>
        </Table>
      </TableContainer>
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "57%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.primaryBlue
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default AddCertificationDetails;
