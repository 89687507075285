import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Checkbox,
  Button
} from "@mui/material";
import { colors } from "../../../styles/colors";
import { Box } from "@mui/system";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import images from "assets/images/images";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import { CheckBox, DeleteOutline } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../components/CommonModal";

const AddEducationDetails = (props) => {
  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    isEditing,
    setIsEditing,
    data,
    disabled
  } = props;

  // console.log("data", data);

  //   const { rolePermissions } = getJwtTokenDetails();

  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    handleSubmit();
  };

  const handleSpinTheWheelChange = (event) => {
    const { name, checked } = event.target;
    const spinTheWheelCheckboxesArr = values.priorities?.map(
      (item) => item.spinTheWheel
    );
    const spinTheWheelCount = spinTheWheelCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (spinTheWheelCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };
  const handleMysteryBoxChange = (event) => {
    const { name, checked } = event.target;
    const mysteryBoxCheckboxesArr = values.priorities?.map(
      (item) => item.mysteryBox
    );
    const mysteryBoxCount = mysteryBoxCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (mysteryBoxCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };

  const handleAdd = () => {
    const tempId = data?.length + 1;
    data.push({
      id: tempId,
      priority: "Priority 4",
      minValue: "0",
      maxValue: "1000",
      name: "lorem"
    });
  };

  const educationTemp = data?.education ? JSON.parse(data?.education) : null;

  let educationArr =
    values.education && values.education?.length > 0
      ? [...values.education]
      : educationTemp
      ? [...educationTemp]
      : [];

  console.log("educationArr", educationArr);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>(null);

  const FormModal = () => {
    const [saveClicked, setSaveClicked] = React.useState<any>(false);
    const [degree, setDegree] = React.useState<any>(selectedRow?.degree || "");
    const [institution, setInstitution] = React.useState<any>(
      selectedRow?.institution || ""
    );
    const [year, setYear] = React.useState<any>(selectedRow?.year || "");
    const [achievement, setAchievement] = React.useState<any>(
      selectedRow?.achievement || ""
    );

    const index = selectedIndex;

    return (
      <Modal open={open} setOpen={setOpen}>
        <Grid container spacing={2} mt={-9}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start"
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight={500}
              color={colors.fontLight}
              textAlign="center"
            >
              Education details
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Degree<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                }}
                value={degree || ""}
                onChange={(e) => {
                  setDegree(e.target.value);
                }}
                disabled={disabled}
              />
              {saveClicked && degree === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Institution<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                type="max"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                }}
                value={institution || ""}
                onChange={(e) => {
                  setInstitution(e.target.value);
                }}
                disabled={disabled}
              />
              {saveClicked && institution === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Year of Completion<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                type="min"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                }}
                value={year || ""}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  if (numericValue.length <= 4) setYear(numericValue);
                }}
                placeholder="Enter in Year"
                disabled={disabled}
              />
              {saveClicked && year === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Achievements
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                type="max"
                multiline
                rows={3}
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                }}
                value={achievement || ""}
                onChange={(e) => {
                  setAchievement(e.target.value);
                }}
                disabled={disabled}
              />
              {errors.education &&
                errors.education?.length > 0 &&
                errors?.education[index] &&
                errors?.education[index]?.achievement && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.education[index]?.achievement}
                  </Typography>
                )}
            </Box>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="end"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (degree !== "" && year !== "" && institution !== "") {
                  if (selectedRow) {
                    const temp = educationArr?.map((item, index2) => {
                      if (index !== index2) {
                        return item;
                      } else {
                        return {
                          degree,
                          institution,
                          year,
                          achievement
                        };
                      }
                    });
                    setFieldValue("education", [...temp]);
                    setOpen(false);
                  } else {
                    setFieldValue("education", [
                      {
                        degree,
                        institution,
                        year,
                        achievement
                      },
                      ...educationArr
                    ]);
                    setOpen(false);
                  }
                } else {
                  setSaveClicked(true);
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  };

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: colors.white,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "0px 0px 4px 4px "
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: colors.white }}>
            <TableRow>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Degree
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Institution
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Year
                </Typography>
              </TableCell>
              {!disabled && (
                <TableCell
                  sx={{ py: 1, display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Actions
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {educationArr && educationArr?.length > 0 ? (
              educationArr?.map((row, index) => {
                return (
                  <>
                    <TableRow key={row?.id}>
                      <TableCell sx={{ py: disabled ? 1 : 0 }}>
                        <Box>
                          <Typography fontSize={14}>{row?.degree}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: disabled ? 1 : 0 }}>
                        <Box>
                          <Typography fontSize={14}>
                            {row?.institution}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: disabled ? 1 : 0 }}>
                        <Box>
                          <Typography fontSize={14}>{row?.year}</Typography>
                        </Box>
                      </TableCell>
                      {!disabled && (
                        <TableCell
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <Box
                            color={colors.primaryBlue}
                            onClick={() => {
                              setSelectedRow(row);
                              setSelectedIndex(index);
                              setOpen(true);
                            }}
                            sx={{
                              mr: 1,
                              cursor: "pointer",
                              color: colors.fontLight,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignContent: "center",
                                marginRight: "5px",
                                paddingLeft: "2px",
                                marginTop: "-3px"
                              }}
                            >
                              <img src={images.editIcon} alt="" />
                            </span>
                          </Box>
                          <Box
                            color={colors.primaryBlue}
                            onClick={() => {
                              let temp = [...educationArr];
                              temp.splice(index, 1);
                              setFieldValue("education", temp);
                            }}
                            sx={{
                              cursor: "pointer",
                              color: colors.danger,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <DeleteOutline fontSize="small" color="inherit" />
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                    {index === educationArr?.length - 1 && !disabled && (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell
                          sx={{
                            py: 0,
                            display: "flex",
                            justifyContent: "end"
                          }}
                        >
                          <Button
                            onClick={() => {
                              setSelectedRow(null);
                              setOpen(true);
                            }}
                          >
                            Add{" "}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })
            ) : (
              <>
                {disabled ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      No Data
                    </TableCell>
                    <TableCell></TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                        display: "flex",
                        justifyContent: "end"
                      }}
                    >
                      <Button
                        onClick={() => {
                          setSelectedRow(null);
                          setOpen(true);
                        }}
                      >
                        Add{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
            <FormModal />
          </TableBody>
        </Table>
      </TableContainer>
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "57%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.primaryBlue
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default AddEducationDetails;
