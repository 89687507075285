import * as React from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Fade,
  Fab,
  Avatar,
  Paper,
  Breadcrumbs,
  useScrollTrigger,
  useMediaQuery
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CommonFooter from "./CommonFooter";
import {
  getStudentNavbarSelected,
  getStudentBreadcrumbs,
  getUserInfo
} from "utils/helpers";
import StudentNotifications from "./StudentNotificationMenu";
import { colors } from "../../styles/colors";
import theme from "styles/theme";
import images from "assets/images/images";

import { useLazyQuery } from "@apollo/client";
import { GET_UPSKILLING_URL } from "app/graphql/users";
import { useSnackbar } from "notistack";

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
  mainComponent: React.ReactNode;
  clearNotifications?: boolean;
}

const drawerWidth = 240;
const navItems = [
  { id: 0, name: "Business Development", path: "/business-development" },
  { id: 1, name: "Create Project", path: "/create-project" },
  { id: 2, name: "Search Projects", path: "/search-projects" },
  { id: 3, name: "Manage Projects", path: "/manage-projects" },
  { id: 4, name: "Manage Clients", path: "/manage-client" }
];

function ScrollTop(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center"
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const NavBar = (props: Props) => {
  const { mainComponent, clearNotifications } = props;
  const history = useHistory();
  const location = useLocation();
  const { firstName } = getUserInfo();
  const { enqueueSnackbar } = useSnackbar();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState<
    { id: number; name: string; path: string; color: string }[]
  >([]);

  const [getUpskillingUrl] = useLazyQuery(GET_UPSKILLING_URL, {
    variables: {},
    onCompleted: (data) => {
      const { getUpskillingUrl } = data;
      const { status, loginurl, error } = getUpskillingUrl;
      if (status) {
        window.open(loginurl, "_blank")?.focus();
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const me = sessionStorage.getItem("me");
  let id = "";

  if (me) {
    const meObj = JSON.parse(me);
    id = meObj.id;
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }} onClick={() => history.push("/")}>
        <img
          src={images.ezyconseil_logo_color}
          width={175}
          alt="logo"
          style={{
            marginTop: "-12px",
            marginBottom: "-12px"
          }}
        />
      </Typography>
      <Divider />
      <List>
        {navItems?.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={item.name}
                onClick={() => {
                  history.push(item.path);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  React.useEffect(() => {
    setBreadcrumbs(getStudentBreadcrumbs(location));
  }, [location]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" elevation={0} color="primary">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "block" }}
              // onClick={() => history.push("/")}
            >
              <img
                src={images.ezyconseil_logo_white_png}
                width={175}
                alt="logo"
                style={{
                  marginTop: "-12px",
                  marginBottom: "-12px"
                }}
              />
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" }
              }}
            >
              {navItems &&
                navItems.map((item, index) => {
                  return (
                    <Typography
                      key={item.id}
                      color={
                        getStudentNavbarSelected(location, item)
                          ? colors.secondary
                          : `${colors.secondary}50`
                      }
                      fontSize="16px"
                      sx={{ cursor: "pointer", mr: 2 }}
                      onClick={() => {
                        history.push(item.path);
                      }}
                    >
                      {item.name}
                    </Typography>
                  );
                })}
            </Box>
            {/* <StudentNotifications clearNotifications={clearNotifications} /> */}
            <Avatar
              sx={{
                cursor: "pointer",
                color: colors.primary,
                backgroundColor: colors.secondary
              }}
              onClick={() => history.push(`/freelancer-profile/${id}`)}
            >
              {firstName?.length > 0 ? firstName[0] : ""}
            </Avatar>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ width: "100%" }}>
          <Toolbar id="back-to-top-anchor" />
          <Paper
            elevation={1}
            sx={{ p: 2, pl: matchesMd ? 5 : 15, borderRadius: 0 }}
          >
            {/* <Breadcrumbs separator={">"} aria-label="breadcrumb">
              {breadcrumbs.map((crumb) => (
                <NavLink
                  key={crumb.id}
                  role="button"
                  to={crumb.path}
                  style={{
                    textDecoration: "none",
                    color: crumb.color,
                    fontSize: "16px",
                  }}
                >
                  {crumb.name}
                </NavLink>
              ))}
            </Breadcrumbs> */}
          </Paper>
          <Box sx={{ py: 3, px: matchesMd ? 5 : 15 }}>{mainComponent}</Box>
        </Box>
      </Box>
      <CommonFooter />
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default NavBar;
