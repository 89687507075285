import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import NoBorderDropdown from "app/components/NoBorderDropdown";
import Filters from "./Filters";
import { colors } from "../../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import NoDataFound from "app/components/NoData";
import Table from "../../components/Table";
import "../../components/Table/StatusButton.css";
import { useHistory } from "react-router-dom";
import NavBar from "app/components/Navbar";
import { GET_ALL_PUBLISHED_PROJECTS } from "app/graphql/AdminDevelopmentQuries";
import AdminNavbar from "app/components/AdminNavbar";
import { GridActionsCellItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import images from "assets/images/images";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  manageProjectStatusArr,
  partnerCategoryOptions
} from "utils/constants";
import { DELETE_PROJECT } from "app/graphql/projects";
import { useSnackbar } from "notistack";

function Projects() {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = React.useState("-created_date");
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryFilter, setQueryFilter] = React.useState({});
  const [propertiesFilter, setPropertiesFilter] = React.useState({});

  const [projects, setProjects] = React.useState([]);

  const [projectsCount, setProjectsCount] = React.useState(0);

  React.useEffect(() => {
    console.log("queryFilter changed:", queryFilter);
  }, [queryFilter]);

  const {
    data: allProjects,
    loading,
    refetch
  } = useQuery(GET_ALL_PUBLISHED_PROJECTS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: queryFilter,
      sort_by: sortBy,
      candidate_to_be_excluded: ""
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (allProjects && allProjects.projects && allProjects.projects.projects) {
      setProjects(allProjects.projects.projects);
      setProjectsCount(allProjects.projects.count);
    } else {
      setProjects([]);
    }
  }, [allProjects]);

  // const manageProjectStatusArr = [
  //   {
  //     id: 0,
  //     name: "Open"
  //   },
  //   {
  //     id: 1,
  //     name: "Closed"
  //   }
  // ];

  const [deleteProject, { loading: deleting }] = useMutation(DELETE_PROJECT, {
    onCompleted: (data) => {
      const { deleteProject } = data;
      const { status, message } = deleteProject;
      if (status) {
        refetch();
        enqueueSnackbar(message, { variant: "success" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  });

  const CATEGORY_OPTIONS = {
    A: "A-High-Profile Partners(Influential) ",
    B: "B-Medium-Profile Partners(Established) ",
    C: "C-Low-Profile Partners(Emerging) "
  };

  const columns = React.useMemo(
    () => [
      {
        field: "createdDate",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PUBLISH DATE
          </Typography>
        ),
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="14px">
              {params.row?.createdDate
                ? dayjs(params.row?.createdDate).format("DD-MM-YYYY")
                : null}
            </Typography>
          </Box>
        )
      },
      {
        field: "org",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            CLIENT NAME
          </Typography>
        ),
        minWidth: 120,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">{params?.row?.organization.name || ""}</Typography>
          </Box>
        )
      },
      {
        field: "title",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PROJECT TITLE
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">{params?.row?.title || ""}</Typography>
          </Box>
        )
      },
      {
        field: "preferredBpCategory",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PROJECT CATEGORY
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => {
          const domains =
            params?.row?.domain && params?.row?.domain?.length > 0
              ? params?.row?.domain?.map((item, index) => item?.service?.name)
              : [];
          return (
            <Box>
              <Typography fontSize="12px">{domains[0] || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "preferredCategory",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PREFERRED PARTNER CATEGORY
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => {
          const tempArrParsed = params?.row?.preferredBpCategory
            ? JSON.parse(params?.row?.preferredBpCategory)
            : [];
          console.log("tempArrParsed", tempArrParsed);
          const tempArr = tempArrParsed?.map((item) => CATEGORY_OPTIONS[item]);
          console.log("temp", tempArr);
          // const temp =
          //   tempArr && tempArr?.length > 0
          //     ? tempArr?.map((item, index) => {
          //         if (index === 0) {
          //           return item;
          //         } else {
          //           return `, ${item}`;
          //         }
          //       })
          //     : [];

          return (
            <Box>
              <Typography fontSize="12px">{tempArr || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "preferredBpCity",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PREFERRED LOCATION
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => {
          const temp =
            params?.row?.preferredCityName &&
            params?.row?.preferredCityName?.length > 0
              ? params?.row?.preferredCityName?.map((item, index) => {
                  if (index === 0) {
                    return item?.name;
                  } else {
                    return `, ${item?.name}`;
                  }
                })
              : [];
          return (
            <Box>
              <Typography fontSize="12px">{temp || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "preferredBp",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PREFERRED BP
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        renderCell: (params) => {
          const temp =
            params?.row?.preferredBpName &&
            params?.row?.preferredBpName?.length > 0
              ? params?.row?.preferredBpName?.map((item, index) => {
                  if (index === 0) {
                    return item;
                  } else {
                    return `, ${item}`;
                  }
                })
              : [];
          return (
            <Box>
              <Typography fontSize="12px">{temp || "-"}</Typography>
            </Box>
          );
        }
      },
      // {
      //   field: "status",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       STATUS
      //     </Typography>
      //   ),
      //   minWidth: 140,
      //   flex: 1,
      //   headerAlign: "left",
      //   align: "left",
      //   renderCell: (params) => {
      //     return (
      //       <NoBorderDropdown
      //         value={params?.row?.status === 0 ? 0 : params?.row?.status}
      //         bgColor="rgba(0, 0, 0, 0.04)"
      //         textColor={colors.fontLight}
      //         dropdownArr={manageProjectStatusArr}
      //         onChange={(e) => {
      //           console.log(e);
      //         }}
      //       />
      //     );
      //   }
      // },
      {
        field: "actions",
        type: "actions",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            ACTIONS
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    history.push(`/view-selected-project/${params?.row?.id}`);
                  }}
                >
                  <VisibilityIcon fontSize="small" />
                </span>
              }
              label="View"
            />,
            <GridActionsCellItem
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    history.push(`/publish-project/${params?.row?.id}`);
                  }}
                >
                  <img src={images.editIcon} alt="" />
                </span>
              }
              label="Edit"
            />,
            <GridActionsCellItem
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    deleteProject({
                      variables: {
                        ids: [params.row.id]
                      }
                    });
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </span>
              }
              label="Delete"
            />
          ];
        }
      }
    ],
    []
  );

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography fontSize="22px" fontWeight={500}>
          Project Details
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Grid
          container
          spacing={0.5}
          sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
          alignItems="center"
        >
          <Grid item sx={{ display: "flex" }}>
            <Button
              variant="contained"
              onClick={() => history.push("/publish-project/add-new-project")}
            >
              Create New Project
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Filters
          filter={propertiesFilter}
          setFilter={setPropertiesFilter}
          setQueryFilter={setQueryFilter}
        />
      </Grid>
      <Grid item container lg={12} md={12} sm={12} xs={12}>
        {projects && projects.length > 0 ? (
          <Table
            rows={projects || []}
            columns={columns}
            page={page}
            pageSize={pageSize}
            rowCount={projectsCount || 10}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        ) : (
          <NoDataFound />
        )}
      </Grid>
    </Grid>
  );
}

export function AdminPublishProjectDashboard(props: any) {
  return <AdminNavbar mainComponent={<Projects />} />;
}
