import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Paper, Typography, Grid } from "@mui/material";
import {
  AccountCircleOutlined,
  SchoolOutlined,
  TuneOutlined,
  WorkspacePremiumOutlined,
  BusinessCenterOutlined,
  LockOutlined,
  LogoutOutlined
} from "@mui/icons-material";
import { colors } from "../../../styles/colors";

const ProfileDivisions = (props) => {
  const { divisions, selected, setSelected, setLogoutModal } = props;
  const history = useHistory();
  const { partnerId }: any = useParams();
  return (
    <Paper sx={{ borderRadius: 0, p: 3, height: "100%" }}>
      {divisions.map((item) => (
        <Grid
          key={item.id}
          container
          spacing={1}
          sx={{ my: 2, cursor: "pointer" }}
          onClick={() => {
            if (partnerId === "add-new-partner") {
              if (item.id !== 2) {
                setSelected(item.id);
              } else {
                alert(
                  "Please add a partner to proceed with Professional section"
                );
              }
            } else {
              setSelected(item.id);
            }
          }}
        >
          <Grid item>
            {item.id === 1 ? (
              <AccountCircleOutlined
                sx={{
                  color:
                    selected === item.id ? colors.primary : colors.fontLight
                }}
              />
            ) : item.id === 2 ? (
              <SchoolOutlined
                sx={{
                  color:
                    selected === item.id ? colors.primary : colors.fontLight
                }}
              />
            ) : item.id === 3 ? (
              <BusinessCenterOutlined
                sx={{
                  color:
                    selected === item.id ? colors.primary : colors.fontLight
                }}
              />
            ) : item.id === 3 ? (
              <LockOutlined
                sx={{
                  color:
                    selected === item.id ? colors.primary : colors.fontLight
                }}
              />
            ) : (
              <LogoutOutlined
                sx={{
                  color:
                    selected === item.id ? colors.primary : colors.fontLight
                }}
              />
            )}
          </Grid>
          <Grid item sx={{ width: "83%" }}>
            <Typography
              fontSize="16px"
              color={selected === item.id ? colors.primary : colors.fontLight}
              sx={{ wordBreak: "break-word" }}
            >
              {item.name}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Paper>
  );
};

export default ProfileDivisions;
