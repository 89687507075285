/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, HashRouter } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";

import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { HomePage } from "./pages/HomePage/Loadable";
import { SearchProjects } from "./pages/SearchProjects/Loadable";
import { SearchProjectDetailsNew } from "./pages/SearchProjectDetailsNew/Loadable";
import { ManageProjectDetails } from "./pages/ManageProjectDetails/Loadable";
import { CreateProject } from "./pages/CreateProjectPage/Loadable";
import { Profile } from "./pages/ProfilePage/Loadable";
import SetPassword from "./pages/SetPassword";

import AppApolloprovider from "./ApolloProvider";
import { useTranslation } from "react-i18next";
import getStore from "store/zustandStore";
import { Backdrop, CircularProgress } from "@mui/material";
import { AdminProfile } from "./pages/AdminProfile/Loadable";

import { AdminNotifications } from "./pages/AdminNotifications/Loadable";
import { ManageUsers } from "./pages/ManageUsersPage/Loadable";
import { BusinessDevelopment } from "./pages/BusinessDevelopmentPage";
import { ManageProjectsPage } from "./pages/ManageProjectsPage";
import { AdminRegisterPartner } from "./pages/AdminRegisterPartner";
import { AddEditNewPartner } from "./pages/AdminAddNewPartner";
import { AdminBusinessDevelopment } from "./pages/AdminBusinessDevelopmentPage";
import { ViewNewProjectApplicants } from "./pages/ViewProjectApplicants";
import { ManageNewProjectsContainer } from "./pages/ManageProject";
import { AdminPublishProject } from "./pages/AdminPublishProject/Loadable";
import { AdminPublishProjectDashboard } from "./pages/AdminPublishProjectDashboard";
import { AdminManageClients } from "./pages/AdminManageClients/Loadable";
import { BPManageClients } from "./pages/BPManageClients/Loadable";
import ProtectedRoute from "./ProtectedRoute";

export function App() {
  const { i18n } = useTranslation();
  const loading = getStore((state: any) => state.loading);
  return (
    <HashRouter>
      <Helmet
        // titleTemplate="%s - React Boilerplate"
        defaultTitle=""
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Ezyconseil" />
      </Helmet>
      <AppApolloprovider>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/setpassword" component={SetPassword} />
          <ProtectedRoute
            exact
            path="/business-development"
            component={BusinessDevelopment}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/create-project"
            component={CreateProject}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/search-projects"
            component={SearchProjects}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/search-project-detail/:projectId"
            component={SearchProjectDetailsNew}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/manage-projects"
            component={ManageProjectsPage}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/manage-project-details/:projectId"
            component={ManageProjectDetails}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/manage-client"
            component={BPManageClients}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/freelancer-profile/:userId"
            component={Profile}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/partner-registration"
            component={AdminRegisterPartner}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/partner-registration/:partnerId"
            component={AddEditNewPartner}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/new-business-development"
            component={AdminBusinessDevelopment}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/publish-project"
            component={AdminPublishProjectDashboard}
            authenticationPath={"/"}
          />

          <ProtectedRoute
            exact
            path="/publish-project/:projectId"
            component={AdminPublishProject}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/view-selected-project/:projectId"
            component={ViewNewProjectApplicants}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/manage-new-project"
            component={ManageNewProjectsContainer}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/manage-new-project/:projectId"
            component={AdminPublishProject}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/admin/manage-user"
            component={ManageUsers}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/admin/manage-client"
            component={AdminManageClients}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/admin-profile/:userId"
            component={AdminProfile}
            authenticationPath={"/"}
          />
          <ProtectedRoute
            exact
            path="/admin-notifications"
            component={AdminNotifications}
            authenticationPath={"/"}
          />

          <Route component={NotFoundPage} />
        </Switch>
      </AppApolloprovider>
      <GlobalStyle />
      <Backdrop
        open={loading || false}
        sx={{
          color: "#000000",
          opacity: "70%",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress />
      </Backdrop>
    </HashRouter>
  );
}
