import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query qualifications {
    qualifications {
      id
      name
    }
  }
`;

export const GET_CATEGORIES = gql`
  query occupations {
    occupations {
      id
      name
    }
  }
`;

export const GET_CURRENCY_TYPE = gql`
  query currencyType {
    currencyType {
      id
      name
    }
  }
`;

export const GET_SKILLS = gql`
  query skills {
    skills {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_DROPDOWN = gql`
  query organizationDropDown {
    organizationDropDown {
      id
      name
    }
  }
`;

export const GET_QUALIFICATIONS = gql`
  query qualifications {
    qualifications {
      id
      name
    }
  }
`;

export const GET_DEGREES = gql`
  query degress {
    degrees {
      id
      name
    }
  }
`;

export const GET_COUNTRIES = gql`
  query country {
    countries {
      id
      name
    }
  }
`;

export const GET_STATES = gql`
  query states($country: String!) {
    states(country: $country) {
      id
      name
    }
  }
`;

export const GET_ALL_STATES = gql`
  query allStates {
    allStates {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query cities($state: String!) {
    cities(state: $state) {
      id
      name
    }
  }
`;

export const GET_FILTERED_CITIES = gql`
  query getAllCities($filter: CityFilterInput) {
    getAllCities(filter: $filter, limit: 10) {
      count
      city {
        id
        name
      }
    }
  }
`;

export const GET_JOB_CATEGORIES = gql`
  query jobTypes {
    jobTypes {
      id
      name
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query allCities {
    allCities {
      id
      name
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries {
    industries {
      id
      name
    }
  }
`;

export const GET_SERVICES = gql`
  query services {
    services {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_DROPDOWN_OPTIONS = gql`
  query organizationDropDown {
    organizationDropDown {
      count
      organization {
        id
        name
      }
    }
  }
`;

export const GET_EXPERT_ON_CALL_CONSULTANTS = gql`
  query allProfessionalDetails(
    $filter: ProfessionalDetailsFilterInput
    $limit: Int
    $page: Int
  ) {
    allProfessionalDetails(filter: $filter, limit: $limit, page: $page) {
      count
      details {
        id
        name
      }
    }
  }
`;
