import * as React from "react";
import { Box, Typography } from "@mui/material";
import images from "assets/images/images";

const NoDataFound = (props) => {
  const { message } = props;
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box>
        <img src={images.NoIndustry} />
        <Typography
          fontSize="14px"
          sx={{ width: "80%", textAlign: "center", ml: 5, mt: 1 }}
        >
          {message ||
            "Try adjusting your search or filter to find what you’re looking for"}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoDataFound;
