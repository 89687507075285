import { gql } from "@apollo/client";

// Table 1
export const GET_ALL__REGISTERED_USERS = gql`
  query users(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
    $is_freelancer: Boolean
  ) {
    users(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
      is_freelancer: $is_freelancer
    ) {
      count
      users {
        id
        firstName
        lastName
        username
        email
        mobile
        category
        roles
        city {
          id
          name
        }
        expectedRates
        category
        userWorkExperience {
          occupation {
            id
            name
          }
          experience
          certifications
        }
        partnershipDetails {
          endDate
        }
      }
    }
  }
`;

export const GET_ALL__BUSINESS_PARTNERS = gql`
  query users($is_freelancer: Boolean) {
    users(is_freelancer: $is_freelancer) {
      count
      users {
        id
        firstName
      }
    }
  }
`;
// Table 2
export const GET_ALL_BUSINESS_DEV_ENQUIRIES = gql`
  query getAllEnquiries($filter: EnquiryFilterInput, $limit: Int, $page: Int) {
    getAllEnquiries(filter: $filter, limit: $limit, page: $page) {
      count
      enquiry {
        id
        companyName
        spocDesignation
        spocName
        projectCategoryId
        industryId
        websiteLink
        requirement
        spocName
        spocDesignation
        spocPhone
        spocEmail
        enquiryStatus
        comment
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
      }
    }
  }
`;

// Table 3 a
export const GET_ALL_PUBLISHED_PROJECTS = gql`
  query projects(
    $filter: ProjectFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
    $candidate_to_be_excluded: String
  ) {
    projects(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
      candidate_to_be_excluded: $candidate_to_be_excluded
    ) {
      count
      projects {
        id
        title
        createdDate
        description
        category {
          id
          name
        }
        preferredBp
        preferredBpCategory
        preferredBpCity
        preferredCityName {
          id
          name
        }
        preferredBpName
        preferredType
        org
        organization{
          id
          name
        }
        domain {
          id
          service {
            id
            name
          }
        }
        status
      }
    }
  }
`;

// Table 3 b
//48

// Table 4
export const GET_MANAGED_PROJECTS_APPLICANTS = gql`
  query projectCandidates(
    $filter: ProjectCandidateFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
    $exclude_unassigned_projects: Boolean
  ) {
    projectCandidates(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
      exclude_unassigned_projects: $exclude_unassigned_projects
    ) {
      count
      projectcandidates {
        id
        project {
          id
          title
          description
          projectStatus
          # keyDeliverables
          industry {
            id
            name
          }
          category {
            id
            name
          }
          city {
            id
            name
          }
          org
          domain {
            id
            service {
              id
              name
            }
          }
          status
          organization {
            name
          }
        }
        candidate {
          id
          firstName
          lastName
          # username
          # email
          # mobile
          # roles
          # profile
          # userWorkExperience {
          #   occupation {
          #     id
          #     name
          #   }
          #   experience
          #   certifications
          #   userSkills {
          #     skill {
          #       id
          #       name
          #     }
          #     expertise
          #   }
          # }
          # organization {
          #   name
          # }
        }
        createdDate
        status
      }
    }
  }
`;
