export const bsuinessDevStatusArr = [
  { id: "1", name: "Input gathering" },
  { id: "2", name: "Meeting" },
  { id: "3", name: "Approach Note" },
  { id: "4", name: "Proposal" },
  { id: "5", name: "Discussion" },
  { id: "6", name: "Negotiation" },
  { id: "7", name: "Agreement" },
  { id: "8", name: "Won" },
  { id: "9", name: "OnHold" },
  { id: "10", name: "Closed" },
  { id: "11", name: "Lost" }
];

export const projectCategoryArr = [
  { id: "1", name: "Growth Strategy" },
  { id: "2", name: "Sales Strategy" },
  { id: "3", name: "Digital Transformation" },
  { id: "4", name: "Branding" },
  { id: "5", name: "Intercultural Coaching" },
  { id: "6", name: "Start-up Advisory" },
  { id: "7", name: "Investment Memorandum" },
  { id: "8", name: "Sales Force Effectiveness" },
  { id: "9", name: "Process Excellence" },
  { id: "10", name: "Performance Improvement Diagnostics" },
  { id: "11", name: "Supply Chain Management" },
  { id: "12", name: "Project Management" }
];

export const partnerCategory = [
  {
    id: "A",
    name: "A-High - Profile Partners(Influential)"
  },
  {
    id: "B",
    name: "B-Medium - Profile Partners(Established)"
  },
  {
    id: "C",
    name: "C-Low - Profile Partners(Emerging)"
  }
];

export const partnerCategoryOptions = [
  { id: "A", name: "A-High-Profile Partners(Influential)" },
  { id: "B", name: "B-Medium-Profile Partners(Established)" },
  { id: "C", name: "C-Low-Profile Partners(Emerging)" }
];

export const deliveryModeOptions = [
  { id: "VIRTUAL", name: "Virtual" },
  { id: "IN_PERSON", name: "In Person" },
  { id: "HYBRID", name: "Hybrid" }
];

export const statusArr = [
  { id: 4, name: "Applied" },
  { id: 5, name: "Shortlisted" },
  // { id: 6, name: "Interviewed" },
  { id: 7, name: "Offered" },
  { id: 9, name: "Rejected" }
];

export const statusMappingDisplay = {
  1: "active",
  3: "deleted",
  4: "applied",
  5: "shortlisted",
  6: "interviewed",
  7: "offered",
  13: "joined",
  9: "rejected",
  17: "draft"
};

export const statusMapping = {
  1: "Active",
  3: "Deleted",
  4: "Applied",
  5: "Shortlisted",
  6: "Interviewed",
  7: "Offered",
  13: "Joined",
  9: "Rejected",
  18: "Selected",
  17: "Draft",
  16: "Closed",
  20: "Cleared",
  15: "Put On Hold"
};

export const manageProjectStatusArr = [
  {
    id: 1,
    name: "Active"
  },
  {
    id: 2,
    name: "Completed"
  },
  {
    id: 3,
    name: "On Hold"
  }
];

export const jobOrInternshipArr = [
  { id: "INTERNSHIP", name: "Internship" },
  { id: "JOB", name: "Job" }
];

export const genders = {
  M: "Male",
  F: "Female",
  O: "Others",
  B: "Both",
  NA: "No Preference"
};

export const gendersArr = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
  { id: "O", name: "Others" },
  { id: "B", name: "Both" },
  { id: "NA", name: "No Preference" }
];

export const jobModes = {
  FULL_TIME: "Full Time",
  PART_TIME: "Part Time"
};

export const jobModesArr = [
  { id: "FULL_TIME", name: "Full Time" },
  { id: "PART_TIME", name: "Part Time" }
];

export const jobTeamTypes = {
  IN_OFFICE: "In Office",
  WORK_FROM_HOME: "Remote",
  HYBRID: "Hybrid"
};

export const jobTeamTypesArr = [
  { id: "IN_OFFICE", name: "In Office" },
  { id: "WORK_FROM_HOME", name: "Remote" },
  { id: "HYBRID", name: "Hybrid" }
];

export const ownershipArr = [
  { id: "CENTRAL_OPEN_UNIVERSITY", name: "Central Open University" },
  { id: "CENTRAL_UNIVERSITY", name: "Central University" },
  { id: "DEEMED_UNIVERSITY_GOVERNMENT", name: "Deemed University- Government" },
  {
    id: "DEEMED_UNIVERSITY_GOVERNMENT_AIDED",
    name: "DEEMED_UNIVERSITY_GOVERNMENT_AIDED"
  },
  { id: "DEEMED_UNIVERSITY_PRIVATE", name: "Deemed University - Private" },
  {
    id: "INSTITUTE_OF_NATIONAL_IMPORTANCE",
    name: "INSTITUTE_OF_NATIONAL_IMPORTANCE"
  },
  {
    id: "INSTITUTE_UNDER_STATE_LEGISLATURE_ACT",
    name: "Institute Under State Legislature Act"
  },
  { id: "STATE_OPEN_UNIVERSITY", name: "State Open University" },
  {
    id: "STATE_PRIVATE_OPEN_UNIVERSITY",
    name: "State Private Open University"
  },
  { id: "STATE_PRIVATE_UNIVERSITY", name: "State Private University" },
  { id: "STATE_PUBLIC_UNIVERSITY", name: "State Public University" },
  { id: "OTHER", name: "Other" }
];

const MIMETypesMapping = {
  ".apng": "apng",
  ".avif": "avif",
  ".gif": "gif",
  ".jpg": "jpeg",
  ".jpeg": "jpeg",
  ".jfif": "jpeg",
  ".pjpeg": "jpeg",
  ".pjp": "jpeg",
  ".png": "png",
  ".svg": "svg+xml",
  ".webp": "webp",
  ".pdf": "application/pdf",
  ".mp4": "mp4",
  ".mov": "quicktime",
  ".webm": "webm"
};

export const getMIMETypeOfFile = (fileType) => {
  return MIMETypesMapping[fileType];
};

export const acceptImageTypes =
  ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp";

export const acceptVideoTypes = ".mp4, .mov, .webm";

export const acceptFileTypes = ".pdf, .doc, .txt";

export const expertiseChoices = [
  { id: "BEGINNER", name: "Beginner" },
  { id: "COMPETENT", name: "Competent" },
  { id: "EXPERT", name: "Expert" }
];

export const projectStatusChoices = [
  { id: 21, name: "On-going" },
  { id: 15, name: "On-Hold" },
  { id: 22, name: "Completed" }
];
