import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Box
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import MultipleAddComponent from "../../components/MultipleAddComponent";
import { colors } from "../../../styles/colors";

import { FormikProps, FieldArray } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CATEGORIES, GET_SERVICES } from "../../graphql/meta";
import ProfilePhotoForView from "./ProfilePhotoForView";
import AddEducationDetails from "./AddEducationDetails";
import AddWorkExperienceDetails from "./AddWorkExperienceDetails";
import AddProjectExperienceDetails from "./AddProjectExperienceDetails";
import AddCertificationDetails from "./AddCertificationDetails";
import { AddEditTableRowComponent } from "app/components/AddEditTableRowCompoent";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Chip } from "@mui/material";
import { DELETE_CONSULTANT } from "app/graphql/Profile";

interface IAddQualificationProps {
  editClicked: boolean;
  userQualifications: any;
  setSelectedConsultant: any;
  setEditClicked: any;
  refetchProfessionalDetails: any;
}

const ViewProfessionalDetailsComponent = (
  props: IAddQualificationProps & FormikProps<any>
) => {
  const {
    editClicked,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    userQualifications,
    setSelectedConsultant,
    setEditClicked,
    refetchProfessionalDetails
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [servicesArr, setServicesArr] = React.useState([]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);
  console.log(errors);

  const [deleteConsultant, { loading: deleteConsultantLoading }] = useMutation(
    DELETE_CONSULTANT,
    {
      onCompleted: (data) => {
        const { deleteConsultant } = data;
        const { status, message } = deleteConsultant;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchProfessionalDetails();
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      },
      fetchPolicy: "network-only"
    }
  );

  return (
    <FieldArray
      name="professionalDetails"
      render={(arrayHelpers) => (
        <Grid container spacing={0.25}>
          {/* {editClicked && (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
            >
              <Button
                // variant="contained"
                size="small"
                startIcon={<AddOutlined color="primary" fontSize="small" />}
                onClick={() => arrayHelpers.push({})}
              >
                Add New Consultant
              </Button>
            </Grid>
          )} */}
          {values.professionalDetails &&
            values.professionalDetails?.map((item, index) => {
              const domainIds = item?.domain?.map(
                (item2) => item2?.service?.id
              );
              const consultantId = item?.id;
              return (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ mt: index === 0 ? 0 : 1 }}
                  key={`professionalDetails${index}`}
                >
                  <MultipleAddComponent
                    {...(editClicked && {
                      onDeleteClicked: () => arrayHelpers.remove(index)
                    })}
                  >
                    <Grid container spacing={2.5}>
                      <Grid
                        item
                        lg={7}
                        md={7}
                        sm={12}
                        xs={12}
                        sx={{
                          mb: -2,
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <ProfilePhotoForView
                          consultantId={consultantId}
                          disabled={true}
                          {...props}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            mt: 4
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setEditClicked(true);
                              setSelectedConsultant(item);
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => {
                              deleteConsultant({
                                variables: {
                                  id: consultantId
                                }
                              });
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              ml: 1.5,
                              color: colors.danger
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Grid>
                      {/* <Grid
                      item
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      sx={{ mb: -2, display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={
                          <AddOutlined color="primary" fontSize="small" />
                        }
                        onClick={() => arrayHelpers.push({})}
                      >
                        Edit
                      </Button>
                    </Grid> */}
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Name
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <TextField
                          id={`professionalDetails${index}name`}
                          value={item?.name || ""}
                          onChange={(e) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][name]`,
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]?.name &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]?.name && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {errors?.professionalDetails[index]?.name}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Brief Profile (100 words)
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <TextField
                          id={`professionalDetails${index}profile`}
                          multiline
                          rows={3}
                          value={item?.profile || ""}
                          onChange={(e) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][profile]`,
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]?.profile &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]?.profile && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {errors?.professionalDetails[index]?.profile}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Years of experience
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <TextField
                          id={`professionalDetails${index}years`}
                          value={item?.years || ""}
                          onChange={(e) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][years]`,
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]
                            ?.years &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]
                            ?.years && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {
                                errors?.professionalDetails[index]
                                  ?.years
                              }
                            </Typography>
                          )}
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Education Details
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <AddEducationDetails
                          disabled={true}
                          {...props}
                          data={item}
                        />
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Work Experience
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <AddWorkExperienceDetails
                          disabled={true}
                          {...props}
                          data={item}
                        />
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Project Experience
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <AddProjectExperienceDetails
                          disabled={true}
                          {...props}
                          data={item}
                        />
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Certification
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <AddCertificationDetails
                          disabled={true}
                          {...props}
                          data={item}
                        />
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Domain Expertise
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <Autocomplete
                          multiple // Enable multiple selection
                          id={`professionalDetails${index}domainExpertiseId`}
                          value={
                            services && services?.services?.length > 0
                              ? services?.services?.filter((x: any) =>
                                  domainIds?.includes(x.id)
                                )
                              : []
                          }
                          onChange={(e, values) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][domainExpertiseId]`,
                                values.map((value) => value.id)
                              );
                            }
                          }}
                          options={servicesArr || []}
                          getOptionLabel={(option: any) => option.name}
                          size="small"
                          fullWidth
                          disabled={true}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]
                            ?.domainExpertiseId &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]
                            ?.domainExpertiseId && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {
                                errors?.professionalDetails[index]
                                  ?.domainExpertiseId
                              }
                            </Typography>
                          )}
                      </Grid>

                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          LinkedIn Profile
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <TextField
                          id={`professionalDetails${index}linkdinProfile`}
                          value={item?.linkdinProfile || ""}
                          onChange={(e) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][linkdinProfile]`,
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]
                            ?.linkedinProfile &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]
                            ?.linkedinProfile && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {
                                errors?.professionalDetails[index]
                                  ?.linkedinProfile
                              }
                            </Typography>
                          )}
                      </Grid>

                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Personal Website/Blog
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <TextField
                          id={`professionalDetails${index}personalBlog`}
                          value={item?.personalBlog || ""}
                          onChange={(e) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][personalBlog]`,
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]?.personalBlog &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]?.personalBlog && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {errors?.professionalDetails[index]?.personalBlog}
                            </Typography>
                          )}
                      </Grid>

                      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
                        <Typography fontSize="14px" color={colors.fontLight}>
                          Youtube / Any other channel link
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <TextField
                          id={`professionalDetails${index}youtubeChannel`}
                          value={item?.youtubeChannel || ""}
                          onChange={(e) => {
                            if (index !== 0) {
                              setFieldValue(
                                `professionalDetails[${index}][youtubeChannel]`,
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={true}
                        />{" "}
                        {touched.professionalDetails &&
                          touched?.professionalDetails[index] &&
                          touched?.professionalDetails[index]?.youtubeChannel &&
                          errors.professionalDetails &&
                          errors?.professionalDetails[index] &&
                          errors?.professionalDetails[index]
                            ?.youtubeChannel && (
                            <Typography
                              sx={{
                                color: colors.danger,
                                fontSize: "11px",
                                fontWeight: 600,
                                mt: 0.5
                              }}
                            >
                              {
                                errors?.professionalDetails[index]
                                  ?.youtubeChannel
                              }
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  </MultipleAddComponent>
                </Grid>
              );
            })}
        </Grid>
      )}
    />
  );
};

const ViewProfessionalDetails = (props) => {
  const { consultantsArr, setSelectedConsultant } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [userOccupations, setUserOccupations] = React.useState([]);
  console.log("consultantsArr", consultantsArr);

  const sampleConsultantsData = [
    {
      profilePhoto: "",
      name: "name1",
      profile: "desc1",
      years: "1",
      domainExpertiseId: "abc",
      linkedinProfile: "nbv",
      personalBlog: "cxz",
      youtubeChannel: "asd"
    },
    {
      profilePhoto: "",
      name: "name2",
      profile: "desc2",
      years: "2",
      domainExpertiseId: "abcert",
      linkedinProfile: "nbvhgf",
      personalBlog: "cxzlkj",
      youtubeChannel: "asddcf"
    }
  ];

  console.log("consultantsArr", consultantsArr);

  const AddProfessionalDetailsFormWrapped = withFormik({
    mapPropsToValues: () => ({
      professionalDetails: consultantsArr
    }),

    validationSchema: Yup.object().shape({
      professionalDetails: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required("This is a required field"),
            profile: Yup.string().required("This is a required field"),
            years: Yup.number()
              .min(0, "atleast possible value is 0")
              .max(85, "Please enter a valid input")
              .required("This is a required field")
              .typeError("Please enter a valid number"),
            domainExpertiseId: Yup.string().required(
              "This is a required field"
            ),
            linkedinProfile: Yup.string().required("This is a required field"),
            personalBlog: Yup.string().required("This is a required field"),
            youtubeChannel: Yup.string().required("This is a required field")
          })
        )
        .min(1, "atleast one is required")
    }),

    enableReinitialize: true,

    handleSubmit(values) {
      console.log(values);
    }
  })(ViewProfessionalDetailsComponent);

  return (
    <AddProfessionalDetailsFormWrapped
      {...props}
      userQualifications={userOccupations}
    />
  );
};

export default ViewProfessionalDetails;
