import * as React from "react";
import { Box, Avatar, Grid, Button } from "@mui/material";
import { RemoveCircleOutline, AddOutlined } from "@mui/icons-material";
import { colors } from "../../styles/colors";

interface IMultipleAddComponentProps {
  onDeleteClicked?: any;
  children: React.ReactNode;
  backgroundColor?: string;
}

const MultipleAddComponent = (props: IMultipleAddComponentProps) => {
  const { children, onDeleteClicked } = props;
  return (
    <Box
      sx={{
        px: 1.5,
        py: 2,
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : colors.lightBgColor,
        borderRadius: "4px",
        position: "relative",
      }}
    >
      {onDeleteClicked && (
        <Avatar
          sx={{
            height: 25,
            width: 25,
            backgroundColor: colors.secondary,
            border: "2px solid #F1F1F1",
            position: "absolute",
            right: -5,
            top: -5,
            cursor: "pointer",
          }}
          onClick={onDeleteClicked}
        >
          <RemoveCircleOutline sx={{ color: colors.danger, fontSize: 18 }} />
        </Avatar>
      )}
      {children}
    </Box>
  );
};

export default MultipleAddComponent;
