import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import NoBorderDropdown from "app/components/NoBorderDropdown";
import Filters from "./Filters";
import { colors } from "../../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import NoDataFound from "app/components/NoData";
import Table from "../../components/Table";
import "../../components/Table/StatusButton.css";
import { useHistory, useParams } from "react-router-dom";
import { GET_PROJECTS } from "app/graphql/SearchProject";
import AdminNavbar from "app/components/AdminNavbar";
import { GET_PROJECT_APPLICANTS } from "app/graphql/projects";
import CommonModal from "app/components/CommonModal";
import { UPDATE_PROJECT_CANDIDATE_STATUS } from "app/graphql/users";
import { useSnackbar } from "notistack";

function ViewNewProject() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { projectId }: any = useParams();
  const [sortBy, setSortBy] = React.useState("-created_date");
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const [selectedCandidateId, setSelectedCandidateId] = React.useState(null);
  const [candidates, setCandidates] = React.useState([]);

  const [rowCount, setRowCount] = React.useState(0);

  const {
    data: projectData,
    loading: getProjectDetailsLoading,
    refetch: refetchProjectData
  } = useQuery(GET_PROJECT_APPLICANTS, {
    variables: {
      filter: {
        projectId: projectId
      },
      page: page + 1,
      limit: pageSize,
      sort_by: sortBy
    },
    onError: (error) => {
      console.error("🚀 ~ onError error:", error);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    console.log(projectData);
    if (
      projectData &&
      projectData?.projectCandidates &&
      projectData?.projectCandidates?.projectcandidates
    ) {
      console.log(projectData);
      setCandidates(projectData?.projectCandidates?.projectcandidates);
      setRowCount(projectData?.projectCandidates?.count);
    } else {
      console.log(projectData);
      setCandidates([]);
      setRowCount(0);
    }
  }, [projectData]);

  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const columns = React.useMemo(
    () => [
      {
        field: "name",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            Name
          </Typography>
        ),
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="14px">
              {params?.row?.candidate?.firstName || ""}
            </Typography>
          </Box>
        )
      },
      {
        field: "location",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            Location
          </Typography>
        ),
        minWidth: 120,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.candidate?.city?.name || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "email",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            Email Id
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.candidate?.email || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "mobile",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            Phone Number
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.candidate?.mobile || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "status",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            Status
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedCandidateId(params?.row?.id);
              setConfirmationModal(true);
            }}
          >
            Approve
          </Button>
        )
      }
    ],
    []
  );

  console.log("candidates", candidates);

  const [
    updateProjectCandidateStatus,
    { loading: changeProjectCandidateStatusLoading }
  ] = useMutation(UPDATE_PROJECT_CANDIDATE_STATUS, {
    onCompleted: (data: any) => {
      console.log(data);
      const { updateProjectCandidate } = data;
      const { status, message, error } = updateProjectCandidate;
      if (status) {
        setConfirmationModal(false);
        enqueueSnackbar(message, { variant: "success" });
        setTimeout(() => {
          history.push("/publish-project");
        }, 1000);
        refetchProjectData();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    },
    fetchPolicy: "network-only"
  });

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography fontSize="22px" fontWeight={500}>
          View Applicants
        </Typography>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {candidates && candidates.length > 0 ? (
          <Table
            rows={candidates || []}
            columns={columns}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount || 10}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        ) : (
          <NoDataFound message={"No applications have been received yet."} />
        )}
      </Grid>
      <CommonModal
        open={confirmationModal}
        setOpen={setConfirmationModal}
        children={
          <>
            <Box
              sx={{
                width: "400px",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                // alignItems: "start"
                mt: -4
              }}
            >
              <Typography fontSize="20px" fontWeight={500}>
                Are you sure?
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 3,
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                size="small"
                onClick={() => setConfirmationModal(false)}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                size="small"
                onClick={() => {
                  updateProjectCandidateStatus({
                    variables: {
                      payload: {
                        id: [selectedCandidateId],
                        status: 8
                      }
                    }
                  });
                }}
              >
                Yes, proceed
              </Button>
            </Box>
          </>
        }
      />
    </Grid>
  );
}

export function ViewNewProjectApplicants(props: any) {
  return <AdminNavbar mainComponent={<ViewNewProject />} />;
}
