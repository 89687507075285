import * as React from "react";
import { Box } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { DataGrid, GridDensity, GridRow } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import CustomPagination from "../../components/PaginationDataGrid";
import { colors } from "../../../styles/colors";
import { isDateDifferenceMoreThan90Days } from "utils/helpers";

const StyledDataGrid = styled(DataGrid)(() => ({
  border: "1px solid #E3E3E3",
  borderRadius: "4px",
  fontFamily: ["Neuton"].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnHeaderTitle": {
    color: colors.fontLight,
    fontSize: "14px",
    fontWeight: 500
  },
  "& .MuiDataGrid-cell": {
    fontSize: "12px",
    color: colors.fontLight
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none"
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none"
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none"
  },
  "& .MuiDataGrid-columnHeadersInner": {
    backgroundColor: "#F5F5F5"
  }
  // "& .MuiDataGrid-cell--withRenderer": {
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
  // "& .MuiDataGrid-columnHeaderTitleContainer": {
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
}));

function CustomRow(props) {
  const { className, index, ...other } = props;

  return (
    <GridRow
      index={index}
      style={
        index % 2 === 0
          ? { backgroundColor: colors.secondary }
          : { backgroundColor: colors.primaryLight }
      }
      {...other}
    />
  );
}

function CustomRowWithExpiry(props) {
  const { className, index, rows, ...other } = props;

  const date =
    (rows && rows?.length > 0 && rows[index]?.partnershipDetails?.endDate) ||
    null;

  const isNotExpiringIn90Days = date && isDateDifferenceMoreThan90Days(date);
  return (
    <GridRow
      index={index}
      style={
        isNotExpiringIn90Days
          ? index % 2 === 0
            ? { backgroundColor: colors.secondary }
            : { backgroundColor: colors.primaryLight }
          : { backgroundColor: colors.dangerFill }
      }
      {...other}
    />
  );
}

function CustomUnSortIcon(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ArrowDropUp
        fontSize="small"
        sx={{ mb: -2, color: colors.fontLighter }}
      />
      <ArrowDropDown fontSize="small" sx={{ color: colors.fontLighter }} />
    </div>
  );
}

function CustomAscIcon(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ArrowDropUp fontSize="small" sx={{ mb: -2 }} />
      <ArrowDropDown fontSize="small" sx={{ color: colors.fontLighter }} />
    </div>
  );
}

function CustomDescIcon(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ArrowDropUp
        fontSize="small"
        sx={{ mb: -2, color: colors.fontLighter }}
      />
      <ArrowDropDown fontSize="small" />
    </div>
  );
}

interface ITableProps {
  rows: any;
  columns: any;
  checkboxSelection?: boolean;
  hideFooter?: boolean;
  hideSortIcons?: boolean;
  density?: GridDensity;
  selectedRows?: any;
  setSelectedRows?: any;
  rowCount?: number;
  setPageSize?: (page: number) => void;
  setPage?: (page: number) => void;
  page?: number;
  pageSize?: number;
  getRowId?: any;
  exportOptions?: any;
  highlightExpiringPartners?: boolean;
}

export default function Table(props: ITableProps) {
  const {
    rows,
    columns,
    checkboxSelection,
    hideFooter,
    hideSortIcons,
    density,
    selectedRows,
    setSelectedRows,
    rowCount,
    setPageSize,
    setPage,
    page,
    pageSize,
    getRowId,
    exportOptions,
    highlightExpiringPartners
  } = props;
  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
          {
            width: "auto",
            visibility: hideSortIcons ? "hidden" : "visible"
          },
        "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
          {
            opacity: 0.5
          }
      }}
    >
      <StyledDataGrid
        slotProps={exportOptions}
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        rowCount={rowCount}
        density={density || "standard"}
        getRowHeight={() => "auto"}
        autoHeight
        checkboxSelection={checkboxSelection || false}
        rows={rows}
        rowsPerPageOptions={[5, 10, 15, 20]}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        hideFooterSelectedRowCount
        hideFooter={hideFooter || false}
        onSelectionModelChange={(selectionModel, details) =>
          setSelectedRows && setSelectedRows(selectionModel)
        }
        selectionModel={selectedRows || []}
        components={{
          Row: highlightExpiringPartners ? CustomRowWithExpiry : CustomRow,
          ColumnSortedAscendingIcon: CustomAscIcon,
          ColumnSortedDescendingIcon: CustomDescIcon,
          ColumnUnsortedIcon: CustomUnSortIcon,
          Pagination: CustomPagination
        }}
        componentsProps={{
          pagination: {
            setPage: setPage,
            setPageSize: setPageSize,
            page: page,
            pageSize: pageSize,
            rowCount: rowCount,
            pageCount: rowCount && pageSize ? Math.ceil(rowCount / pageSize) : 0
          },
          row: {
            rows: rows // Pass the rows data to the CustomRow component
          }
        }}
        {...(getRowId && { getRowId: getRowId })}
        initialState={{
          pagination: {
            pageSize: 10
          }
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "8px",
            pl: "2px"
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
            pl: "5px"
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "30px",
            pl: "10px"
          }
        }}
      />
    </Box>
  );
}
