import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  FormHelperText
} from "@mui/material";
import { colors } from "../../../styles/colors";
import {
  GET_JOB_CATEGORIES,
  GET_INDUSTRIES,
  GET_SERVICES,
  GET_STATES,
  GET_CITIES,
  GET_ORGANIZATION_DROPDOWN_OPTIONS,
  GET_FILTERED_CITIES
} from "app/graphql/meta";
import { GET_ALL_LOCATIONS } from "app/graphql/users";
import { useQuery } from "@apollo/client";
import {
  bsuinessDevStatusArr,
  jobModesArr,
  projectStatusChoices
} from "../../../utils/constants";
import RadioGroups from "app/components/RadioGroup";

interface FiltersProps {
  setFilter: any;
  filter: any;
  setQueryFilter: any;
}

const Filters = (props: FiltersProps) => {
  const { filter, setFilter } = props;

  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);
  const [statesArr, setStatesArr] = React.useState([]);
  const [citiesArr, setCitiesArr] = React.useState([]);
  const [organizationArr, setOrganizationArr] = React.useState([]);

  const { data: organizations } = useQuery(GET_ORGANIZATION_DROPDOWN_OPTIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {}
  });

  React.useEffect(() => {
    if (industries) {
      console.log(industries);
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  React.useEffect(() => {
    if (organizations && organizations?.organizationDropDown?.organization) {
      console.log(organizations);
      setOrganizationArr(
        organizations?.organizationDropDown?.organization || []
      );
    }
  }, [organizations]);

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" }
  });

  React.useEffect(() => {
    if (states) {
      console.log(states);
      setStatesArr(states?.states || []);
    }
  }, [states]);

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: filter?.stateId },
    skip: !filter?.stateId
  });

  React.useEffect(() => {
    if (cities) {
      console.log(cities);
      setCitiesArr(cities?.cities || []);
    }
  }, [cities]);

  const setFilterVariables = (key, val) => {
    setFilter({ ...filter, [key]: val });
  };

  const [errors, setErrors] = React.useState<any>({});

  const validate = () => {
    let newErrors = {};
    let dirty = false;

    if (filter?.minimumRemuneration__gte) {
      if (filter?.minimumRemuneration__gte < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Min Remuneration cannot be less than 0"
        };

        dirty = true;
      }
    }

    if (filter?.maximumRemuneration__lte) {
      if (filter?.maximumRemuneration__lte < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Max Remuneration cannot be less than 0"
        };
        dirty = true;
      }
    }

    if (
      filter?.minimumRemuneration__gte &&
      filter?.maximumRemuneration__lte &&
      filter?.maximumRemuneration__lte < filter?.minimumRemuneration__gte
    ) {
      newErrors = {
        ...newErrors,
        remuneration: "Min Remuneration cannot be more than Max Remuneration"
      };
      dirty = true;
    }

    if (dirty) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }

    return dirty;
  };

  const { data: locations, loading: loadingLocations } = useQuery(
    GET_ALL_LOCATIONS,
    {
      variables: {}
    }
  );

  const [cityIdFilter, setCityIdFilter] = React.useState(filter?.cityId || "");

  const [filteredCityIdOptions, setFilteredCityIdOptions] = React.useState([]);

  const { data: filteredCities, loading: filteredCitiesLoading } = useQuery(
    GET_FILTERED_CITIES,
    {
      variables: {
        filter: {
          name__icontains: cityIdFilter
        }
      },
      // skip: !cityIdFilter,
      onCompleted: (data) => {
        const options =
          data?.getAllCities && data?.getAllCities?.city
            ? data?.getAllCities?.city
            : [];
        setFilteredCityIdOptions(options);
      }
    }
  );

  const [timer, setTimer] = React.useState<any>(null);

  const handleCityIdInputChange = (value) => {
    console.log(value);
    // setFieldValue("cityId", "");
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setCityIdFilter(value);
      }, 700)
    );
  };

  console.log("filteredCityIdOptions", filteredCityIdOptions);

  return (
    <Box sx={{ borderRadius: "4px", border: "1px solid #E3E3E3" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Typography fontSize="16px" color={colors.fontLight}>
            FILTERS
          </Typography>
        </Grid>
        <Grid item sx={{ cursor: "pointer" }}>
          <Typography
            fontSize="12px"
            color={colors.primary}
            sx={{ textDecoration: "underline" }}
            onClick={() => {
              setFilter({});
              if (!validate()) {
                props.setQueryFilter({ status__in: [0, 21, 15, 22] });
              }
            }}
          >
            Clear all filters
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{
          height: 1,
          backgroundColor: "#E9E9E9",
          width: "100%"
        }}
      />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Project Category
          </Typography>
          <Autocomplete
            id="project_category__serviceId"
            autoComplete
            value={
              filter?.project_category__serviceId &&
                servicesArr &&
                servicesArr?.length > 0
                ? servicesArr?.find(
                  (x: any) => x.id === filter?.project_category__serviceId
                )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("project_category__serviceId", value?.id);
            }}
            options={servicesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose category"
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Title
          </Typography>
          <TextField
            id="title__icontains"
            placeholder="Enter title"
            size="small"
            fullWidth
            value={filter?.title__icontains ? filter?.title__icontains : ""}
            onChange={(e) =>
              setFilterVariables("title__icontains", e.target.value)
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Industry
          </Typography>
          <Autocomplete
            id="industryId"
            autoComplete
            value={
              filter?.industryId && industriesArr && industriesArr?.length > 0
                ? industriesArr?.find((x: any) => x.id === filter?.industryId)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("industryId", value?.id);
            }}
            options={industriesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose industry"
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Client
          </Typography>
          <TextField
            id="org__icontains"
            size="small"
            fullWidth
            placeholder="Enter Client Name"
            value={filter?.org__icontains ? filter?.org__icontains : ""}
            onChange={(e) =>
              setFilterVariables("org__icontains", e.target.value)
            }
          />
          {/* <Autocomplete
            id="organizationId"
            value={
              filter.organizationId &&
              organizations &&
              organizations?.organizationDropDown &&
              organizations?.organizationDropDown?.organization?.length > 0
                ? organizations?.organizationDropDown?.organization?.find(
                    (item: any) => item?.id === filter.organizationId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("organizationId", value?.id);
            }}
            disableClearable={true}
            options={organizationArr || []}
            getOptionLabel={(option: any) => option.name}
            autoComplete
            sx={{
              backgroundColor: colors.white
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: "4px"
                }}
                placeholder="Choose Client"
              />
            )}
          /> */}
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            State
          </Typography>
          <Autocomplete
            id="stateId"
            autoComplete
            value={
              filter?.stateId && statesArr && statesArr?.length > 0
                ? statesArr?.find((x: any) => x.id === filter?.stateId)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("stateId", value?.id);
            }}
            options={statesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            City
          </Typography>
          <Autocomplete
            id="cityId"
            autoComplete
            disableClearable={true}
            value={
              filter?.cityId &&
                filteredCityIdOptions &&
                filteredCityIdOptions?.length > 0
                ? filteredCityIdOptions?.find(
                  (x: any) => x.id === filter?.cityId
                )
                : null
            }
            onInputChange={(e, newInputValue) => {
              handleCityIdInputChange(newInputValue);
            }}
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("cityId", value?.id);
            }}
            options={filteredCityIdOptions ? filteredCityIdOptions : []}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            sx={{
              "& .MuiAutocomplete-tag": {
                backgroundColor: colors.primaryBlue,
                marginRight: "8px",
                color: colors.secondary
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose city" />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Status
          </Typography>
          <Autocomplete
            id="status__in"
            value={
              filter?.status__in && projectStatusChoices
                ? projectStatusChoices?.find(
                  (x: any) => x.id === filter?.status__in
                )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("status__in", value?.id);
            }}
            options={projectStatusChoices || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose status" />
            )}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Status
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            onChange={(_, value: any) =>
              setFilterVariables("industryId", value?.id)
            }
            value={
              industries?.industries && filter?.hasOwnProperty("industryId")
                ? industries.industries.find(
                    (x) => x.id === filter["industryId"]
                  )
                : null
            }
            options={industries?.industries || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid> */}
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Functional Area
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            onChange={(_, value: any) =>
              setFilterVariables("categoryId", value?.id)
            }
            value={
              categories?.jobTypes && filter?.hasOwnProperty("categoryId")
                ? categories.jobTypes.find((x) => x.id === filter["categoryId"])
                : null
            }
            options={categories?.jobTypes || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid> */}
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Key Skills
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            options={skills}
            size="small"
            freeSolo
            multiple
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
            ChipProps={{
              sx: { backgroundColor: colors.primary, color: colors.secondary },
            }}
          />
        </Grid> */}
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            fontSize="14px"
            color={colors.fontLight}
            sx={{ mt: 0.25 }}
          >
            Salary per annum
          </Typography>
          {errors?.remuneration && (
            <FormHelperText error>{errors?.remuneration}</FormHelperText>
          )}
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography fontSize="12px" color={colors.fontLight}>
                Min
              </Typography>
              <TextField
                onChange={(e) =>
                  setFilterVariables(
                    "minimumRemuneration__gte",
                    parseInt(e.target.value)
                  )
                }
                onBlur={validate}
                value={filter?.minimumRemuneration__gte || ""}
                size="small"
                type="number"
                fullWidth
                sx={{ mt: 0.25 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography fontSize="12px" color={colors.fontLight}>
                Max
              </Typography>
              <TextField
                onChange={(e) =>
                  setFilterVariables(
                    "maximumRemuneration__lte",
                    parseInt(e.target.value)
                  )
                }
                onBlur={validate}
                value={filter?.maximumRemuneration__lte || ""}
                size="small"
                type="number"
                fullWidth
                sx={{ mt: 0.25 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Job Type
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            disableClearable
            onChange={(_, value: any) =>
              setFilterVariables("jobMode", value.id)
            }
            value={
              jobModesArr.find((x) => x.id === filter["jobMode"])
                ? jobModesArr.find((x) => x.id === filter["jobMode"])
                : {}
            }
            getOptionLabel={(option: any) => option.name || ""}
            options={jobModesArr}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Location
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            options={locations?.allCities || []}
            onChange={(_, value: any) =>
              setFilterVariables("cityId", value?.id)
            }
            value={
              locations?.allCities && filter?.hasOwnProperty("cityId")
                ? locations.allCities.find((x) => x.id === filter["cityId"])
                : {}
            }
            getOptionLabel={(option) => option.name || ""}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid> */}
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
        >
          <Button
            variant="contained"
            sx={{ width: "70%", my: 2 }}
            onClick={() => {
              if (!validate()) {
                props.setQueryFilter({ ...filter });
              }
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
