/**
 * Asynchronously loads the component for EmployerSearchCandidates
 */

import { lazyLoad } from "utils/loadable";

export const BPManageClients = lazyLoad(
  () => import("./index"),
  (module) => module.BPManageClients
);
