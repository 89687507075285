import * as React from "react";
import { Grid } from "@mui/material";
import NavBar from "app/components/Navbar";
import ProfileDivisions from "./ProfileDivisions";
import PartnershipDetails from "./PartnershipDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import PasswordForm from "./PasswordForm";
import PasswordModal from "./PasswordModal";
import LogoutModal from "../../components/LogoutModal";
import { getLoggedInUserData } from "../../../utils/helpers";

import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_STUDENT_DETAILS,
  UPDATE_PROFILE,
  GET_VIDEO_INTRODUCTION
} from "../../graphql/Profile";
import getStore from "../../../store/zustandStore";
import { GET_USER_DETAILS } from "app/graphql/users";
import { useParams } from "react-router-dom";
import AdminNavbar from "app/components/AdminNavbar";

const divisions = [
  { id: 1, name: "Partnership Details" },
  { id: 2, name: "Professional Details" }
];

function getSelectedForm(
  selectedDivision,
  setSelectedDivision,
  partnerId,
  setPartnerId,
  setConfirmPasswordModal,
  userData,
  updateProfile,
  refetch
) {
  if (selectedDivision === 1) {
    return (
      <PartnershipDetails
        userData={userData}
        updateProfile={updateProfile}
        setPartnerId={setPartnerId}
        setSelectedDivision={setSelectedDivision}
      />
    );
  }
  if (selectedDivision === 2) {
    return (
      <ProfessionalDetails
        partnerId={partnerId}
        userData={userData}
        updateProfile={updateProfile}
        refetch={() => {
          refetch();
        }}
      />
    );
  }
}

function ProfileComponent() {
  const me = getLoggedInUserData();
  const { enqueueSnackbar } = useSnackbar();

  const [userData, setUserData] = React.useState(null);
  const [selectedDivision, setSelectedDivision] = React.useState(1);
  const [confirmPasswordModal, setConfirmPasswordModal] = React.useState(false);
  const [logoutModal, setLogoutModal] = React.useState(false);
  const [partnerId, setPartnerId] = React.useState("");

  const uriParams: any = useParams();
  const { partnerId: userId } = uriParams;

  const {
    data: userDetails,
    loading,
    refetch
  } = useQuery(GET_USER_DETAILS, {
    variables: { userId: userId },
    // skip: !userId,
    onCompleted: (data) => {
      console.log(data);
      const { user } = data;
      console.log(user);
      if (user?.id) {
        setUserData(user);
        console.log("user details is here");
        console.log(user);
      }
    },
    fetchPolicy: "network-only"
  });

  const [updateProfile, { loading: updating }] = useMutation(UPDATE_PROFILE, {
    onCompleted: (data) => {
      const { updateUser } = data;
      const { status, message } = updateUser;
      if (status) {
        refetch();
        // refetchVideo();
        enqueueSnackbar(message, {
          variant: "success"
        });
      } else {
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
  });

  // React.useEffect(() => {
  //   if (me && me.id) {
  //     setUserId(me.id);
  //   }
  // }, [me]);

  React.useEffect(() => {
    if (userDetails && userDetails.user) {
      setUserData(userDetails.user);
    }
  }, [userDetails]);

  // React.useEffect(() => {
  //   setLoading(loading || updating || gettingVideo);
  // }, [loading, setLoading, gettingVideo, updating]);
  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={4} sm={4} xs={12}>
        <ProfileDivisions
          divisions={divisions}
          selected={selectedDivision}
          setSelected={setSelectedDivision}
          setLogoutModal={setLogoutModal}
        />
      </Grid>
      <Grid item lg={9} md={8} sm={8} xs={12}>
        {getSelectedForm(
          selectedDivision,
          setSelectedDivision,
          partnerId,
          setPartnerId,
          setConfirmPasswordModal,
          userData,
          updateProfile,
          refetch
        )}
      </Grid>
    </Grid>
  );
}

export const AddEditNewPartner = (props) => {
  return <AdminNavbar mainComponent={<ProfileComponent />} />;
};
