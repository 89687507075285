import * as React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import HorizontalDivider from "app/components/HorizontalDivider";
import { colors } from "../../../styles/colors";
import { bsuinessDevStatusArr, projectCategoryArr } from "utils/constants";
import {
  GET_CITIES,
  GET_INDUSTRIES,
  GET_SERVICES,
  GET_STATES
} from "app/graphql/meta";
import { useQuery } from "@apollo/client";

interface FiltersProps {
  setFilter: any;
  filter: any;
  setQueryFilter: any;
}

const Filters = (props: FiltersProps) => {
  const { filter, setFilter, setQueryFilter } = props;

  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);
  const [statesArr, setStatesArr] = React.useState([]);
  const [citiesArr, setCitiesArr] = React.useState([]);

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {}
  });

  React.useEffect(() => {
    if (industries) {
      console.log(industries);
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" }
  });

  React.useEffect(() => {
    if (states) {
      console.log(states);
      setStatesArr(states?.states || []);
    }
  }, [states]);

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: filter?.state__id },
    skip: !filter?.state__id
  });

  React.useEffect(() => {
    if (cities) {
      console.log(cities);
      setCitiesArr(cities?.cities || []);
    }
  }, [cities]);

  const setFilterVariables = (key, val) => {
    setFilter({ ...filter, [key]: val });
  };

  return (
    <Box sx={{ borderRadius: "4px", border: "1px solid #E3E3E3" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Typography fontSize="16px" color={colors.fontLight}>
            FILTERS
          </Typography>
        </Grid>
        <Grid item sx={{ cursor: "pointer" }}>
          <Typography
            fontSize="12px"
            color={colors.primary}
            sx={{ textDecoration: "underline" }}
            onClick={() => {
              setFilter({});
              setQueryFilter({});
              setCitiesArr([]);
            }}
          >
            Clear all filters
          </Typography>
        </Grid>
      </Grid>
      <HorizontalDivider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Client Name
          </Typography>
          <TextField
            id="companyName__icontains"
            size="small"
            fullWidth
            placeholder="Enter client name"
            value={
              filter?.companyName__icontains
                ? filter.companyName__icontains
                : ""
            }
            onChange={(e: any) =>
              setFilterVariables("companyName__icontains", e.target.value)
            }
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            State
          </Typography>
          <Autocomplete
            id="state__id"
            autoComplete
            value={
              filter?.state__id && statesArr && statesArr?.length > 0
                ? statesArr?.find((x: any) => x.id === filter?.state__id)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("state__id", value?.id);
            }}
            options={statesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose state" />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            City
          </Typography>
          <Autocomplete
            id="city__id"
            autoComplete
            value={
              filter?.city__id && citiesArr && citiesArr?.length > 0
                ? citiesArr?.find((x: any) => x.id === filter.city__id)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("city__id", value?.id);
            }}
            options={citiesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose city" />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Industry
          </Typography>
          <Autocomplete
            id="industry__id"
            autoComplete
            value={
              filter.industry__id && industriesArr && industriesArr?.length > 0
                ? industriesArr?.find((x: any) => x.id === filter.industry__id)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("industry__id", value?.id);
            }}
            options={industriesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose industry"
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Project Category
          </Typography>
          <Autocomplete
            id="enquiry__serviceId"
            autoComplete
            value={
              filter?.enquiry__serviceId &&
              servicesArr &&
              servicesArr?.length > 0
                ? servicesArr?.find(
                    (x: any) => x.id === filter?.enquiry__serviceId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("enquiry__serviceId", value?.id);
            }}
            options={servicesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose category"
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Status
          </Typography>
          <Autocomplete
            id="enquiryStatus__icontains"
            value={
              filter?.enquiryStatus__icontains && bsuinessDevStatusArr
                ? bsuinessDevStatusArr?.find(
                    (x: any) => x.id === filter?.enquiryStatus__icontains
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("enquiryStatus__icontains", value?.id);
            }}
            options={bsuinessDevStatusArr || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose status" />
            )}
          />
        </Grid>
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
        >
          <Button
            variant="contained"
            sx={{ width: "70%", my: 2 }}
            onClick={() => {
              setQueryFilter({ ...filter });
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
