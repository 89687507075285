import * as React from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Fade,
  Fab,
  Avatar,
  Paper,
  Breadcrumbs,
  useScrollTrigger,
  useMediaQuery,
  Menu,
  MenuItem
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CommonFooter from "./CommonFooter";
import CampusNotificationsMenu from "app/components/CampusNotificationsMenu";
import {
  getAdminNavbarSelected,
  getCampusFLowBreadcrumbs,
  clearSession,
  getUserInfo,
  isJwtTokenValid
} from "utils/helpers";
import images from "../../assets/images/images";
import { colors } from "../../styles/colors";
import theme from "../../styles/theme";

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
  mainComponent: React.ReactNode;
}
const { roles, isVerified } = isJwtTokenValid();
// console.log("🚀 ~ file: AdminNavbar.tsx:46 ~ roles:", roles)

const drawerWidth = 240;

// let navItems = [
//   { id: 0, name: "Freelancer/Company", path: "/admin-entities" },
//   { id: 1, name: "New Registrations", path: "/admin-new-registrations" },
//   { id: 2, name: "Projects", path: "/admin/projects" },
//   { id: 3, name: "Manage User", path: "/admin/manage-user" },
// ];

// if (roles[0] === "super_user") {
//   navItems = [
//     { id: 0, name: "Freelancer/Company", path: "/admin-entities" },
//     { id: 1, name: "New Registrations", path: "/admin-new-registrations" },
//     { id: 2, name: "Projects", path: "/admin/projects" },
//     { id: 3, name: "Search Candidates", path: "/admin/search-candidates" },
//   ];
// } else if (roles[0] === "super_admin") {
//   navItems = navItems = [
//     { id: 0, name: "Freelancer/Company", path: "/admin-entities" },
//     { id: 1, name: "New Registrations", path: "/admin-new-registrations" },
//     { id: 2, name: "Projects", path: "/admin/projects" },
//     { id: 3, name: "Manage User", path: "/admin/manage-user" },
//     { id: 4, name: "Search Candidates", path: "/admin/search-candidates" },
//   ];
// }

function ScrollTop(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center"
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const AdminNavbar = (props: Props) => {
  const { window, mainComponent } = props;
  const history = useHistory();
  const location = useLocation();
  const { roles, isVerified } = isJwtTokenValid();

  const [navItems, setNavItems] = React.useState([
    { id: 0, name: "Register Partner", path: "/partner-registration" },
    { id: 1, name: "Business Development", path: "/new-business-development" },
    { id: 2, name: "Publish Projects", path: "/publish-project" },
    { id: 3, name: "Manage BP projects", path: "/manage-new-project" },
    { id: 4, name: "Manage Clients", path: "/admin/manage-client" },
    { id: 5, name: "Manage User", path: "/admin/manage-user" }
  ]);

  React.useEffect(() => {
    if (roles[0] === "super_user") {
      setNavItems([
        { id: 0, name: "Register Partner", path: "/partner-registration" },
        {
          id: 1,
          name: "Business Development",
          path: "/new-business-development"
        },
        { id: 2, name: "Publish Projects", path: "/publish-project" },
        { id: 3, name: "Manage BP projects", path: "/manage-new-project" },
        { id: 4, name: "Manage Clients", path: "/admin/manage-client" }
      ]);
    } else if (roles[0] === "super_admin") {
      setNavItems([
        { id: 0, name: "Register Partner", path: "/partner-registration" },
        {
          id: 1,
          name: "Business Development",
          path: "/new-business-development"
        },
        { id: 2, name: "Publish Projects", path: "/publish-project" },
        { id: 3, name: "Manage BP projects", path: "/manage-new-project" },
        { id: 4, name: "Manage Clients", path: "/admin/manage-client" },
        { id: 5, name: "Manage User", path: "/admin/manage-user" }
      ]);
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { firstName } = getUserInfo();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState<
    { id: number; name: string; path: string; color: string }[]
  >([]);

  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const me = sessionStorage.getItem("me");
  let id = "";

  if (me) {
    const meObj = JSON.parse(me);
    id = meObj.id;
  }

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <img
          src={images.ezyconseil_logo_color}
          width={175}
          alt="logo"
          style={{
            marginTop: "-12px",
            marginBottom: "-12px"
          }}
        />
      </Box>
      <Divider />
      <List>
        {navItems?.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={item.name}
                onClick={() => {
                  history.push(item.path);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    setBreadcrumbs(getCampusFLowBreadcrumbs(location));
  }, [location]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" elevation={0} color="primary">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "block" }}
              // onClick={() => history.push("/")}
            >
              <img
                src={images.ezyconseil_logo_white_png}
                width={175}
                alt="logo"
                style={{
                  marginTop: "-12px",
                  marginBottom: "-12px"
                }}
              />
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" }
              }}
            >
              {navItems &&
                navItems?.map((item, index) => (
                  <Typography
                    key={item.id}
                    color={
                      getAdminNavbarSelected(location, item)
                        ? colors.secondary
                        : `${colors.secondary}50`
                    }
                    fontSize="15px"
                    sx={{ cursor: "pointer", mr: 1.5 }}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    {item.name}
                  </Typography>
                ))}
            </Box>
            {/* <CampusNotificationsMenu roles={roles} /> */}
            <Avatar
              sx={{
                cursor: "pointer",
                color: colors.primary,
                backgroundColor: colors.secondary
              }}
              onClick={() => history.push(`/admin-profile/${id}`)}
            >
              {firstName?.length > 0 ? firstName[0] : ""}
            </Avatar>
            <Menu
              id="campus-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
            >
              <MenuItem
                onClick={() => {
                  clearSession();
                  history.push("/");
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ width: "100%" }}>
          <Toolbar id="back-to-top-anchor" />
          {/* <Paper
            elevation={1}
            sx={{ p: 2, pl: matchesMd ? 5 : 15, borderRadius: 0 }}
          >
            <Breadcrumbs separator={">"} aria-label="breadcrumb">
              {breadcrumbs.map((crumb) => (
                <NavLink
                  key={crumb.id}
                  role="button"
                  to={crumb.path}
                  style={{
                    textDecoration: "none",
                    color: crumb.color,
                    fontSize: "16px",
                  }}
                >
                  {crumb.name}
                </NavLink>
              ))}
            </Breadcrumbs>
          </Paper> */}
          <Box sx={{ py: 3, px: matchesMd ? 5 : 15 }}>{mainComponent}</Box>
        </Box>
      </Box>
      <CommonFooter />
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default AdminNavbar;
