import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Filters from "./Filters";
import { useMutation, useQuery } from "@apollo/client";
import NoDataFound from "app/components/NoData";
import Table from "../../components/Table";
import "../../components/Table/StatusButton.css";
import { useHistory } from "react-router-dom";
import AdminNavbar from "app/components/AdminNavbar";
import { GET_ALL__REGISTERED_USERS } from "app/graphql/AdminDevelopmentQuries";
import images from "assets/images/images";
import {
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { CheckBox, DeleteOutline } from "@mui/icons-material";
import { colors } from "styles/colors";
import { DELETE_USER } from "app/graphql/users";
import { useSnackbar } from "notistack";
import { isDateDifferenceMoreThan90Days } from "utils/helpers";

function AdminRegisterPartnerDashboard() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = React.useState("-created_date");
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryFilter, setQueryFilter] = React.useState({});
  const [propertiesFilter, setPropertiesFilter] = React.useState(null);

  const isExpired = (expiryDate) => {
    // Convert input string to Date object
    const currentDate = new Date();
    const inputDate = new Date(expiryDate);

    // Calculate the date 3 months ago
    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    // Compare input date with the date 3 months ago
    return inputDate <= threeMonthsAgo;
  };

  const [userViewData, setUserDetails] = React.useState([]);

  const [projectsCount, setProjectsCount] = React.useState(0);

  React.useEffect(() => {
    console.log("queryFilter changed:", queryFilter);
  }, [queryFilter]);

  const {
    data: userDetails,
    loading,
    refetch
  } = useQuery(GET_ALL__REGISTERED_USERS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: queryFilter,
      sort_by: sortBy,
      is_freelancer: true
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    // debugger;
    if (userDetails && userDetails.users && userDetails.users.users) {
      setUserDetails(userDetails.users.users);
      setProjectsCount(userDetails.users.count);
    } else {
      setUserDetails([]);
    }
  }, [userDetails]);

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: (data) => {
      const { deleteUsers } = data;
      const { status, message } = deleteUsers;
      if (status) {
        refetch();
        enqueueSnackbar(message, { variant: "success" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  });

  const columns = React.useMemo(
    () => [
      {
        field: "name",
        headerName: <Typography fontSize="12px">NAME</Typography>,
        flex: 0.75,
        minWidth: 200,
        renderCell: (params) => (
          <Box>
            <Typography
              fontSize="14px"
              display="flex"
              justifyContent="center"
              // color={
              //   params?.row?.partnershipDetails?.endDate &&
              //   isDateDifferenceMoreThan90Days(
              //     params?.row?.partnershipDetails?.endDate
              //   )
              //     ? colors.fontPrimary
              //     : colors.danger
              // }
            >
              {params?.row?.firstName || ""} {params?.row?.lastName || ""}
            </Typography>
          </Box>
        )
      },
      {
        field: "city",
        headerName: <Typography fontSize="13px">CITY</Typography>,
        minWidth: 100,
        flex: 0.75,
        renderCell: (params) => (
          <Box>
            <Typography
              fontSize="12px"
              display="flex"
              justifyContent="center"
              // color={
              //   params?.row?.partnershipDetails?.endDate &&
              //   isDateDifferenceMoreThan90Days(
              //     params?.row?.partnershipDetails?.endDate
              //   )
              //     ? colors.fontPrimary
              //     : colors.danger
              // }
            >
              {params?.row?.city?.name || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "rate",
        headerName: (
          <>
            <Typography fontSize="13px" display="flex" justifyContent="center">
              WORKING RATE
            </Typography>
          </>
        ),
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography
              fontSize="12px"
              display="flex"
              justifyContent="center"
              // color={
              //   params?.row?.partnershipDetails?.endDate &&
              //   isDateDifferenceMoreThan90Days(
              //     params?.row?.partnershipDetails?.endDate
              //   )
              //     ? colors.fontPrimary
              //     : colors.danger
              // }
            >
              {params?.row?.expectedRates ? params?.row?.expectedRates : "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "category",
        headerName: (
          <>
            <div>
              <Typography
                fontSize="13px"
                display="flex"
                justifyContent="center"
              >
                PARTNER CATEGORY
              </Typography>
            </div>
          </>
        ),
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Box>
            <Typography
              fontSize="12px"
              display="flex"
              justifyContent="center"
              // color={
              //   params?.row?.partnershipDetails?.endDate &&
              //   isDateDifferenceMoreThan90Days(
              //     params?.row?.partnershipDetails?.endDate
              //   )
              //     ? colors.fontPrimary
              //     : colors.danger
              // }
            >
              {params?.row?.category === "A"
                ? "A-High-Profile Partners(Influential)"
                : params?.row?.category === "B"
                ? "B-Medium-Profile Partners(Established)"
                : params?.row?.category === "C"
                ? "C-Low-Profile Partners(Emerging)"
                : "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "endDate",
        headerName: <Typography fontSize="13px">END DATE</Typography>,
        minWidth: 100,
        flex: 0.75,
        renderCell: (params) => (
          <Box>
            <Typography
              fontSize="12px"
              display="flex"
              justifyContent="center"
              // color={
              //   params?.row?.partnershipDetails?.endDate &&
              //   isDateDifferenceMoreThan90Days(
              //     params?.row?.partnershipDetails?.endDate
              //   )
              //     ? colors.fontPrimary
              //     : colors.danger
              // }
            >
              <div
                className={
                  isExpired(params?.row?.partnershipDetails?.endDate)
                    ? "isExpired"
                    : ""
                }
              >
                {params?.row?.partnershipDetails?.endDate
                  ? dayjs(params?.row?.partnershipDetails?.endDate).format(
                      "DD-MM-YYYY"
                    )
                  : null}
              </div>
            </Typography>
          </Box>
        )
      },
      {
        field: "actions",
        type: "actions",
        headerName: <Typography fontSize="13px">ACTIONS</Typography>,
        minWidth: 140,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <span
                  onClick={() => {
                    history.push(`partner-registration/${params?.row?.id}`);
                  }}
                >
                  <img src={images.editIcon} alt="" />
                </span>
              }
              label="Edit"
            />,
            <GridActionsCellItem
              icon={
                <Box
                  color={colors.danger}
                  onClick={() => {
                    deleteUser({
                      variables: {
                        ids: [params?.row?.id]
                      }
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color: colors.danger,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <DeleteOutline fontSize="small" color="inherit" />
                </Box>
              }
              label="View"
            />
          ];
        }
      }
    ],
    []
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography fontSize="22px" fontWeight={500}>
          Partner Details
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
          alignItems="center"
        >
          <Grid item sx={{ display: "flex" }}>
            <Button
              variant="contained"
              sx={{ my: 2 }}
              onClick={() =>
                history.push(`/partner-registration/add-new-partner`)
              }
            >
              Register New Partner
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Filters
          filter={propertiesFilter}
          setFilter={setPropertiesFilter}
          setQueryFilter={setQueryFilter}
        />
      </Grid>
      <Grid item container lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {userViewData && userViewData.length > 0 ? (
            <Table
              rows={userViewData || []}
              columns={columns}
              page={page}
              pageSize={pageSize}
              rowCount={projectsCount || 10}
              setPage={setPage}
              setPageSize={setPageSize}
              exportOptions={{ toolbar: CustomToolbar }}
              highlightExpiringPartners={true}
            />
          ) : (
            <NoDataFound />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export function AdminRegisterPartner(props: any) {
  return <AdminNavbar mainComponent={<AdminRegisterPartnerDashboard />} />;
}
