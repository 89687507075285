import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Checkbox,
  Button
} from "@mui/material";
import { colors } from "../../../styles/colors";
import { Box } from "@mui/system";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import images from "assets/images/images";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import { CheckBox, DeleteOutline } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../components/CommonModal";

const AddProjectExperienceDetails = (props) => {
  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    isEditing,
    setIsEditing,
    data,
    disabled
  } = props;

  //   const { rolePermissions } = getJwtTokenDetails();

  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    handleSubmit();
  };

  const handleSpinTheWheelChange = (event) => {
    const { name, checked } = event.target;
    const spinTheWheelCheckboxesArr = values.priorities?.map(
      (item) => item.spinTheWheel
    );
    const spinTheWheelCount = spinTheWheelCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (spinTheWheelCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };
  const handleMysteryBoxChange = (event) => {
    const { name, checked } = event.target;
    const mysteryBoxCheckboxesArr = values.priorities?.map(
      (item) => item.mysteryBox
    );
    const mysteryBoxCount = mysteryBoxCheckboxesArr.filter(
      (item) => item === true
    ).length;
    if (checked === true) {
      if (mysteryBoxCount <= 3) {
        setFieldValue(name, checked);
      } else {
        const message = "Only four levels can be selected";
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    } else {
      setFieldValue(name, checked);
    }
  };

  const handleAdd = () => {
    const tempId = data?.length + 1;
    data.push({
      id: tempId,
      priority: "Priority 4",
      minValue: "0",
      maxValue: "1000",
      name: "lorem"
    });
  };

  // const projectsTemp = data?.projects ? JSON.parse(data?.projects) : null;

  // console.log("projectsTemp", projectsTemp);

  // // const projectsTempData = projectsTemp ? JSON.parse(projectsTemp) : [];

  // const projectsArr = values.projects || projectsTemp || [];
  // // const projectsArr = values.projects || projectsTempData || [];

  const projectsTemp = data?.projects ? JSON.parse(data?.projects) : null;
  let projectsArr =
    values.projects && values.projects?.length > 0
      ? [...values.projects]
      : projectsTemp
      ? [...projectsTemp]
      : [];
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>(null);

  const FormModal = () => {
    const [saveClicked, setSaveClicked] = React.useState<any>(false);
    const [projectTitle, setProjectTitle] = React.useState<any>(
      selectedRow?.projectTitle || ""
    );
    const [company, setCompany] = React.useState<any>(
      selectedRow?.company || ""
    );
    const [fromDate, setFromDate] = React.useState<any>(
      selectedRow?.fromDate || ""
    );
    const [toDate, setToDate] = React.useState<any>(selectedRow?.toDate || "");
    const [role, setRole] = React.useState<any>(selectedRow?.role || "");
    const [industry, setIndustry] = React.useState<any>(
      selectedRow?.industry || ""
    );
    const [scope, setScope] = React.useState<any>(selectedRow?.scope || "");

    const index = selectedIndex;

    return (
      <Modal open={open} setOpen={setOpen}>
        <Grid container spacing={2} mt={-9}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start"
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight={500}
              color={colors.fontLight}
              textAlign="center"
            >
              Project Experience details
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Title<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={projectTitle || ""}
                onChange={(e) => {
                  setProjectTitle(e.target.value);
                }}
                disabled={disabled}
              />
              {/* {errors.projects &&
                errors.projects?.length > 0 &&
                errors?.projects[index] &&
                errors?.projects[index]?.projectTitle && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 14
                    }}
                  >
                    {errors?.projects[index]?.projectTitle}
                  </Typography>
                )} */}
              {saveClicked && projectTitle === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Company<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                type="max"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={company || ""}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                disabled={disabled}
              />
              {/* {errors.projects &&
                errors.projects?.length > 0 &&
                errors?.projects[index] &&
                errors?.projects[index]?.company && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.projects[index]?.company}
                  </Typography>
                )} */}
              {saveClicked && company === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              From<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              To<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box>
              <TextField
                size="small"
                type="min"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={fromDate || ""}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
                disabled={disabled}
              />
              {saveClicked && fromDate === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
              {/* {errors.workExperience &&
                errors.workExperience?.length > 0 &&
                errors?.workExperience[index] &&
                errors?.workExperience[index]?.duration && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.workExperience[index]?.duration}
                  </Typography>
                )} */}
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box>
              <TextField
                size="small"
                type="min"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={toDate || ""}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                disabled={disabled}
              />
              {saveClicked && toDate === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
              {/* {errors.workExperience &&
                errors.workExperience?.length > 0 &&
                errors?.workExperience[index] &&
                errors?.workExperience[index]?.duration && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.workExperience[index]?.duration}
                  </Typography>
                )} */}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Role<span style={{ color: colors.danger }}>*</span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                type="min"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={role || ""}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                disabled={disabled}
              />
              {/* {errors.projects &&
                errors.projects?.length > 0 &&
                errors?.projects[index] &&
                errors?.projects[index]?.role && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.projects[index]?.role}
                  </Typography>
                )} */}
              {saveClicked && role === "" && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 600
                  }}
                >
                  This is a required field
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Industry
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                size="small"
                type="max"
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%"
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  // "& .MuiOutlinedInput-input": {
                  //   height: "16px",
                  //   paddingBottom: "4px"
                  // }
                }}
                value={industry || ""}
                onChange={(e) => {
                  setIndustry(e.target.value);
                }}
                disabled={disabled}
              />
              {errors.projects &&
                errors.projects?.length > 0 &&
                errors?.projects[index] &&
                errors?.projects[index]?.industry && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.projects[index]?.industry}
                  </Typography>
                )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -1.5 }}>
            <Typography fontSize="14px" color={colors.fontLight}>
              Scope
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <TextField
                type="max"
                multiline
                rows={3}
                sx={{
                  backgroundColor: colors.white,
                  color: "#020202",
                  width: "100%",
                  // "& .MuiOutlinedInput-root": {
                  //   "& fieldset": {
                  //     border: "none",
                  //     borderColor: colors.lighterGray,
                  //     borderRadius: 0,
                  //     width: "50px",
                  //     ml: 1
                  //   }
                  // },
                  "& .MuiOutlinedInput-input": {
                    height: "16px",
                    paddingBottom: "4px"
                  }
                }}
                value={scope || ""}
                onChange={(e) => {
                  setScope(e.target.value);
                }}
                disabled={disabled}
              />
              {errors.projects &&
                errors.projects?.length > 0 &&
                errors?.projects[index] &&
                errors?.projects[index]?.scope && (
                  <Typography
                    sx={{
                      color: colors.danger,
                      fontSize: "11px",
                      fontWeight: 600,
                      ml: 1
                    }}
                  >
                    {errors?.projects[index]?.scope}
                  </Typography>
                )}
            </Box>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="end"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (
                  projectTitle !== "" &&
                  company !== "" &&
                  fromDate !== "" &&
                  toDate !== "" &&
                  role !== ""
                ) {
                  if (selectedRow) {
                    const temp = projectsArr?.map((item, index2) => {
                      if (index !== index2) {
                        return item;
                      } else {
                        return {
                          projectTitle,
                          company,
                          fromDate,
                          toDate,
                          role,
                          industry,
                          scope
                        };
                      }
                    });
                    console.log("projects temp", temp);
                    setFieldValue("projects", [...temp]);
                    setOpen(false);
                  } else {
                    setFieldValue("projects", [
                      {
                        projectTitle,
                        company,
                        fromDate,
                        toDate,
                        role,
                        industry,
                        scope
                      },
                      ...projectsArr
                    ]);
                    setOpen(false);
                  }
                } else {
                  setSaveClicked(true);
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  };

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: colors.white,
          border: "0.5px solid rgba(168, 168, 168, 0.5)",
          borderRadius: "0px 0px 4px 4px "
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: colors.white }}>
            <TableRow>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Title
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Company
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  From
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  To
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    pl: 2
                  }}
                >
                  Role
                </Typography>
              </TableCell>
              {!disabled && (
                <TableCell
                  sx={{ py: 1, display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      pl: 2
                    }}
                  >
                    Actions
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsArr && projectsArr?.length > 0 ? (
              projectsArr?.map((row, index) => (
                <>
                  <TableRow key={row?.id}>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>
                          {row?.projectTitle}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>{row?.company}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>{row?.fromDate}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>{row?.toDate}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: disabled ? 1 : 0 }}>
                      <Box>
                        <Typography fontSize={14}>{row?.role}</Typography>
                      </Box>
                    </TableCell>
                    {!disabled && (
                      <TableCell
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Box
                          color={colors.primaryBlue}
                          onClick={() => {
                            setSelectedRow(row);
                            setSelectedIndex(index);
                            setOpen(true);
                          }}
                          sx={{
                            mr: 1,
                            cursor: "pointer",
                            color: colors.fontLight,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignContent: "center",
                              marginRight: "5px",
                              paddingLeft: "2px",
                              marginTop: "-3px"
                            }}
                          >
                            <img src={images.editIcon} alt="" />
                          </span>
                        </Box>
                        <Box
                          color={colors.primaryBlue}
                          onClick={() => {
                            let temp = [...projectsArr];
                            temp.splice(index, 1);
                            setFieldValue("projects", temp);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: colors.danger,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <DeleteOutline fontSize="small" color="inherit" />
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  {index === projectsArr?.length - 1 && !disabled && (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        sx={{
                          py: 0,
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <Button
                          onClick={() => {
                            setSelectedRow(null);
                            setOpen(true);
                          }}
                        >
                          Add{" "}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <>
                {disabled ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "start"
                      }}
                    >
                      No Data
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                        display: "flex",
                        justifyContent: "end"
                      }}
                    >
                      <Button
                        onClick={() => {
                          setSelectedRow(null);
                          setOpen(true);
                        }}
                      >
                        Add{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
            <FormModal />
          </TableBody>
        </Table>
      </TableContainer>
      {values.loading && (
        <Box
          sx={{
            position: "absolute",
            top: "130%",
            left: "57%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.primaryBlue
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default AddProjectExperienceDetails;
