import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
// import Navbar from "app/components/Navbar";
import NoBorderDropdown from "app/components/NoBorderDropdown";
import Filters from "./Filters";
import { bsuinessDevStatusArr } from "utils/constants";
import { colors } from "../../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import NoDataFound from "app/components/NoData";
import {
  CheckCircleOutlineOutlined,
  CancelOutlined,
  FactCheckOutlined,
  GroupsOutlined
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Table from "../../components/Table";
import "../../components/Table/StatusButton.css";
import { useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import images from "assets/images/images";
import {
  GET_ALL_ENQUIRIES,
  DELETE_ENQUIRY,
  UPDATE_ENQUIRY
} from "app/graphql/Enquiry";
import { useSnackbar } from "notistack";
import NavBar from "app/components/Navbar";
import AddEditEnquiry from "./AddEditEnquiry";
import { GET_ALL_STATES, GET_INDUSTRIES, GET_SERVICES } from "app/graphql/meta";
import { getDateInFormat } from "utils/helpers";

function BusinessDevelopmentContent() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = React.useState("-created_date");
  const [students, setStudents] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [queryFilter, setQueryFilter] = React.useState({});
  const [propertiesCount, setPropertiesCount] = React.useState(0);
  const [propertiesFilter, setPropertiesFilter] = React.useState({});
  const [enquiries, setEnquiries] = React.useState([]);
  const [sendEmailModalOpen, setSndEmailModalOpen] = React.useState(false);
  const [selectedProperty, setSelectedProperty] = React.useState({});
  const [addEnquiry, setAddEnquiry] = React.useState(false);
  const [enquiryId, setEnquiryId] = React.useState(null);
  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);

  const { data: industries, loading: industriesLoading } = useQuery(
    GET_INDUSTRIES,
    {
      variables: {}
    }
  );

  React.useEffect(() => {
    if (industries) {
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  const {
    data: enquiriesData,
    loading: enquiriesDataLoading,
    refetch: refetchEnquiriesData
  } = useQuery(GET_ALL_ENQUIRIES, {
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: queryFilter
    },
    onCompleted: (data) => {
      console.log("all enquiries", data);
    }
  });

  // const [changePropertyStatus, { loading: CHANGE_PROPERTY_STATUS_LOADING }] =
  //   useMutation(CHANGE_PROPERTY_STATUS, {
  //     onCompleted: (data) => {
  //       const { changePropertyStatus } = data;
  //       const { status, message } = changePropertyStatus;

  //       if (status) {
  //         refetchPropertiesData();
  //         enqueueSnackbar(message, {
  //           variant: "success"
  //         });
  //       } else {
  //         enqueueSnackbar(message, {
  //           variant: "error"
  //         });
  //       }
  //     }
  //   });

  const [deleteEnquiry, { loading: deleteEnquiryLoading }] = useMutation(
    DELETE_ENQUIRY,
    {
      onCompleted: (data) => {
        const { deleteEnquiry } = data;
        const { status, message } = deleteEnquiry;
        if (status) {
          refetchEnquiriesData();
          enqueueSnackbar(message, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  React.useEffect(() => {
    if (enquiriesData && enquiriesData?.getAllEnquiries?.enquiry) {
      setEnquiries(enquiriesData?.getAllEnquiries?.enquiry);
      setPropertiesCount(enquiriesData?.getAllEnquiries?.count);
    } else {
      setEnquiries([]);
      setPropertiesCount(0);
    }
  }, [enquiriesData]);

  // const { data: states } = useQuery(GET_ALL_STATES, { variables: {} });

  // const { data: jobCities } = useQuery(GET_CITIES, {
  //   variables: { state: values?.jobStateId },
  //   skip: !values.jobStateId,
  // });

  // const [rowCount, setRowCount] = React.useState(0);

  const [updateEnquiry, { loading: updateEnquiryLoading }] = useMutation(
    UPDATE_ENQUIRY,
    {
      onCompleted: (data) => {
        const { updateEnquiry } = data;
        const { status, message } = updateEnquiry;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refetchEnquiriesData();
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      },
      fetchPolicy: "network-only"
    }
  );

  const columns = React.useMemo(
    () => [
      {
        field: "clientName",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            CLIENT NAME
          </Typography>
        ),
        minWidth: 150,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <Box px={1}>
            <Typography fontSize="14px">
              {params.row?.companyName || ""}
            </Typography>
          </Box>
        )
      },

      // {
      //   field: "industryId",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       INDUSTRY
      //     </Typography>
      //   ),
      //   minWidth: 150,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => {
      //     console.log("industriesArr", industriesArr);
      //     console.log("industryId", params.row?.industryId);
      //     const industry: any =
      //       industriesArr?.find(
      //         (item: any) => item?.id === params.row?.industryId
      //       ) || null;
      //     console.log("industry", industry);

      //     return (
      //       <div>
      //         <Typography fontSize="14px">{industry?.name || ""}</Typography>
      //       </div>
      //     );
      //   }
      // },
      // {
      //   field: "city",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       CITY
      //     </Typography>
      //   ),
      //   minWidth: 150,
      //   flex: 0.75,
      //   headerAlign: "center",
      //   align: "left",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="14px">
      //         {params.row?.city?.name || ""}
      //       </Typography>
      //     </div>
      //   )
      // },
      {
        field: "projectCategoryId",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            CATEGORY
          </Typography>
        ),
        minWidth: 210,
        flex: 1,
        headerAlign: "left",
        align: "left",
        // renderCell: (params) => <div>{params.row?.projectCategoryId || ""}</div>
        renderCell: (params) => {
          console.log("servicesArr", servicesArr);
          console.log("projectCategoryId", params.row?.domain);
          const domains =
            params.row?.domain &&
            params.row?.domain?.length > 0 &&
            params.row?.domain?.map((item, index) => {
              if (index === 0) {
                return item?.service?.name;
              } else {
                return ", " + item?.service?.name;
              }
            });
          return (
            <div>
              <Typography fontSize="14px">{domains || ""}</Typography>
            </div>
          );
        }
      },
      {
        field: "spocName",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            NAME
          </Typography>
        ),
        minWidth: 160,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div>
            <Typography fontSize="14px">
              {params.row?.spocName || ""}
            </Typography>
          </div>
        )
      },
      // {
      //   field: "spocDesignation",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       DESIGNATION
      //     </Typography>
      //   ),
      //   minWidth: 160,
      //   flex: 0.8,
      //   headerAlign: "center",
      //   align: "left",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="14px">
      //         {params.row?.spocDesignation || ""}
      //       </Typography>
      //     </div>
      //   )
      // },
      // {
      //   field: "spocEmail",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       SPOC EMAIL
      //     </Typography>
      //   ),
      //   minWidth: 200,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="14px">
      //         {params.row?.spocEmail || ""}
      //       </Typography>
      //     </div>
      //   )
      // },
      // {
      //   field: "spocPhone",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       SPOC MOBILE
      //     </Typography>
      //   ),
      //   minWidth: 170,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="14px">{params.row?.spocPhone || ""}</Typography>
      //     </div>
      //   )
      // },
      {
        field: "enquiryStatus",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            STATUS
          </Typography>
        ),
        minWidth: 150,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          return (
            <NoBorderDropdown
              value={params?.row?.enquiryStatus || 1}
              bgColor="rgba(0, 0, 0, 0.04)"
              textColor={colors.fontLight}
              dropdownArr={bsuinessDevStatusArr}
              onChange={(e) => {
                console.log(e);
                // changePropertyStatus({
                //   variables: {
                //     payload: {
                //       propertyId: params.row?.id,
                //       status: e.target.value
                //     }
                //   }
                // });
                updateEnquiry({
                  variables: {
                    payload: {
                      id: params.row?.id,
                      enquiryStatus: e.target.value
                    }
                  }
                });
              }}
            />
          );
        }
      },
      {
        field: "createdDate",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            CREATED DATE
          </Typography>
        ),
        minWidth: 150,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div>
            <Typography fontSize="14px">
              {params.row?.createdDate
                ? getDateInFormat(params.row?.createdDate, "DD-MM-YYYY")
                : ""}
            </Typography>
          </div>
        )
      },
      {
        field: "actions",
        type: "actions",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            ACTIONS
          </Typography>
        ),
        minWidth: 120,
        flex: 1,
        align: "center",
        renderCell: (params) => {
          return [
            // <GridActionsCellItem
            //   // nonce={undefined}
            //   // onResize={undefined}
            //   // onResizeCapture={undefined}
            //   icon={
            //     <span
            //       style={{ display: "flex", alignContent: "center" }}
            //       // onClick={() => {
            //       //   setEnquiryId(params.row?.id)
            //       // }}
            //     >
            //       <VisibilityOutlinedIcon fontSize="small" />
            //     </span>
            //   }
            //   label="View"
            //   onClick={() => {
            //     setEnquiryId(params.row?.id);
            //     setAddEnquiry(true);
            //   }}
            // />,
            <GridActionsCellItem
              // nonce={undefined}
              // onResize={undefined}
              // onResizeCapture={undefined}
              icon={
                <span
                  style={{
                    display: "flex",
                    alignContent: "center",
                    marginRight: "5px",
                    paddingLeft: "2px",
                    marginTop: "-3px"
                  }}
                >
                  <img
                    src={images.editIcon}
                    alt=""
                    // onClick={() => {
                    //   setEnquiryId(params.row?.id)
                    // }}
                  />
                </span>
              }
              label="Edit"
              onClick={() => {
                setEnquiryId(params.row?.id);
                setAddEnquiry(true);
              }}
            />,
            <GridActionsCellItem
              // nonce={undefined}
              // onResize={undefined}
              // onResizeCapture={undefined}
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  // onClick={() => {
                  //   history.push(`/games/${params.row.Id}`);
                  // }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </span>
              }
              label="Delete"
              onClick={() => {
                deleteEnquiry({
                  variables: {
                    id: params.row.id
                  }
                });
              }}
            />
          ];
        }
      }
    ],
    [enquiriesData, industriesArr, servicesArr]
  );

  const handleClose = () => {
    setSndEmailModalOpen(false);
  };

  return (
    <Grid container spacing={2} mt={-4}>
      {!addEnquiry && (
        <>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography fontSize="22px" fontWeight={500}>
              Business Development
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
              alignItems="center"
            >
              <Grid item sx={{ display: "flex" }}>
                <Button
                  variant="contained"
                  sx={{ my: 2 }}
                  onClick={() => {
                    setEnquiryId(null);
                    setAddEnquiry(true);
                  }}
                >
                  Add Enquiry
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Filters
              filter={propertiesFilter}
              setFilter={setPropertiesFilter}
              setQueryFilter={setQueryFilter}
            />
          </Grid>
          <Grid item container lg={9} md={9} sm={9} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {enquiries && enquiries.length > 0 ? (
                <Table
                  rows={
                    !enquiriesDataLoading && !industriesLoading ? enquiries : []
                  }
                  columns={columns}
                  page={page}
                  pageSize={pageSize}
                  rowCount={propertiesCount || 10}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </Grid>
          </Grid>
        </>
      )}
      {addEnquiry && (
        <>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center", mt: 2 }}
          >
            <Typography fontSize="22px" fontWeight={500}>
              Add Enquiry
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
            <AddEditEnquiry
              enquiryId={enquiryId}
              setAddEnquiry={setAddEnquiry}
              refetchEnquiriesData={refetchEnquiriesData}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export function BusinessDevelopment(props: any) {
  return <NavBar mainComponent={<BusinessDevelopmentContent />} />;
}
