/**
 * Asynchronously loads the component for AdminProfile
 */

import { lazyLoad } from "utils/loadable";

export const AdminProfile = lazyLoad(
  () => import("./index"),
  (module) => module.AdminProfile
);
