import * as React from "react";
import { Typography } from "@mui/material";

const CommonFooter = (props) => {
  return (
    <Typography
      fontSize="14px"
      sx={{ py: 1.5, backgroundColor: "#F4F7FC", mt: 5 }}
      textAlign="center"
    >
      {/* © 2022 Campus Tech. All rights reserved */}
    </Typography>
  );
};

export default CommonFooter;
