import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation updateUser($payload: UserUpdateInput!, $partner_agreement: Upload) {
    updateUser(payload: $payload, partner_agreement: $partner_agreement) {
      status
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($payload: ChangePasswordInput!) {
    changePassword(payload: $payload) {
      status
      message
    }
  }
`;

// for ezyconseil latest update ----------------------------------------------------------------------------------------------

export const GET_PARTNERSHIP_DETAILS = gql`
  query getPartnershipDetail {
    getPartnershipDetail {
      id
      user {
        id
        firstName
        lastName
        username
        email
        mobile
        address
        country {
          id
          name
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
        expextedHourlyRate
      }
      entityName
      startDate
      endDate
      partenerShipAgreement
      renewalDate
    }
  }
`;

export const UPDATE_PARTNERSHIP_DETAILS = gql`
  mutation updatePartnershipDetails($payload: PartnershipCreateInput!) {
    createPartnership(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_PROFESSIONAL_DETAILS = gql`
  query getProfessionalDetails($user_id: String) {
    getProfessionalDetails(user_id: $user_id) {
      count
      details {
        id
        name
        profilePhoto
        profile
        years
        education
        workExperience
        projects
        certification {
          id
          name
          issuedBy
          issuedOn
        }
        domain {
          id
          service {
            id
            name
          }
        }
        linkdinProfile
        personalBlog
        youtubeChannel
      }
    }
  }
`;

export const GET_PROFESSIONAL_DETAIL = gql`
  query getProfessionalDetail {
    getProfessionalDetail {
      id
      name
      profilePhoto
      profile
      yearsOfExperience
      # education
      # workExperience
      # projectExperience {
      #   name
      #   clientName
      #   cost
      #   currencyType {
      #     id
      #     name
      #   }
      #   startDate
      #   endDate
      #   description
      #   status
      #   externalProject
      #   projectReviews {
      #     review
      #   }
      # }
      # certification
      domainExpertiseId
      linkedinProfile
      personalBlog
      youtubeChannel
    }
  }
`;

export const CREATE_CONSULTANT = gql`
  mutation createConsultants(
    $payload: CreateProfessionalDetailsInput!
    $profile_picture: Upload
  ) {
    createConsultants(payload: $payload, profile_picture: $profile_picture) {
      status
      message
    }
  }
`;

export const UPDATE_CONSULTANT = gql`
  mutation updateConsultant(
    $payload: UpdateProfessionalDetailsInput!
    $profile_picture: Upload
  ) {
    updateConsultant(payload: $payload, profile_picture: $profile_picture) {
      status
      message
    }
  }
`;

export const DELETE_CONSULTANT = gql`
  mutation deleteConsultant($id: String!) {
    deleteConsultant(id: $id) {
      status
      message
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUserDetails($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      mobile
      email
      address
      city {
        id
        name
      }
      state {
        id
        name
      }
      country {
        id
        name
      }
      availableHoursPerWeek
    }
  }
`;

export const GET_USER_PARTNERSHIP_DETAILS = gql`
  query getUserPartnershipDetails($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      mobile
      email
      address
      category
      expectedRates
      city {
        id
        name
      }
      state {
        id
        name
      }
      country {
        id
        name
      }
      partnershipDetails {
        id
        entityName
        startDate
        endDate
        partenerShipAgreement
        renewalDate
      }
    }
  }
`;

// -------------------------------------------------------------------------------------------------------------------------

export const GET_STUDENT_DETAILS = gql`
  query getStudents($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      mobile
      email
      address
      city {
        id
        name
      }
      state {
        id
        name
      }
      country {
        id
        name
      }
      dob
      gender
      roles
      qualification {
        id
        qualification {
          id
          name
        }
        course {
          id
          name
        }
        graduationYear
        specialization
        collegePercentage
        enrolmentNumber
        transcripts
        current
        college
      }
      language {
        language
        read
        write
        speak
      }
      certifications
      skills
      profile
      workPreference {
        id
        functionalArea {
          id
          name
        }
        industry {
          id
          name
        }
        jobType1
        jobType2
        jobLocation
        jobState {
          id
          name
        }
        jobCity {
          id
          name
        }
        minimumExpectation
        maximumExpectation
        internshipFunctionalArea {
          id
          name
        }
        internshipIndustry {
          id
          name
        }
        internshipJobType1
        internshipJobType2
        internshipJobLocation
        internshipState {
          id
          name
        }
        internshipCity {
          id
          name
        }
        internshipMinimumExpectation
        internshipMaximumExpectation
      }
      workExperience {
        id
        current
        designation
        company
        functionalArea {
          id
          name
        }
        industry {
          id
          name
        }
        startDate
        endDate
        description
      }
      internshipExperience {
        id
        company
        functionalArea {
          id
          name
        }
        industry {
          id
          name
        }
        startDate
        endDate
        designation
        description
      }
      coCurricular
      location
      facultyRecommendation
      videoName
      college {
        name
      }
    }
  }
`;

export const GET_PARTNER_AGREEMENT = gql`
  query getPartnerAgreement($user_id: String) {
    getPartnerAgreement(user_id: $user_id) {
      documentType
      documentName
      document
    }
  }
`;

export const GET_PROFILE_PHOTO = gql`
  query getProfilePicture {
    getProfilePicture {
      documentType
      documentName
      document
    }
  }
`;

export const GET_CONSULTANT_PROFILE_PHOTO = gql`
  query getConsultantPicture($id: String!) {
    getConsultantPicture(id: $id) {
      documentType
      documentName
      document
    }
  }
`;

export const UPLOAD_PROFILE_PHOTO = gql`
  mutation uploadProfilePicture($file: Upload!) {
    uploadProfilePicture(profile_picture: $file) {
      status
      message
    }
  }
`;

export const GET_RESUME = gql`
  query getResume($userId: String!) {
    getResume(user_id: $userId) {
      document
      documentType
      documentName
    }
  }
`;

export const GET_VIDEO_INTRODUCTION = gql`
  query getVideoIntroduction($userId: String!) {
    getVideoIntroduction(userId: $userId) {
      file
      fileType
      fileName
    }
  }
`;

export const UPLOAD_RESUME = gql`
  mutation updateResume($file: Upload) {
    updateResume(resume: $file) {
      status
      message
    }
  }
`;

export const UPLOAD_VIDEO_INTRODUCTION = gql`
  mutation updateVideo($payload: String!, $file: Upload) {
    uploadVideoIntroduction(payload: $payload, file: $file) {
      status
      error
    }
  }
`;
