import * as React from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  Box
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ProfilePhoto from "./ProfilePhoto";
import {
  downloadPDF,
  getDateInFormat,
  getUserInfo,
  viewDocumentInNewTab
} from "../../../utils/helpers";
import {
  gendersArr,
  getMIMETypeOfFile,
  partnerCategoryOptions
} from "../../../utils/constants";
import { colors } from "../../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COUNTRIES, GET_STATES, GET_CITIES } from "app/graphql/meta";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import dayjs from "dayjs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  GET_PARTNERSHIP_DETAILS,
  GET_PARTNER_AGREEMENT,
  GET_USER_PARTNERSHIP_DETAILS,
  UPDATE_PARTNERSHIP_DETAILS,
  UPDATE_PROFILE
} from "app/graphql/Profile";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_USER_MUTATION } from "app/graphql/users";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { FilePicker } from "react-file-picker";
import images from "assets/images/images";

const PartnershipDetails = (props) => {
  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    resetForm,
    editClicked,
    setEditClicked,
    partnerAgreement
  } = props;

  const history: any = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [dob, setDob] = React.useState<Date | null>(null);
  // const [partnerAgreement, setPartnerAgreement] = React.useState(null);

  const { data: countries } = useQuery(GET_COUNTRIES, {
    variables: {}
  });

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" }
  });

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: values.stateId },
    skip: !values.stateId
  });

  // const { data: partnerAgreementData } = useQuery(GET_PARTNER_AGREEMENT, {
  //   variables: { user_id: values.id },
  //   onCompleted: (data) => {
  //     console.log(data);
  //     setPartnerAgreement(data?.getPartnerAgreement);
  //     // const { status, error } = data;
  //     // if (status) {
  //     //   setPartnerAgreement(data?.getPartnerAgreement);
  //     // } else {
  //     //   enqueueSnackbar(error, {
  //     //     variant: "error"
  //     //   });
  //     // }
  //   }
  // });

  const handleDOBChange = (newValue: Date | null) => {
    setDob(newValue);
    setFieldValue("dob", getDateInFormat(newValue, "YYYY-MM-DD"));
  };

  React.useEffect(() => {
    if (values && values.dob) {
      const dateParts = values.dob.split("-");
      const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      setDob(date);
    }
  }, [values]);

  const hourlyRateArr = [
    {
      id: "1",
      name: "20 hrs/week"
    },
    {
      id: "2",
      name: "30 hrs/week"
    },
    {
      id: "3",
      name: "40 hrs/week"
    }
  ];

  const handleFileSelect = (key, file) => {
    console.log(file);
    setFieldValue("partnerAgreement", file);
  };

  console.log("partner Agreement", partnerAgreement);
  return (
    <Paper sx={{ borderRadius: 0, p: 3, minHeight: "80vh" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography fontSize="20px">Partnership Details</Typography>
        </Grid>
        {/* {!editClicked && (
          <Grid item>
            <Button
              size="small"
              variant="contained"
              onClick={() => setEditClicked(true)}
            >
              Edit
            </Button>
          </Grid>
        )} */}
      </Grid>
      <Grid container spacing={2.5} mt={0.1}>
        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Entity Name
            {/* <span style={{ color: colors.danger }}>*</span> */}
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <TextField
            id="entityName"
            value={values.entityName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            multiline
          />
          {touched.entityName && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.entityName}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={3.5} md={3.5} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Partner First Name
          </Typography>
        </Grid>
        <Grid
          item
          lg={3.5}
          md={3.5}
          sm={12}
          xs={12}
          sx={{ mb: -2, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="14px" color={colors.fontLight}>
            Partner Last Name
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
          <TextField
            id="partnerFirstName"
            value={values.partnerFirstName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
          {touched.partnerFirstName && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.partnerFirstName}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={3.5}
          md={3.5}
          sm={12}
          xs={12}
          sx={{ mb: -2, display: { xs: "block", md: "none" } }}
        >
          <Typography fontSize="14px" color={colors.fontLight}>
            Last Name
          </Typography>
        </Grid>
        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
          <TextField
            id="partnerLastName"
            value={values.partnerLastName}
            onChange={handleChange}
            size="small"
            fullWidth
          />
          {touched.partnerLastName && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.partnerLastName}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Phone No.
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <TextField
            id="mobile"
            value={values.mobile}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            type="number"
            fullWidth
            disabled={values.id !== "add-new-partner" ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    fontSize="14px"
                    color={colors.fontLight}
                    sx={{
                      borderRight: `2px solid ${colors.fontLight}50`,
                      pr: 1
                    }}
                  >
                    +91
                  </Typography>
                </InputAdornment>
              )
            }}
          />
          {touched.mobile && errors.mobile && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.mobile}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Email
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <TextField
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            disabled={values.id !== "add-new-partner" ? true : false}
          />
          {touched.email && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.email}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Address<span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <TextField
            id="address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            multiline
          />
          {touched.address && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.address}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        {/* <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Country
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Autocomplete
            id="countryId"
            autoComplete
            value={
              countries?.countries
                ? countries?.countries.find((x) => x.id === values.countryId)
                : null
            }
            onChange={(_, value: any) => {
              if (value) {
                setFieldValue("countryId", value.id);
              } else {
                setFieldValue("countryId", "");
              }
              setFieldValue("stateId", "");
              setFieldValue("cityId", "");
            }}
            options={countries?.countries || []}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
                        renderInput={(params) => <TextField {...params} size="small" />}
          />
          {touched.countryId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.countryId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} /> */}
        <Grid item lg={3.5} md={3.5} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            State<span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid
          item
          lg={3.5}
          md={3.5}
          sm={12}
          xs={12}
          sx={{ mb: -2, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="14px" color={colors.fontLight}>
            City<span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
          <Autocomplete
            id="stateId"
            autoComplete
            value={
              states?.states
                ? states?.states.find((x) => x.id === values.stateId)
                : null
            }
            onChange={(_, value: any) => {
              if (value) {
                setFieldValue("stateId", value.id);
              } else {
                setFieldValue("stateId", "");
              }
              setFieldValue("cityId", "");
            }}
            options={states?.states || []}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          {errors.stateId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.stateId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
          <Autocomplete
            id="cityId"
            autoComplete
            value={
              cities?.cities
                ? cities?.cities.find((x) => x.id === values.cityId)
                : null
            }
            onChange={(_, value: any) => {
              if (value) {
                setFieldValue("cityId", value.id);
              } else {
                setFieldValue("cityId", "");
              }
            }}
            options={cities?.cities || []}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          {errors.cityId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.cityId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item container lg={12} md={12}>
          <Grid
            item
            lg={2.5}
            md={2.5}
            sm={6}
            xs={10}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              fontSize="14px"
              fontWeight={600}
              color={colors.fontLight}
            >
              Partner Agreement :
            </Typography>
          </Grid>
          <Grid
            item
            lg={4.5}
            md={4.5}
            sm={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center", my: 2 }}
          >
            {values.partnerAgreement && values.partnerAgreement?.name ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize={14} fontWeight={400}>
                  {values.partnerAgreement?.name || ""}
                </Typography>
                <DeleteOutlineIcon
                  fontSize="small"
                  sx={{ ml: 1, cursor: "pointer" }}
                  onClick={() => setFieldValue("partnerAgreement", "")}
                />
              </Box>
            ) : partnerAgreement &&
              partnerAgreement?.document &&
              partnerAgreement?.documentType ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    viewDocumentInNewTab(
                      partnerAgreement?.document,
                      "Partner Agreement",
                      getMIMETypeOfFile(partnerAgreement?.documentType)
                    )
                  }
                >
                  <VisibilityIcon fontSize="small" />
                </Box>
                <Box
                  sx={{ pl: 2, cursor: "pointer" }}
                  onClick={() =>
                    downloadPDF(
                      partnerAgreement?.document,
                      partnerAgreement?.documentName,
                      getMIMETypeOfFile(partnerAgreement?.documentType)
                    )
                  }
                >
                  <FileDownloadOutlinedIcon fontSize="small" />
                </Box>
                <Box sx={{ pl: 2, cursor: "pointer" }}>
                  <FilePicker
                    extensions={["pdf"]}
                    onChange={(file) =>
                      handleFileSelect("partnerAgreement", file)
                    }
                    onError={(errMsg) => alert(errMsg)}
                  >
                    <span>
                      <img src={images.editIcon} alt="" />
                    </span>
                  </FilePicker>
                </Box>
              </Box>
            ) : (
              <FilePicker
                extensions={["pdf"]}
                onChange={(file) => handleFileSelect("partnerAgreement", file)}
                onError={(errMsg) => alert(errMsg)}
              >
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<FileUploadOutlinedIcon fontSize="small" />}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: colors.primaryBlue,
                    fontSize: "13px"
                  }}
                >
                  Upload
                </Button>
              </FilePicker>
            )}
          </Grid>
          <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        </Grid>
        <Grid item lg={3.5} md={3.5} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Partnership Start Date
            <span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid
          item
          lg={3.5}
          md={3.5}
          sm={12}
          xs={12}
          sx={{ mb: -2, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="14px" color={colors.fontLight}>
            Partnership End Date<span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              // disablePast={true}
              InputProps={{
                style: { fontSize: 14 }
              }}
              closeOnSelect={true}
              components={{
                OpenPickerIcon: CalendarTodayOutlinedIcon
              }}
              value={values.partnershipStartDate || null}
              onChange={(value) => {
                setFieldValue("partnershipStartDate", value, true);
              }}
              renderInput={(params) => (
                <TextField
                  id="partnershipStartDate"
                  size="small"
                  {...params}
                  fullWidth
                  onBlur={handleBlur}
                />
              )}
            />
          </LocalizationProvider>
          {errors.partnershipStartDate && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.partnershipStartDate}
            </Typography>
          )}
        </Grid>
        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              disablePast={true}
              InputProps={{
                style: { fontSize: 14 }
              }}
              closeOnSelect={true}
              components={{
                OpenPickerIcon: CalendarTodayOutlinedIcon
              }}
              value={values.partnershipEndDate || null}
              onChange={(value) => {
                // setFieldValue("partnershipEndDate", value, true);
                // setFieldValue("renewalDate", value, true);
                if (value) {
                  setFieldValue("partnershipEndDate", value);

                  const nextDay = new Date(value);
                  nextDay.setDate(nextDay.getDate() + 1);

                  setFieldValue("renewalDate", nextDay);
                } else {
                  setFieldValue("partnershipEndDate", null);
                  setFieldValue("renewalDate", null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  id="partnershipEndDate"
                  size="small"
                  {...params}
                  fullWidth
                  onBlur={handleBlur}
                />
              )}
            />
          </LocalizationProvider>
          {errors.partnershipEndDate && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.partnershipEndDate}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Renewal Date
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={true}
              inputFormat="DD/MM/YYYY"
              InputProps={{
                style: { fontSize: 14 }
              }}
              closeOnSelect={false}
              components={{
                OpenPickerIcon: CalendarTodayOutlinedIcon
              }}
              value={values.renewalDate}
              onChange={() => null}
              renderInput={(params) => (
                <TextField
                  id="renewalDate"
                  size="small"
                  {...params}
                  fullWidth
                  onBlur={handleBlur}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid
          item
          lg={7}
          md={7}
          sm={12}
          xs={12}
          sx={{ mb: -2, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="14px" color={colors.fontLight}>
            Partner Category<span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Autocomplete
            id="partnerCategory"
            autoComplete
            value={
              values.partnerCategory
                ? partnerCategoryOptions.find(
                    (x) => x.id === values.partnerCategory
                  )
                : null
            }
            onChange={(_, value: any) => {
              setFieldValue("partnerCategory", value ? value.id : null);
            }}
            onInputChange={(_, newInputValue) => {
              if (!newInputValue) {
                setFieldValue("partnerCategory", null);
              }
            }}
            options={partnerCategoryOptions || []}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          {errors.partnerCategory && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.partnerCategory}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid
          item
          lg={7}
          md={7}
          sm={12}
          xs={12}
          sx={{ mb: -2, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="14px" color={colors.fontLight}>
            Expected Working Rate<span style={{ color: colors.danger }}>*</span>
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <TextField
            id="expectedRates"
            value={values.expectedRates}
            placeholder="Enter working rate"
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            multiline
          />
          {errors.expectedRates && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.expectedRates}
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
        {/* {!editClicked && (
          <Grid
            item
            container
            lg={12}
            md={12}
            display={{ xs: "none", md: "block" }}
          >
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{ pb: 0.5 }}>
              <Typography fontSize="14px" color={colors.fontLight}>
                Renewal Date
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={true}
                  inputFormat="DD/MM/YYYY"
                  InputProps={{
                    style: { fontSize: 14 }
                  }}
                  closeOnSelect={false}
                  components={{
                    OpenPickerIcon: CalendarTodayOutlinedIcon
                  }}
                  value={values.renewalDate}
                  onChange={() => null}
                  renderInput={(params) => (
                    <TextField
                      id="renewalDate"
                      size="small"
                      {...params}
                      fullWidth
                      onBlur={handleBlur}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={5} md={5} display={{ xs: "none", md: "block" }} />
          </Grid>
        )} */}
        {/* {editClicked && (
          <> */}
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 2 }}>
          <div
            style={{
              height: 1,
              backgroundColor: "#E9E9E9",
              width: "100%"
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                sx={{ border: "1px solid #55555550", color: "#555555" }}
                disableElevation
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* </>
        )} */}
      </Grid>
    </Paper>
  );
};

const PartnershipDetailsFormik = (props) => {
  const { setPartnerId, setSelectedDivision } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [partnershipDetails, setPartnershipDetails] = React.useState<any>({});
  const [partnerAgreement, setPartnerAgreement] = React.useState(null);
  const [editClicked, setEditClicked] = React.useState(false);

  const { partnerId: id }: any = useParams();
  const {
    data: partnershipData,
    loading: partnershipDataLoading,
    refetch: refecthPartnershipData
  } = useQuery(GET_USER_PARTNERSHIP_DETAILS, {
    variables: {
      id: id,
      skip: id === "add-new-partner"
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (partnershipData && partnershipData?.user) {
      console.log("partnershipData", partnershipData);
      setPartnershipDetails(partnershipData?.user);
    }
  }, [partnershipData]);

  const { data: partnerAgreementData, refetch: refetchPartnerAgreement } =
    useQuery(GET_PARTNER_AGREEMENT, {
      variables: { user_id: id },
      onCompleted: (data) => {
        console.log(data);
        setPartnerAgreement(data?.getPartnerAgreement);
        // const { status, error } = data;
        // if (status) {
        //   setPartnerAgreement(data?.getPartnerAgreement);
        // } else {
        //   enqueueSnackbar(error, {
        //     variant: "error"
        //   });
        // }
      }
    });

  const [updatePartnershipDetails, { loading: updateTournamentLoading }] =
    useMutation(UPDATE_PROFILE, {
      onCompleted: (data) => {
        console.log(data);
        const { updateUser } = data;
        const { status, message, user } = updateUser;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          refecthPartnershipData();
          refetchPartnerAgreement();
          // history.push("/partner-registration");
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    });

  const [createPartner, { loading: updating }] = useMutation(
    CREATE_USER_MUTATION,
    {
      onCompleted: (data) => {
        const { createUser } = data;
        const { status, message, user } = createUser;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          setPartnerId(user?.id);
          setSelectedDivision(2);
          // history.push("/partner-registration");
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    }
  );

  const PersonalFormWrapped = withFormik({
    mapPropsToValues: () => ({
      id: id || null,
      entityName: partnershipDetails?.partnershipDetails?.entityName || "",
      partnerFirstName: partnershipDetails?.firstName || "",
      partnerLastName: partnershipDetails?.lastName || "",
      mobile: partnershipDetails?.mobile || "",
      email: partnershipDetails?.email || "",
      address: partnershipDetails?.address || "",
      stateId: partnershipDetails?.state?.id || "",
      cityId: partnershipDetails?.city?.id || "",
      partnerCategory: partnershipDetails?.category || "",
      // countryId: userData?.country?.id || ""
      partnerAgreement: "",
      partnershipStartDate:
        partnershipDetails?.partnershipDetails?.startDate || null,
      partnershipEndDate:
        partnershipDetails?.partnershipDetails?.endDate || null,
      renewalDate: partnershipDetails?.partnershipDetails?.renewalDate || null,
      expectedRates: partnershipDetails?.expectedRates || ""
    }),

    validationSchema: Yup.object().shape({
      // entityName: Yup.string().required("This is a required field"),
      partnerFirstName: Yup.string().required("This is a required field"),
      // partnerLastName: Yup.string().required("Please enter your last name"),
      mobile: Yup.string()
        .required("This is a required field")
        .matches(/^[0-9]{10}$/, "Please enter a valid 10 digit mobile number"),
      email: Yup.string()
        .required("This is a required field")
        .email("Enter a valid email id"),
      address: Yup.string().required("Please enter your address"),
      // countryId: Yup.string().required("Please select a country"),
      stateId: Yup.string().required("Please select a state"),
      cityId: Yup.string().required("Please select a city"),
      partnershipStartDate: Yup.date()
        // .min(
        //   dayjs(new Date(), { utc: true }).format(),
        //   "Please enter a valid date"
        // )
        .typeError("Please enter a valid date")
        .required("This is a required field")
        .nullable(),
      partnershipEndDate: Yup.date()
        .min(Yup.ref("partnershipStartDate"), "End date cannot be before Start")
        .typeError("Please enter a valid date")
        .required("This is a required field")
        .nullable(),
      partnerCategory: Yup.string()
        .required("This is a required field")
        .nullable(),
      expectedRates: Yup.string().required("This is a required field")
    }),

    enableReinitialize: true,

    handleSubmit(values, { props }) {
      console.log(values);

      const startDate = values?.partnershipStartDate
        ? dayjs(values?.partnershipStartDate).format("YYYY-MM-DD")
        : null;
      const endDate = values?.partnershipEndDate
        ? dayjs(values?.partnershipEndDate).format("YYYY-MM-DD")
        : null;

      if (values?.id !== "add-new-partner") {
        const payload = {
          id: values?.id,
          firstName: values?.partnerFirstName,
          lastName: values?.partnerLastName,
          // mobile: values?.mobile,
          // email: values?.email,
          address: values?.address,
          stateId: values?.stateId,
          cityId: values?.cityId,
          category: values?.partnerCategory,
          expectedRates: values?.expectedRates,
          partnership: {
            entityName: values?.entityName,
            startDate: startDate,
            endDate: endDate
          }
        };
        updatePartnershipDetails({
          variables: {
            payload: payload,
            partner_agreement: values.partnerAgreement
          }
        });
      } else {
        const payload = {
          firstName: values?.partnerFirstName,
          lastName: values?.partnerLastName,
          mobile: values?.mobile?.toString(),
          email: values?.email,
          address: values?.address,
          stateId: values?.stateId,
          cityId: values?.cityId,
          category: values?.partnerCategory,
          expectedRates: values?.expectedRates,
          roles: ["freelancer"],
          partnership: {
            entityName: values?.entityName,
            startDate: startDate,
            endDate: endDate
          }
        };
        createPartner({
          variables: {
            payload: payload,
            partner_agreement: values.partnerAgreement
          }
        });
      }
    }
  })(PartnershipDetails);

  return (
    <PersonalFormWrapped
      {...props}
      editClicked={editClicked}
      setEditClicked={setEditClicked}
      partnerAgreement={partnerAgreement}
    />
  );
};

export default PartnershipDetailsFormik;
