import * as React from "react";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { Box, Paper, Avatar, Typography } from "@mui/material";
import {
  Person,
  AddAPhotoOutlined,
  ModeEditOutlined
} from "@mui/icons-material";
import { acceptImageTypes, getMIMETypeOfFile } from "../../../utils/constants";
import { colors } from "../../../styles/colors";

import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  GET_CONSULTANT_PROFILE_PHOTO,
  GET_PROFILE_PHOTO,
  UPLOAD_PROFILE_PHOTO
} from "../../graphql/Profile";
import getStore from "../../../store/zustandStore";
import images from "assets/images/images";

interface IProfilePhotoProps {
  editClicked: boolean;
  values: any;
  setFieldValue: any;
  disabled?: Boolean;
  consultantId?: string;
}

const ProfilePhoto = (props: IProfilePhotoProps) => {
  const { editClicked, values, setFieldValue, disabled, consultantId } = props;
  const setLoading = getStore((state: any) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [profilePhoto, setProfilePhoto] = React.useState<any>(null);

  const {
    data: uploadedProfilePhoto,
    loading: gettingPhoto,
    refetch: getPhoto
  } = useQuery(GET_CONSULTANT_PROFILE_PHOTO, {
    variables: {
      id: consultantId
    },
    skip: !consultantId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [uploadPhoto, { loading: uploadingPhoto }] = useMutation(
    UPLOAD_PROFILE_PHOTO,
    {
      onCompleted: (data) => {
        const { uploadProfilePicture } = data;
        const { status, error } = uploadProfilePicture;
        if (status) {
          enqueueSnackbar(error, { variant: "success" });
          getPhoto();
        } else {
          enqueueSnackbar(error, { variant: "error" });
        }
      }
    }
  );

  const handleUploadPhoto = (file: RcFile): Promise<void> => {
    // uploadPhoto({
    //   variables: {
    //     file: file
    //   }
    // });
    console.log(file);
    setFieldValue("profilePhoto", file);
    return Promise.resolve();
  };

  React.useEffect(() => {
    if (uploadedProfilePhoto && uploadedProfilePhoto.getConsultantPicture) {
      setProfilePhoto(uploadedProfilePhoto.getConsultantPicture);
    } else {
      setProfilePhoto(null);
    }
  }, [uploadedProfilePhoto]);

  // React.useEffect(() => {
  //   setLoading(gettingPhoto || uploadingPhoto);
  // }, [gettingPhoto, setLoading, uploadingPhoto]);tudent-profile

  console.log("profilePhoto", values?.profilePhoto);

  return (
    <>
      {values?.profilePhoto !== null || profilePhoto ? (
        <Paper
          elevation={3}
          sx={{
            my: 2,
            height: 150,
            width: 150,
            borderRadius: 75,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 1
          }}
        >
          <Avatar
            sx={{ height: 140, width: 140, position: "absolute", zIndex: 2 }}
          >
            {values?.profilePhoto ? (
              <img
                src={
                  values?.profilePhoto?.name
                    ? URL.createObjectURL(values?.profilePhoto)
                    : values?.profilePhoto
                    ? values?.profilePhoto
                    : ""
                }
                alt="profile pic"
                height={140}
                width={140}
              />
            ) : profilePhoto && profilePhoto?.document ? (
              <img
                src={`data:image/${getMIMETypeOfFile(
                  profilePhoto?.documentType
                )};base64,${profilePhoto?.document}`}
                // alt="profile pic"
                height={140}
                width={140}
              />
            ) : (
              <Typography fontWeight={400} fontSize={14}>
                Add Photo
              </Typography>
            )}
          </Avatar>
        </Paper>
      ) : (
        <Box
          sx={{
            my: 2,
            height: 150,
            width: 150,
            borderRadius: 75,
            backgroundColor: "#F7F7F7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 1
          }}
        >
          <Person
            sx={{
              color: colors.fontLight,
              fontSize: 60,
              position: "absolute",
              zIndex: 2
            }}
          />
          {editClicked && (
            <Upload
              openFileDialogOnClick
              beforeUpload={handleUploadPhoto}
              accept={acceptImageTypes}
            >
              <Paper
                component="div"
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  zIndex: 2,
                  top: 50,
                  left: 50,
                  cursor: "pointer"
                }}
              >
                <AddAPhotoOutlined color="primary" fontSize="small" />
              </Paper>
            </Upload>
          )}
        </Box>
      )}
    </>
  );
};

export default ProfilePhoto;
