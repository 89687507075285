import * as React from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete
} from "@mui/material";
import ViewProfessionalDetails from "./ViewProfessionalDetails";
import MultipleAddComponent from "../../components/MultipleAddComponent";
import { colors } from "../../../styles/colors";
import ProfilePhoto from "./ProfilePhoto";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";
import { GET_CURRENCY_TYPE } from "app/graphql/meta";
import { AddOutlined } from "@mui/icons-material";
import AddProfessionalDetails from "./AddProfessionalDetails";
import { GET_PROFESSIONAL_DETAILS } from "app/graphql/Profile";
import { useParams } from "react-router-dom";

const ProfessionalDetails = (props) => {
  const { partnerId: partnerUserId } = props;

  const { partnerId }: any = useParams();
  const [editClicked, setEditClicked] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [consultantsArr, setConsultantsArr] = React.useState([]);
  const [selectedConsultant, setSelectedConsultant] = React.useState({});

  const {
    data: professionalDetails,
    loading: professionalDetailsLoading,
    refetch: refetchProfessionalDetails
  } = useQuery(GET_PROFESSIONAL_DETAILS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      user_id: partnerUserId || partnerId
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      professionalDetails &&
      professionalDetails?.getProfessionalDetails &&
      professionalDetails?.getProfessionalDetails?.details
    ) {
      setConsultantsArr(professionalDetails.getProfessionalDetails.details);
    } else {
      setConsultantsArr([]);
    }
  }, [professionalDetails]);
  return (
    <Paper sx={{ borderRadius: 0, p: 3 }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography fontSize="20px">Professional Details</Typography>
        </Grid>
        {!editClicked && (
          <Grid item>
            <Button
              size="small"
              // variant="contained"
              startIcon={<AddOutlined color="primary" fontSize="small" />}
              onClick={() => {
                setSelectedConsultant({});
                setEditClicked(true);
              }}
            >
              Add Consultant
            </Button>
          </Grid>
        )}
      </Grid>
      {!editClicked && (
        <Grid container spacing={2.5}>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ViewProfessionalDetails
                editClicked={editClicked}
                setEditClicked={setEditClicked}
                consultantsArr={consultantsArr}
                setSelectedConsultant={setSelectedConsultant}
                refetchProfessionalDetails={refetchProfessionalDetails}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {editClicked && (
        <Grid container spacing={2.5}>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AddProfessionalDetails
                partnerUserId={partnerUserId || partnerId}
                editClicked={editClicked}
                setEditClicked={setEditClicked}
                selectedConsultant={selectedConsultant}
                setSelectedConsultant={setSelectedConsultant}
                refetchProfessionalDetails={refetchProfessionalDetails}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ProfessionalDetails;
