import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { colors } from "../../../styles/colors";
import {
  GET_JOB_CATEGORIES,
  GET_INDUSTRIES,
  GET_SERVICES,
  GET_STATES,
  GET_CITIES,
  GET_ORGANIZATION_DROPDOWN_OPTIONS,
  GET_FILTERED_CITIES,
} from "app/graphql/meta";
import { GET_ALL_LOCATIONS } from "app/graphql/users";
import { useQuery } from "@apollo/client";
import {
  bsuinessDevStatusArr,
  jobModesArr,
  partnerCategory,
} from "../../../utils/constants";
import RadioGroups from "app/components/RadioGroup";

interface FiltersProps {
  setFilter: any;
  filter: any;
  setQueryFilter: any;
}

const Filters = (props: FiltersProps) => {
  const { filter, setFilter } = props;

  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);
  const [statesArr, setStatesArr] = React.useState([]);
  const [citiesArr, setCitiesArr] = React.useState([]);
  const [organizationArr, setOrganizationArr] = React.useState([]);

  const { data: organizations } = useQuery(GET_ORGANIZATION_DROPDOWN_OPTIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {},
  });

  React.useEffect(() => {
    if (industries) {
      console.log(industries);
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {},
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  React.useEffect(() => {
    if (organizations) {
      console.log(organizations);
      setOrganizationArr(organizations?.organizationDropDown || []);
    }
  }, [organizations]);

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" },
  });

  React.useEffect(() => {
    if (states) {
      console.log(states);
      setStatesArr(states?.states || []);
    }
  }, [states]);

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: filter?.stateId },
    skip: !filter?.stateId,
  });

  React.useEffect(() => {
    if (cities) {
      console.log(cities);
      setCitiesArr(cities?.cities || []);
    }
  }, [cities]);

  const setFilterVariables = (key, val) => {
    setFilter({ ...filter, [key]: val });
  };

  const [errors, setErrors] = React.useState<any>({});

  const validate = () => {
    let newErrors = {};
    let dirty = false;

    if (filter?.minimumRemuneration__gte) {
      if (filter?.minimumRemuneration__gte < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Min Remuneration cannot be less than 0",
        };

        dirty = true;
      }
    }

    if (filter?.maximumRemuneration__lte) {
      if (filter?.maximumRemuneration__lte < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Max Remuneration cannot be less than 0",
        };
        dirty = true;
      }
    }

    if (
      filter?.minimumRemuneration__gte &&
      filter?.maximumRemuneration__lte &&
      filter?.maximumRemuneration__lte < filter?.minimumRemuneration__gte
    ) {
      newErrors = {
        ...newErrors,
        remuneration: "Min Remuneration cannot be more than Max Remuneration",
      };
      dirty = true;
    }

    if (dirty) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }

    return dirty;
  };

  const { data: locations, loading: loadingLocations } = useQuery(
    GET_ALL_LOCATIONS,
    {
      variables: {},
    }
  );

  const hourlyRateArr = [
    {
      id: "1",
      name: "20 hrs/week",
    },
    {
      id: "2",
      name: "30 hrs/week",
    },
    {
      id: "3",
      name: "40 hrs/week",
    },
  ];

  const [cityIdFilter, setCityIdFilter] = React.useState(filter?.cityId || "");

  const [filteredCityIdOptions, setFilteredCityIdOptions] = React.useState([]);

  const { data: filteredCities, loading: filteredCitiesLoading } = useQuery(
    GET_FILTERED_CITIES,
    {
      variables: {
        filter: {
          name__icontains: cityIdFilter,
        },
      },
      // skip: !cityIdFilter,
      onCompleted: (data) => {
        const options =
          data?.getAllCities && data?.getAllCities?.city
            ? data?.getAllCities?.city
            : [];
        setFilteredCityIdOptions(options);
      },
    }
  );

  const [timer, setTimer] = React.useState<any>(null);

  const handleCityIdInputChange = (value) => {
    console.log(value);
    // setFieldValue("cityId", "");
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setCityIdFilter(value);
      }, 700)
    );
  };

  console.log("filteredCityIdOptions", filteredCityIdOptions);

  return (
    <Box sx={{ borderRadius: "4px", border: "1px solid #E3E3E3" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Grid item>
          <Typography fontSize="16px" color={colors.fontLight}>
            FILTERS
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontSize="12px"
            color={colors.primary}
            sx={{ textDecoration: "underline" }}
            onClick={() => {
              setFilter({});
              if (!validate()) {
                props.setQueryFilter({});
              }
            }}
          >
            Clear all filters
          </Typography>

          <Button
            variant="contained"
            sx={{ width: "20%" }}
            onClick={() => {
              if (!validate()) {
                props.setQueryFilter({ ...filter });
              }
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>

      <div
        style={{
          height: 1,
          backgroundColor: "#E9E9E9",
          width: "100%",
        }}
      />

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Name
          </Typography>
          <TextField
            id="firstName__icontains"
            size="small"
            fullWidth
            placeholder="Enter partner name"
            value={
              filter?.firstName__icontains ? filter?.firstName__icontains : ""
              // (filter?.lastName__icontains ? filter?.lastName__icontains : "") ||
              // (filter?.partner__entityName__icontains
              //   ? filter?.partner__entityName__icontains
              //   : "")
            }
            onChange={(e) =>
              setFilterVariables("firstName__icontains", e.target.value)
            }
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Partner Category
          </Typography>
          <Autocomplete
            key={filter ? filter.category : "defaultKey"}
            id="category"
            // disableClearable={false}
            value={
              partnerCategory?.find((x) => x.id === filter?.category) || null
            }
            onChange={(_, value: any) => {
              setFilterVariables("category", value?.id || null);
            }}
            options={partnerCategory}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            // disabled={!editClicked}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose category.."
              />
            )}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            City
          </Typography>
          {/* <Autocomplete
            id="cityId"
            autoComplete
            value={
              filter?.cityId && citiesArr && citiesArr?.length > 0
                ? citiesArr?.find((x: any) => x.id === filter?.cityId)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("cityId", value?.id);
            }}
            options={citiesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          /> */}
          <Autocomplete
            id="cityId"
            autoComplete
            disableClearable={true}
            // multiple={true}
            // value={jobCityPreference}
            value={
              filter?.cityId &&
              filteredCityIdOptions &&
              filteredCityIdOptions?.length > 0
                ? filteredCityIdOptions?.find(
                    (x: any) => x.id === filter?.cityId
                  )
                : null
            }
            onInputChange={(e, newInputValue) => {
              handleCityIdInputChange(newInputValue);
            }}
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("cityId", value?.id);
            }}
            options={filteredCityIdOptions ? filteredCityIdOptions : []}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            sx={{
              "& .MuiAutocomplete-tag": {
                backgroundColor: colors.primaryBlue,
                marginRight: "8px",
                color: colors.secondary,
              },
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose city.." />
            )}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Minimum Experience
          </Typography>
          <TextField
            id="userConsultant__years__gte"
            size="small"
            fullWidth
            placeholder="Enter partner experience"
            value={
              filter?.userConsultant__years__gte
                ? filter?.userConsultant__years__gte
                : ""
            }
            onChange={(e) => {
              if (isNaN(e.target.value as unknown as number)) {
              } else {
                setFilterVariables(
                  "userConsultant__years__gte",
                  parseFloat(e.target.value)
                );
              }
            }}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Working Rate
          </Typography>
          {/* <Autocomplete
            id="expextedHourlyRate__gte"
            value={hourlyRateArr?.find((x) => x.id === "20 hrs/week")}
            onChange={(_, value: any) => {
              setFilterVariables("expextedHourlyRate__gte", value.id);
            }}
            options={hourlyRateArr}
            getOptionLabel={(option: any) => option.name}
            size="small"
            fullWidth
            // disabled={!editClicked}
            renderInput={(params) => <TextField {...params} size="small" />}
          /> */}
          <TextField
            id="expectedRates__icontains"
            size="small"
            fullWidth
            placeholder="Enter working rate"
            value={
              filter?.expectedRates__icontains
                ? filter?.expectedRates__icontains
                : ""
            }
            onChange={(e) =>
              setFilterVariables("expectedRates__icontains", e.target.value)
            }
          />
          {/* {touched.agreedHourlyRate && (
                <Typography fontSize="11px" color={colors.danger}>
                  {errors.agreedHourlyRate} */}
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Certification
          </Typography>
          <TextField
            id="userConsultant__consultantCertificate__name__icontains"
            size="small"
            fullWidth
            placeholder="Enter Certification"
            value={
              filter?.userConsultant__consultantCertificate__name__icontains
                ? filter?.userConsultant__consultantCertificate__name__icontains
                : ""
            }
            onChange={(e) => {
              if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                setFilterVariables(
                  "userConsultant__consultantCertificate__name__icontains",
                  e.target.value
                );
              }
            }}
            sx={{
              backgroundColor: colors.white,
              input: { color: colors.lightGray },
              // width: "90%"
            }}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Domain Expertise
          </Typography>
          <Autocomplete
            id="userConsultant__consultant__serviceId"
            autoComplete
            value={
              filter?.userConsultant__consultant__serviceId &&
              servicesArr &&
              servicesArr?.length > 0
                ? servicesArr?.find(
                    (x: any) =>
                      x.id === filter?.userConsultant__consultant__serviceId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables(
                "userConsultant__consultant__serviceId",
                value?.id
              );
            }}
            options={servicesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose expertise.."
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
