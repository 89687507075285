import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  FormHelperText
} from "@mui/material";
import { colors } from "../../../styles/colors";
import {
  GET_JOB_CATEGORIES,
  GET_INDUSTRIES,
  GET_SERVICES,
  GET_STATES,
  GET_CITIES,
  GET_ORGANIZATION_DROPDOWN_OPTIONS
} from "app/graphql/meta";
import { GET_ALL_LOCATIONS } from "app/graphql/users";
import { useQuery } from "@apollo/client";
import {
  bsuinessDevStatusArr,
  jobModesArr,
  partnerCategoryOptions
} from "../../../utils/constants";
import RadioGroups from "app/components/RadioGroup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CalendarTodayOutlined,
  Close,
  ImageOutlined
} from "@mui/icons-material";

interface FiltersProps {
  setFilter: any;
  filter: any;
  setQueryFilter: any;
}

const Filters = (props: FiltersProps) => {
  const { filter, setFilter } = props;

  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);
  const [statesArr, setStatesArr] = React.useState([]);
  const [citiesArr, setCitiesArr] = React.useState([]);
  const [organizationArr, setOrganizationArr] = React.useState([]);

  const { data: organizations } = useQuery(GET_ORGANIZATION_DROPDOWN_OPTIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {}
  });

  React.useEffect(() => {
    if (industries) {
      console.log(industries);
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  React.useEffect(() => {
    if (organizations && organizations?.organizationDropDown?.organization) {
      console.log(organizations);
      setOrganizationArr(
        organizations?.organizationDropDown?.organization || []
      );
    }
  }, [organizations]);

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" }
  });

  React.useEffect(() => {
    if (states) {
      console.log(states);
      setStatesArr(states?.states || []);
    }
  }, [states]);

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: filter?.stateId },
    skip: !filter?.stateId
  });

  React.useEffect(() => {
    if (cities) {
      console.log(cities);
      setCitiesArr(cities?.cities || []);
    }
  }, [cities]);

  const setFilterVariables = (key, val) => {
    setFilter({ ...filter, [key]: val });
  };

  const [errors, setErrors] = React.useState<any>({});

  const validate = () => {
    let newErrors = {};
    let dirty = false;

    if (filter?.minimumRemuneration__gte) {
      if (filter?.minimumRemuneration__gte < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Min Remuneration cannot be less than 0"
        };

        dirty = true;
      }
    }

    if (filter?.maximumRemuneration__lte) {
      if (filter?.maximumRemuneration__lte < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Max Remuneration cannot be less than 0"
        };
        dirty = true;
      }
    }

    if (
      filter?.minimumRemuneration__gte &&
      filter?.maximumRemuneration__lte &&
      filter?.maximumRemuneration__lte < filter?.minimumRemuneration__gte
    ) {
      newErrors = {
        ...newErrors,
        remuneration: "Min Remuneration cannot be more than Max Remuneration"
      };
      dirty = true;
    }

    if (dirty) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }

    return dirty;
  };

  const { data: locations, loading: loadingLocations } = useQuery(
    GET_ALL_LOCATIONS,
    {
      variables: {}
    }
  );

  return (
    <Box sx={{ borderRadius: "4px", border: "1px solid #E3E3E3" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Typography fontSize="16px" color={colors.fontLight}>
            FILTERS
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography
            fontSize="12px"
            color={colors.primary}
            sx={{ textDecoration: "underline" }}
            onClick={() => {
              setFilter({});
              if (!validate()) {
                props.setQueryFilter({});
              }
            }}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            sx={{ width: "20%" }}
            onClick={() => {
              if (!validate()) {
                const tempDate = filter?.createdDate__gte
                  ? dayjs(filter?.createdDate__gte).format("YYYY-MM-DD")
                  : null;
                props.setQueryFilter({
                  ...filter,
                  ...(tempDate && { createdDate__gte: tempDate })
                });
              }
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
      <div
        style={{
          height: 1,
          backgroundColor: "#E9E9E9",
          width: "100%"
        }}
      />

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Client
          </Typography>
          <Autocomplete
            id="organizationId"
            value={
              filter.organizationId &&
              organizations &&
              organizations?.organizationDropDown &&
              organizations?.organizationDropDown?.organization?.length > 0
                ? organizations?.organizationDropDown?.organization?.find(
                    (item: any) => item?.id === filter.organizationId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("organizationId", value?.id);
            }}
            disableClearable={true}
            options={organizationArr || []}
            getOptionLabel={(option: any) => option.name}
            autoComplete
            sx={{
              backgroundColor: colors.white
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: "4px"
                }}
                placeholder="Choose Client"
              />
            )}
          />
        </Grid>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Project category
          </Typography>
          <Autocomplete
            id="project_category__serviceId"
            autoComplete
            value={
              filter?.project_category__serviceId &&
              servicesArr &&
              servicesArr?.length > 0
                ? servicesArr?.find(
                    (x: any) => x.id === filter?.project_category__serviceId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("project_category__serviceId", value?.id);
            }}
            options={servicesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose project category"
              />
            )}
          />
        </Grid>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Industry
          </Typography>
          <Autocomplete
            id="industryId"
            autoComplete
            value={
              filter?.industryId && industriesArr && industriesArr?.length > 0
                ? industriesArr?.find((x: any) => x.id === filter?.industryId)
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("industryId", value?.id);
            }}
            options={industriesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose industry"
              />
            )}
          />
        </Grid>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight}>
            Location
          </Typography>
          <Autocomplete
            id="cityId"
            autoComplete
            options={locations?.allCities || []}
            onChange={(_, value: any) =>
              setFilterVariables("cityId", value?.id)
            }
            value={
              locations?.allCities && filter?.cityId
                ? locations.allCities.find((x) => x.id === filter?.cityId)
                : {}
            }
            getOptionLabel={(option) => option.name || ""}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose location"
              />
            )}
          />
        </Grid>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Partner category
          </Typography>
          <Autocomplete
            id="preferredBpCategory__contains"
            autoComplete
            value={
              partnerCategoryOptions && filter?.preferredBpCategory__contains
                ? partnerCategoryOptions?.find(
                    (x: any) => x.id === filter?.preferredBpCategory__contains
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("preferredBpCategory__contains", value?.id);
            }}
            options={partnerCategoryOptions || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose partner category"
              />
            )}
          />
        </Grid>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Publish Start Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat={"DD/MM/YYYY"}
              InputProps={{ style: { fontSize: 14 } }}
              components={{
                OpenPickerIcon: CalendarTodayOutlined
              }}
              value={filter?.createdDate__gte || null}
              onChange={(val) => {
                setFilterVariables("createdDate__gte", val);
              }}
              renderInput={(params) => (
                <TextField
                  id="createdDate__gte"
                  placeholder="Select publish date"
                  fullWidth={true}
                  size="small"
                  {...params}
                  sx={{
                    backgroundColor: colors.white,
                    width: "90%"
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={1.7} md={1.7} sm={1.7} xs={1.7}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Publish End Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat={"DD/MM/YYYY"}
              InputProps={{ style: { fontSize: 6 } }}
              components={{
                OpenPickerIcon: CalendarTodayOutlined
              }}
              value={filter?.createdDate__lte || null}
              onChange={(val) => {
                setFilterVariables("createdDate__lte", val);
              }}
              renderInput={(params) => (
                <TextField
                  id="createdDate__lte"
                  placeholder="Select publish date"
                  fullWidth={true}
                  size="small"
                  {...params}
                  sx={{
                    backgroundColor: colors.white,
                    width: "90%"
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
