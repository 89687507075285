import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { clearSession, isJwtTokenValid } from "../utils/helpers";
import React, { lazy } from "react";

export type ProtectedRouteProps = {
  authenticationPath: string;
} & RouteProps;

export default function ProtectedRoute({
  authenticationPath,
  ...routeProps
}: ProtectedRouteProps) {
  const { hasExpired } = isJwtTokenValid();
  if (!hasExpired) {
    return <Route {...routeProps} />;
  } else {
    clearSession();
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}
