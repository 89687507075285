import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import NoBorderDropdown from "app/components/NoBorderDropdown";
import Filters from "./Filters";
import { colors } from "../../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import NoDataFound from "app/components/NoData";
import Table from "../../components/Table";
import "../../components/Table/StatusButton.css";
import { useHistory } from "react-router-dom";
import AdminNavbar from "app/components/AdminNavbar";
import { GET_MANAGED_PROJECTS_APPLICANTS } from "app/graphql/AdminDevelopmentQuries";
import { GridActionsCellItem } from "@mui/x-data-grid";
import images from "assets/images/images";
import { projectStatusChoices } from "utils/constants";
import { CHANGE_PROJECT_STATUS } from "app/graphql/projects";
import { useSnackbar } from "notistack";

function ManageNewProjects() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = React.useState("-created_date");
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryFilter, setQueryFilter] = React.useState(null);
  const [propertiesFilter, setPropertiesFilter] = React.useState({});

  const [projectDataView, setProjectDataView] = React.useState([]);

  const [projectsCount, setProjectDataViewCount] = React.useState(0);

  React.useEffect(() => {
    console.log("queryFilter changed:", queryFilter);
  }, [queryFilter]);

  const {
    data: projectData,
    loading,
    refetch: refetchProjectData
  } = useQuery(GET_MANAGED_PROJECTS_APPLICANTS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      sort_by: sortBy,
      // candidate_to_be_excluded: ""
      exclude_unassigned_projects: true,
      filter: queryFilter
      // ...(queryFilter && { filter: queryFilter })
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (
      projectData &&
      projectData?.projectCandidates &&
      projectData?.projectCandidates?.projectcandidates
    ) {
      setProjectDataView(projectData.projectCandidates.projectcandidates);
      setProjectDataViewCount(projectData.projectCandidates.count);
    } else {
      setProjectDataView([]);
    }
  }, [projectData]);

  const [changeProjectStatus, { loading: CHANGE_PROPERTY_STATUS_LOADING }] =
    useMutation(CHANGE_PROJECT_STATUS, {
      onCompleted: (data) => {
        const { changeProjectStatus } = data;
        const { status, message } = changeProjectStatus;

        if (status) {
          refetchProjectData();
          enqueueSnackbar(message, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    });

  const columns = React.useMemo(
    () => [
      {
        field: "clientName",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            CLIENT NAME
          </Typography>
        ),
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="14px">
              {params?.row?.project?.organization?.name || ""}
            </Typography>
          </Box>
        )
      },
      {
        field: "title",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PROJECT TITLE
          </Typography>
        ),
        minWidth: 120,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.project?.title || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "projectCategory",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PROJECT CATEGORY
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => {
          const domains =
            params?.row?.project?.domain &&
            params?.row?.project?.domain?.length > 0
              ? params?.row?.project?.domain?.map((item, index) => {
                  if (index === 0) {
                    return item?.service?.name;
                  } else {
                    return ", " + item?.service?.name;
                  }
                })
              : [];
          return (
            <Box>
              <Typography fontSize="12px">{domains || "-"}</Typography>
            </Box>
          );
        }
      },
      {
        field: "industry",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            INDUSTRY
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.project?.industry?.name || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "location",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            LOCATION
          </Typography>
        ),
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.project?.city?.name || "-"}
            </Typography>
          </Box>
        )
      },
      {
        field: "partnerName",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PARTNER NAME
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography fontSize="12px">
              {params?.row?.candidate?.firstName || ""}{" "}
              {params?.row?.candidate?.lastName || ""}
            </Typography>
          </Box>
        )
      },
      {
        field: "status",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            STATUS
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          if (params?.row?.project?.status !== 0) {
            // const data = projectStatusChoices?.filter(
            //   (item) => item.id === params?.row?.project?.status
            // );
            // return data.length > 0 ? (
            //   <Typography fontSize="13px" fontWeight={500}>
            //     {data[0]?.name}
            //   </Typography>
            // ) : (
            //   ""
            // );
            return (
              <NoBorderDropdown
                value={
                  params?.row?.status === 0 ? 0 : params?.row?.project?.status
                }
                bgColor="rgba(0, 0, 0, 0.04)"
                textColor={colors.fontLight}
                dropdownArr={projectStatusChoices}
                onChange={(e) => {
                  console.log(e);
                  changeProjectStatus({
                    variables: {
                      payload: {
                        id: params.row?.project?.id,
                        status: e.target.value && JSON.parse(e.target.value)
                      }
                    }
                  });
                }}
              />
            );
          } else {
            return (
              <Button
                variant="outlined"
                onClick={() => {
                  changeProjectStatus({
                    variables: {
                      payload: {
                        id: params.row?.project?.id,
                        status: 21
                      }
                    }
                  });
                }}
              >
                Approve
              </Button>
            );
          }
        }
      },
      {
        field: "actions",
        type: "actions",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            ACTIONS
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    history.push(
                      `/manage-new-project/${params?.row?.project?.id}`
                    );
                  }}
                >
                  <img src={images.editIcon} alt="" />
                </span>
              }
              label="Edit"
            />
          ];
        }
      }
    ],
    []
  );

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography fontSize="22px" fontWeight={500}>
          Manage Project
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Filters
          filter={propertiesFilter}
          setFilter={setPropertiesFilter}
          setQueryFilter={setQueryFilter}
        />
      </Grid>
      <Grid item container lg={12} md={12} sm={12} xs={12}>
        {projectDataView && projectDataView.length > 0 ? (
          <Table
            rows={projectDataView || []}
            columns={columns}
            page={page}
            pageSize={pageSize}
            rowCount={projectsCount || 10}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        ) : (
          <NoDataFound />
        )}
      </Grid>
    </Grid>
  );
}

export function ManageNewProjectsContainer(props: any) {
  return <AdminNavbar mainComponent={<ManageNewProjects />} />;
}
