import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation loginUser($payload: LoginInput!) {
    loginUser(payload: $payload) {
      status
      message
      token
      me {
        id
        firstName
        lastName
        email
        username
        mobile
      }
    }
  }
`;

export const REGISTER = gql`
  mutation registerUser($payload: RegisterUserInput!) {
    registerUser(payload: $payload) {
      status
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($payload: multiFactorLoginInput!) {
    verifyOTP(payload: $payload) {
      status
      message
      token
      me {
        id
        firstName
        lastName
        email
        username
        mobile
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query user($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      username
      email
      mobile
      roles
      dob
      gender
      address
      country {
        id
        name
      }
      state {
        id
        name
      }
      city {
        id
        name
      }
      expextedHourlyRate
      currencyTypeId
      availableHoursPerWeek
      availableDays
      website
      profile
      userWorkExperience {
        occupation {
          id
          name
        }
        experience
        certifications
        userSkills {
          skill {
            id
            name
            description
          }
          expertise
        }
      }
      # userProjects {
      #   status
      #   name
      #   clientName
      #   cost
      #   currencyType {
      #     id
      #     name
      #     description
      #   }
      #   startDate
      #   endDate
      #   description
      #   projectReviews {
      #     review
      #   }
      # }
      organization {
        id
        name
        description
        website
        address
        location {
          id
          name
        }
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
      }
      resume
      resumeName
      resumeType
    }
  }
`;

export const GET_PROJECT_CANDIDATE = gql`
  query projectCandidate($id: String!) {
    projectCandidate(id: $id) {
      id
      candidate {
        id
        firstName
        lastName
        username
        email
        mobile
        roles
        profile
        dob
        gender
        address
        country {
          id
          name
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
        expextedHourlyRate
        currencyTypeId
        availableHoursPerWeek
        availableDays
        website
        userWorkExperience {
          occupation {
            id
            name
          }
          experience
          certifications
          userSkills {
            skill {
              id
              name
              description
            }
            expertise
          }
        }
        userProjects {
          name
          clientName
          cost
          currencyType {
            id
            name
            description
          }
          startDate
          endDate
          description
          projectReviews {
            review
          }
        }
        organization {
          id
          name
          description
          website
          address
          location {
            id
            name
          }
          country {
            id
            name
          }
          state {
            id
            name
          }
          city {
            id
            name
          }
        }
      }
      interviews {
        id
        interviewDate
        startTime
        endTime
        location
        comments
        status
      }
      status
      isSaved
    }
  }
`;

export const GET_RESUME = gql`
  query getResume($payload: String!) {
    getResume(user_id: $payload) {
      document
      documentType
      documentName
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation resetPasswordRequest($payload: ResetPasswordRequest!) {
    resetPasswordRequest(payload: $payload) {
      status
      message
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($id: String!, $token: String!, $password: String!) {
    setPassword(payload: { token: $token, id: $id, password: $password }) {
      status
      message
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUser($payload: UserCreateInput!, $partner_agreement: Upload) {
    createUser(payload: $payload, partner_agreement: $partner_agreement) {
      status
      message
      user {
        id
      }
    }
  }
`;

export const CHANGE_PROJECT_CANDIDATE_STATUS = gql`
  mutation changeProjectCandidateStatus(
    $payload: ChangeProjectCandidateStatusInput!
  ) {
    changeProjectCandidateStatus(payload: $payload) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_CANDIDATE_STATUS = gql`
  mutation updateProjectCandidate($payload: ProjectCandidateUpdateInput!) {
    updateProjectCandidate(payload: $payload) {
      status
      message
    }
  }
`;

export const RESEND_OTP = gql`
  mutation resendOTP {
    resendOTP {
      status
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($ids: [String!]) {
    deleteUsers(ids: $ids) {
      status
      message
    }
  }
`;
// ------------------------------------------------------------------------------------------------------------------

export const GET_USER_JOBS = gql`
  query userJobStatus(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $isApplied: Boolean
    $recommendedJobs: Boolean
  ) {
    userJobStatus(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      is_applied: $isApplied
      recommended_jobs: $recommendedJobs
    ) {
      count
      jobs {
        id
        title
        jobType
        description
        qualification {
          id
          name
        }
        category {
          id
          name
        }
        skills
        city {
          id
          name
        }
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        status
        isSaved
        jobStatus
        createdDate
        modifiedDate
        organization
        salaryConfidential
      }
    }
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query allLocations {
    allCities {
      id
      name
    }
  }
`;

export const GET_TEMPLATE = gql`
  query DataTemplate {
    dataTemplate {
      template
    }
  }
`;

export const BULK_UPLOAD = gql`
  mutation bulkUploadCandidates($payload: [BulkUploadCandidatesInput!]!) {
    bulkUploadCandidates(payload: $payload) {
      status
      error
      users {
        email
        errorMessage
      }
    }
  }
`;

export const CREATE_STUDENT = gql`
  mutation createStudent($payload: UserCreateInput!, $file: Upload) {
    createUser(payload: $payload, file: $file) {
      status
      error
      user {
        email
      }
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation updateStudent($payload: UserUpdateInput!) {
    updateUser(payload: $payload) {
      status
      error
      user {
        email
      }
    }
  }
`;

export const GET_APPLICATION_COUNT = gql`
  query userJobStatus(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $isApplied: Boolean
  ) {
    userJobStatus(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      is_applied: $isApplied
    ) {
      count
    }
  }
`;

// export const DELETE_USERS = gql`
//   mutation deleteUsers($ids: [String!]) {
//     deleteUsers(ids: $ids) {
//       status
//       error
//     }
//   }
// `;

export const DELIST_STUDENT = gql`
  mutation delistStudents($ids: [String!]) {
    delistStudents(ids: $ids) {
      status
      error
    }
  }
`;

export const GET_ALL_USERS = gql`
  query users(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $sortBy: String
  ) {
    users(filter: $filter, limit: $limit, page: $page, sort_by: $sortBy) {
      count
      users {
        id
        firstName
        lastName
        mobile
        email
        dob
        city {
          id
          name
        }
        gender
        qualification {
          id
          qualification {
            id
            name
          }
          course {
            id
            name
          }
          graduationYear
          specialization
          collegePercentage
          college
        }
        workPreference {
          functionalArea {
            id
            name
          }
          jobType1
          jobType2
          jobLocation
          minimumExpectation
          maximumExpectation
        }
        skills
        languagesKnown {
          language
          read
          write
          speak
        }
        workExperience {
          id
          company
          designation
          startDate
          endDate
        }
        status
      }
    }
  }
`;

export const EXPORT_STUDENTS = gql`
  query exportStudents(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $is_student: Boolean
  ) {
    exportStudents(
      filter: $filter
      limit: $limit
      page: $page
      is_student: $is_student
    ) {
      report
    }
  }
`;

export const COLLEGE_INDUSTRY_CONNECT = gql`
  mutation campusIndustryConnect($payload: CampusIndustryConnectInput!) {
    campusIndustryConnect(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_UPSKILLING_URL = gql`
  query getUpskillingUrl {
    getUpskillingUrl {
      status
      error
      loginurl
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      verb
      unread
      id
      timestamp
      description
      level
    }
  }
`;

export const MARK_AS_READ_NOTIFICATIONS = gql`
  mutation markAsRead {
    markAsRead {
      status
      message
    }
  }
`;
