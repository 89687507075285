import * as React from "react";
import { Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { colors } from "../../styles/colors";

interface ICommonModalProps {
  open: boolean;
  setOpen: any;
  children: React.ReactElement;
}

const CommonModal = (props: ICommonModalProps) => {
  const { open, setOpen, children } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-end"
          >
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Clear fontSize="small" sx={{ color: colors.fontLighter }} />
            </IconButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CommonModal;
