import { colors } from "../styles/colors";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { bsuinessDevStatusArr } from "./constants";
// import moment from "moment";

export function getStudentNavbarSelected(location, currentIteration) {
  if (
    location.pathname === "/business-development" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (location.pathname === "/create-project" && currentIteration.id === 1) {
    return true;
  }
  if (location.pathname === "/search-projects" && currentIteration.id === 2) {
    return true;
  }
  if (
    (location.pathname === "/manage-projects" ||
      location.pathname.includes("/manage-project-details/")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (location.pathname === "/manage-client" && currentIteration.id === 4) {
    return true;
  }
  return false;
}

export function getStudentBreadcrumbs(location) {
  if (location.pathname === "/student-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/freelancer-search-project") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Search Job/Internship",
        path: "/freelancer-search-project",
        color: colors.primary
      }
    ];
  }
  // if (location.pathname.includes("/freelancer-project-detail")) {
  //   let jobTitle = "";
  //   if (location && location.state && location.state.title) {
  //     jobTitle = location.state.title;
  //   }
  //   return [
  //     {
  //       id: 1,
  //       name: "Dashboard",
  //       path: "/student-dashboard",
  //       color: "#A4A5A8",
  //     },
  //     {
  //       id: 2,
  //       name: "Search Job/Internship",
  //       path: "/freelancer-search-project",
  //       color: "#A4A5A8",
  //     },
  //     {
  //       id: 3,
  //       name: jobTitle,
  //       path: "",
  //       color: colors.primary,
  //     },
  //   ];
  // }
  if (location.pathname === "/student-upskilling") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Upskilling",
        path: "/student-upskilling",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/student-match-profile")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Upskilling",
        path: "/student-upskilling",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Match Profile",
        path: "",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/student-community")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Community Engagement",
        path: "/student-community",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/student-allcommunity")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Community Engagement",
        path: "/student-community",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Community Engagement",
        path: "/student-community",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Profile",
        path: "/student-profile",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-myapplication") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Application",
        path: "/student-myapplication",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-favourites") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Favourites",
        path: "/student-favourites",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Notification",
        path: "/student-notifications",
        color: colors.primary
      }
    ];
  }
  return [];
}

export function getCompanyNavbarSelected(location, currentIteration) {
  if (location.pathname === "/company/projects" && currentIteration.id === 1) {
    return true;
  }
  if (
    location.pathname === "/company/testimonials" &&
    currentIteration.id === 2
  ) {
    return true;
  }
  return false;
}

export function getEmployerBreadcrumbs(location) {
  if (location.pathname === "/company/dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/company-dashboard",
        color: "#A4A5A8"
      }
    ];
  }
  if (location.pathname === "/company/projects") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/company-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Projects List",
        path: "/company-projects",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/company/projects/add-new") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/company-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Projects",
        path: "/company/projects",
        color: colors.primary
      },
      {
        id: 3,
        name: "Add Project",
        path: "/company/projects/add-new",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Profile",
        path: "/employer-profile",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-internship") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Internship",
        path: "/employer-internship",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/employer-internship-details") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Internship",
        path: "/employer-internship",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Internship Details",
        path: "/employer-internship-details",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/employer-intern-registration")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Intership",
        path: "/employer-internship",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Post Internship",
        path: "/employer-intern-registration",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-applied-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Applied Candidates",
        path: "/employer-applied-candidates",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-college-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-college-list") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "A M Jain College of Arts and Science",
        path: "/employer-college-list",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-college-connect-students-list") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "A M Jain College of Arts and Science",
        path: "/employer-college-list",
        color: "#A4A5A8"
      },
      {
        id: 4,
        name: "Students List",
        path: "/employer-college-connect-students-list",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-search-job") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/company-add-project") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Post Job",
        path: "/company-add-project",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-job-draft") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Drafts",
        path: "/employer-job-draft",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/employer-job-appliedcanditate") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Job Title",
        path: "/employer-job-view-details",
        color: "#A4A5A8"
      },
      {
        id: 4,
        name: "Applied Candidate",
        path: "/employer-job-appliedcanditate",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-search-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Search Candidates",
        path: "/employer-search-candidates",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Notification",
        path: "/employer-notifications",
        color: colors.primary
      }
    ];
  }
  return [];
}

export function getCampusNavBarSelected(location, currentIteration) {
  if (location.pathname === "/campus-dashboard" && currentIteration.id === 0) {
    return true;
  }
  if (
    (location.pathname === "/campus-students" ||
      location.pathname.includes("/campus-students")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-bulk-update-students" ||
      location.pathname.includes("/campus-bulk-update-students")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-add-edit-student" &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-student-activity" &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-connect" ||
      location.pathname.includes("/campus-industry-connect")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-faculty-development" ||
      location.pathname.includes("/campus-faculty-development")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-immersion-program" ||
      location.pathname.includes("/campus-industry-immersion-program")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-notifications" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-consulting-project" ||
      location.pathname.includes("/campus-industry-consulting-project")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-service-offerings" &&
    currentIteration.id === 5
  ) {
    return true;
  }

  return false;
}

export function getCampusFLowBreadcrumbs(location) {
  if (location.pathname === "/campus-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Profile",
        path: "/campus-profile",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-students") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-bulk-update-students") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Bulk Upload",
        path: "/campus-bulk-update-students",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-add-edit-student") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Add Student",
        path: "/campus-add-edit-student",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-student-activity") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Student Activity",
        path: "/campus-student-activity",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-industry-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Connect",
        path: "/campus-industry-connect",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Notification",
        path: "/campus-notifications",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-industry-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Connect",
        path: "/campus-industry-connect",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Posted Jobs",
        path: "/campus-industry-connect/posted-jobs",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-faculty-development") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Faculty Development",
        path: "/campus-faculty-development",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-industry-immersion-program") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Immersion Program",
        path: "/campus-industry-immersion-program",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-industry-consulting-project") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Consulting Project",
        path: "/campus-industry-consulting-project",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-service-offerings") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Services Offerings",
        path: "/campus-service-offerings",
        color: colors.primary
      }
    ];
  }
  return [];
}
export const isJwtTokenValid = () => {
  const token = sessionStorage.getItem("token") || "";
  let hasExpired = true;
  let isVerified = false;
  let roles = [];
  let lastLogin = null;
  let username = "";

  if (Boolean(token)) {
    const decoded: any = jwt_decode(token);
    if (Date.now() < decoded.exp * 1000) {
      hasExpired = false;
      if (Boolean(decoded.verified)) {
        isVerified = true;
      }
      if (Boolean(decoded.roles)) {
        roles = decoded.roles;
      }

      if (Boolean(decoded.lastLogin)) {
        lastLogin = decoded.lastLogin;
      }

      if (Boolean(decoded.username)) {
        username = decoded.username;
      }
    }
  }

  return { hasExpired, isVerified, roles, lastLogin, username };
};

export const clearSession = () => {
  ["token", "me"].forEach((k) => sessionStorage.removeItem(k));
};

export const isTokenPresent = () => {
  return Boolean(sessionStorage.getItem("token"));
};

export const getDefaultRoute = () => {
  const { hasExpired, roles } = isJwtTokenValid();
  let me: any = {};
  if (Boolean(sessionStorage.getItem("me"))) {
    const temp = sessionStorage.getItem("me");
    me = temp ? JSON.parse(temp) : "";
  }
  if (!hasExpired) {
    if (roles[0] === "college" && me?.college && me?.college?.status !== 0) {
      return "/campus-dashboard";
    }
    if (
      roles[0] === "college" &&
      me?.college &&
      (me?.college?.status === 0 || me?.college?.status === 9)
    ) {
      return "/campus-kyc";
    }
    if (
      roles[0] === "employer_user" &&
      me?.organization &&
      me?.organization?.status !== 0
    ) {
      return "/employer-dashboard";
    }
    if (
      roles[0] === "employer_user" &&
      me?.organization &&
      (me?.organization?.status === 0 || me?.organization?.status === 9)
    ) {
      return "/employer-kyc";
    }
    if (roles[0] === "student") {
      return "/student-dashboard";
    }
    if (roles[0] === "super_admin") {
      return "/admin-entities";
    }
  }
  return "/";
};

export function getAdminNavbarSelected(location, currentIteration) {
  if (
    location.pathname === "/partner-registration" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (
    location.pathname === "/new-business-development" &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname.includes("/publish-project") ||
      location.pathname.includes("/view-selected-project/")) &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    location.pathname === "/manage-new-project" &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    location.pathname === "/admin/manage-client" &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (location.pathname === "/admin/manage-user" && currentIteration.id === 5) {
    return true;
  }

  return false;
}

// export const getPrettyDate = (d) => {
//   let dMoment = moment(d);
//   return dMoment.format("DD MMM YYYY hh:mm A");
// };

// export const getMonthAndYear = (d) => {
//   let dMoment = moment(d);
//   return dMoment.format("MMM YYYY");
// };

// export const downloadCSV = (csvContent, filename) => {
//   var hiddenElement = document.createElement("a");
//   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
//   hiddenElement.target = "_blank";
//   hiddenElement.download = filename;
//   hiddenElement.click();
// };

export const downloadExcel = (excelContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:application/vnd.ms-excel;base64," + encodeURI(excelContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadAny = (fileContent, filename, fileType) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:" + fileType + ";base64," + fileContent;
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadPDF = (fileContent, filename, fileType) => {
  const byteCharacters = atob(fileContent);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "Partner_Agreement";

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const viewDocumentInNewTab = (fileContent, filename, fileType) => {
  const byteCharacters = atob(fileContent);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });

  const url = URL.createObjectURL(blob);

  window.open(url, "_blank");

  URL.revokeObjectURL(url);
};

export const getJavascriptObject = (data) => {
  var modifedData = {};
  modifedData = data?.map((item) => {
    const dateCreated = item?.createdDate
      ? getDateInFormat(item?.createdDate, "DD-MM-YYYY")
      : "";
    const domainsArr =
      item?.domain &&
      item?.domain?.length > 0 &&
      item?.domain?.map((item, index) => {
        if (index === 0) {
          return item?.service?.name;
        } else {
          return ", " + item?.service?.name;
        }
      });
    const status = bsuinessDevStatusArr?.find(
      (item2) => item2?.id === item?.enquiryStatus
    );
    const domains = domainsArr
      ?.join(", ")
      .replace(/,/g, " ")
      .replace(/\n/g, " "); // Replace commas and line breaks with spaces

    return {
      "BUSINESS PARTNER": `${item?.user?.firstName || ""} ${
        item?.user?.lastName || ""
      }`,
      // NAME: item?.spocName || "",
      "CLIENT NAME": item?.companyName || "",
      CATEGORY: domains || "",
      STATUS: status?.name || "",
      "CREATED DATE": dateCreated || ""
    };
  });
  return modifedData;
};

export const downloadCSV = (data, fileName) => {
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((item) => Object.values(item).join(",")).join("\n");
    return header + rows;
  };
  console.log(data);
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};

export const capitalize = function (word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const getLoggedInUserData = () => {
  let meJSON = sessionStorage.getItem("me");
  return meJSON ? JSON.parse(meJSON) : null;
};

export const getDateInFormat = (date, format) => {
  return dayjs(date).format(format);
};

export const getDuration = (end, start) => {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisecondsPerWeek = millisecondsPerDay * 7;
  const millisecondsPerMonth = millisecondsPerDay * 30.44;
  const millisecondsPerYear = millisecondsPerDay * 365.25;

  const durationInMilliseconds = end - start;
  const durationInDays = durationInMilliseconds / millisecondsPerDay;
  const durationInWeeks = durationInMilliseconds / millisecondsPerWeek;
  const durationInMonths = durationInMilliseconds / millisecondsPerMonth;
  const durationInYears = durationInMilliseconds / millisecondsPerYear;

  if (durationInYears >= 1) {
    return (
      Math.floor(durationInYears) +
      " year" +
      (Math.floor(durationInYears) > 1 ? "s" : "")
    );
  } else if (durationInMonths >= 1) {
    return (
      Math.floor(durationInMonths) +
      " month" +
      (Math.floor(durationInMonths) > 1 ? "s" : "")
    );
  } else if (durationInWeeks >= 1) {
    return (
      Math.floor(durationInWeeks) +
      " week" +
      (Math.floor(durationInWeeks) > 1 ? "s" : "")
    );
  } else {
    return (
      Math.floor(durationInDays) +
      " day" +
      (Math.floor(durationInDays) > 1 ? "s" : "")
    );
  }
};

export const maskMobile = (mobile: string) => {
  return mobile && mobile.length === 10
    ? mobile.substring(0, 2) + "XXXXX" + mobile.substring(8)
    : mobile;
};

export const getUserInfo = () => {
  const me = sessionStorage.getItem("me") || "{}";
  const parsedMe = JSON.parse(me);
  const { firstName, lastName, organization, college, id } = parsedMe;

  return {
    firstName: firstName || "",
    lastName: lastName || "",
    organization: organization?.name || college?.name || "",
    id: id || ""
  };
};

export const formDateFromStringYYYYMMDD = (dateString) => {
  const dateParts = dateString.split("-");
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  return date;
};

// export const getFromNowDate = (date) => {
//   var relativeTime = require("dayjs/plugin/relativeTime");
//   dayjs.extend(relativeTime);
//   return dayjs(date).fromNow();
// };

export const isDateDifferenceMoreThan90Days = (dateString) => {
  const dateToCompare = dayjs(dateString);
  const currentDate = dayjs();
  const differenceInDays = dateToCompare.diff(currentDate, "day");
  return differenceInDays > 90;
};
