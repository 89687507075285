import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
// import Navbar from "app/components/Navbar";
import NoBorderDropdown from "app/components/NoBorderDropdown";
import Filters from "./Filters";
import {
  manageProjectStatusArr,
  projectStatusChoices,
  statusArr
} from "utils/constants";
import { colors } from "../../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import NoDataFound from "app/components/NoData";
import {
  CheckCircleOutlineOutlined,
  CancelOutlined,
  FactCheckOutlined,
  GroupsOutlined
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Table from "../../components/Table";
import "../../components/Table/StatusButton.css";
import { useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import images from "assets/images/images";
// import {
//   GET_ALL_PROPERTIES,
//   CHANGE_PROPERTY_STATUS,
//   DELETE_PROPERTY
// } from "app/graphql/properties";
import { useSnackbar } from "notistack";
import NavBar from "app/components/Navbar";
import { GET_PROJECTS } from "app/graphql/SearchProject";
import dayjs from "dayjs";
import {
  CHANGE_PROJECT_STATUS,
  DELETE_PROJECT,
  GET_BP_USER_PROJECTS
} from "app/graphql/projects";

function ManageProjectsContent() {
  const history = useHistory();
  const [sortBy, setSortBy] = React.useState("-created_date");
  const [students, setStudents] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [queryFilter, setQueryFilter] = React.useState({
    status__in: [0, 21, 15, 22]
  });
  const [propertiesFilter, setPropertiesFilter] = React.useState({});
  // const [properties, setProperties] = React.useState([]);
  const [sendEmailModalOpen, setSndEmailModalOpen] = React.useState(false);
  const [selectedProperty, setSelectedProperty] = React.useState({});
  const [addEnquiry, setAddEnquiry] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [projects, setProjects] = React.useState([
    {
      id: 1,
      title: "Project title 1",
      category: {
        name: "Project category 1"
      },
      createdBy: {
        organization: {
          name: "Person ABC"
        }
      },
      duration: "4",
      durationType: "Weeks",
      remuneration: "50000",
      currencyType: {
        name: "USD"
      },
      projectStatus: 3
    },
    {
      id: 2,
      title: "Project title 2",
      category: {
        name: "Project category 2"
      },
      createdBy: {
        organization: {
          name: "Person DEF"
        }
      },
      duration: "2",
      durationType: "Months",
      remuneration: "200000",
      currencyType: {
        name: "INR"
      },
      projectStatus: 4
    }
  ]);
  const [projectsCount, setProjectsCount] = React.useState(0);

  React.useEffect(() => {
    console.log("queryFilter changed:", queryFilter);
  }, [queryFilter]);

  const {
    data: allProjects,
    loading,
    refetch: refetchAllProjects
  } = useQuery(GET_BP_USER_PROJECTS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: queryFilter,
      sort_by: sortBy,
      candidate_to_be_excluded: ""
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  // console.log("🚀 ~ file: index.tsx:68 ~ SearchJobsInternships ~ allProjects:", allProjects?.userProjectStatus?.projects)

  React.useEffect(() => {
    if (
      allProjects &&
      allProjects.userProjectStatus &&
      allProjects.userProjectStatus.projects
    ) {
      setProjects(allProjects.userProjectStatus.projects);
      setProjectsCount(allProjects.userProjectStatus.count);
    } else {
      setProjects([]);
    }
  }, [allProjects]);

  // const {
  //   data: propertiesData,
  //   loading: propertiesDataLoading,
  //   refetch: refetchPropertiesData
  // } = useQuery(GET_ALL_PROPERTIES, {
  //   nextFetchPolicy: "network-only",
  //   fetchPolicy: "network-only",
  //   variables: {
  //     page: page + 1,
  //     limit: pageSize,
  //     filter: queryFilter
  //   },
  //   onCompleted: (data) => {
  //     console.log("propertiesData", data);
  //   }
  // });

  // const [changePropertStatus, { loading: CHANGE_PROPERTY_STATUS_LOADING }] =
  //   useMutation(CHANGE_PROPERTY_STATUS, {
  //     onCompleted: (data) => {
  //       const { changePropertyStatus } = data;
  //       const { status, message } = changePropertyStatus;

  //       if (status) {
  //         refetchPropertiesData();
  //         enqueueSnackbar(message, {
  //           variant: "success"
  //         });
  //       } else {
  //         enqueueSnackbar(message, {
  //           variant: "error"
  //         });
  //       }
  //     }
  //   });

  // const [deleteProperty, { loading: DELETE_PROPERTY_LOADING }] = useMutation(
  //   DELETE_PROPERTY,
  //   {
  //     onCompleted: (data) => {
  //       const { deleteProperty } = data;
  //       const { status, message } = deleteProperty;
  //       if (status) {
  //         refetchPropertiesData();
  //         enqueueSnackbar(message, {
  //           variant: "success"
  //         });
  //       } else {
  //         enqueueSnackbar(message, {
  //           variant: "error"
  //         });
  //       }
  //     }
  //   }
  // );

  // React.useEffect(() => {
  //   if (propertiesData && propertiesData.alllProperties) {
  //     setProperties(propertiesData.alllProperties.property);
  //     setPropertiesCount(propertiesData.alllProperties.count);
  //   } else {
  //     setProperties([]);
  //     setPropertiesCount(0);
  //   }
  // }, [propertiesData]);

  // const [rowCount, setRowCount] = React.useState(0);

  const [changeProjectStatus, { loading: CHANGE_PROPERTY_STATUS_LOADING }] =
    useMutation(CHANGE_PROJECT_STATUS, {
      onCompleted: (data) => {
        const { changeProjectStatus } = data;
        const { status, message } = changeProjectStatus;

        if (status) {
          refetchAllProjects();
          enqueueSnackbar(message, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      }
    });

  const [deleteProject, { loading: deleting }] = useMutation(DELETE_PROJECT, {
    onCompleted: (data) => {
      const { deleteProject } = data;
      const { status, message } = deleteProject;
      if (status) {
        refetchAllProjects();
        enqueueSnackbar(message, { variant: "success" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  });

  const columns = React.useMemo(
    () => [
      {
        field: "projectTitle",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            PROJECT TITLE
          </Typography>
        ),
        minWidth: 170,
        flex: 2,
        // headerAlign: "left",
        // align: "left",
        renderCell: (params) => (
          <Box px={1}>
            <Typography fontSize="14px">{params.row?.title}</Typography>
          </Box>
        )
      },
      {
        field: "client",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            CLIENT
          </Typography>
        ),
        minWidth: 150,
        flex: 2,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div>
            <Typography fontSize="14px">{params.row?.org || ""}</Typography>
          </div>
        )
      },
      {
        field: "endDate",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            END DATE
          </Typography>
        ),
        minWidth: 180,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div>
            <Typography fontSize="14px">
              {params.row?.endDate
                ? dayjs(params.row?.endDate).format("DD-MM-YYYY")
                : null}
            </Typography>
          </div>
        )
      },
      // {
      //   field: "projectCategory",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       PROJECT CATEGORY
      //     </Typography>
      //   ),
      //   minWidth: 190,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="15px">
      //         {params.row?.projectCategory}
      //       </Typography>
      //     </div>
      //   )
      // },
      // {
      //   field: "spocName",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       SPOC NAME
      //     </Typography>
      //   ),
      //   minWidth: 170,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="15px">{params.row?.spocName}</Typography>
      //     </div>
      //   )
      // },
      // {
      //   field: "spocEmail",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       CITY
      //     </Typography>
      //   ),
      //   minWidth: 200,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="15px">{params.row?.spocEmail}</Typography>
      //     </div>
      //   )
      // },
      // {
      //   field: "spocMobile",
      //   headerName: (
      //     <Typography fontSize="13px" fontWeight={500}>
      //       SPOC MOBILE
      //     </Typography>
      //   ),
      //   minWidth: 170,
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      //   renderCell: (params) => (
      //     <div>
      //       <Typography fontSize="15px">{params.row?.spocMobile}</Typography>
      //     </div>
      //   )
      // },
      {
        field: "status",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            STATUS
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          if (params?.row?.status === 0) {
            return (
              <Typography fontSize="14px" color={"orange"}>
                Pending Approval
              </Typography>
            );
          }
          return (
            <NoBorderDropdown
              value={params?.row?.status === 0 ? 0 : params?.row?.status}
              bgColor="rgba(0, 0, 0, 0.04)"
              textColor={colors.fontLight}
              dropdownArr={projectStatusChoices}
              onChange={(e) => {
                console.log(e);
                changeProjectStatus({
                  variables: {
                    payload: {
                      id: params.row?.id,
                      status: e.target.value && JSON.parse(e.target.value)
                    }
                  }
                });
              }}
            />
          );
        }
      },
      {
        field: "actions",
        type: "actions",
        headerName: (
          <Typography fontSize="13px" fontWeight={500}>
            ACTIONS
          </Typography>
        ),
        minWidth: 140,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          return [
            <GridActionsCellItem
              // nonce={undefined}
              // onResize={undefined}
              // onResizeCapture={undefined}
              icon={
                <span
                  style={{ display: "flex", alignContent: "center" }}
                  onClick={() => {
                    history.push(`/manage-project-details/${params?.row?.id}`);
                  }}
                >
                  <img src={images.editIcon} alt="" />
                </span>
              }
              label="View"
            />
            // <GridActionsCellItem
            //   // nonce={undefined}
            //   // onResize={undefined}
            //   // onResizeCapture={undefined}
            //   icon={
            //     <span
            //       style={{
            //         display: "flex",
            //         alignContent: "center",
            //         marginRight: "5px",
            //         paddingLeft: "2px",
            //         marginTop: "-3px"
            //       }}
            //     >
            //       <img
            //         src={images.editIcon}
            //         alt=""
            //         // onClick={() => {
            //         //   history.push(`/add-edit-property/${params.row.id}`);
            //         // }}
            //       />
            //     </span>
            //   }
            //   label="Edit"
            // />,
            //   <GridActionsCellItem
            //     // nonce={undefined}
            //     // onResize={undefined}
            //     // onResizeCapture={undefined}
            //     icon={
            //       <span
            //         style={{ display: "flex", alignContent: "center" }}
            //         onClick={() => {
            //           deleteProject({
            //             variables: {
            //               ids: [params.row.id]
            //             }
            //           });
            //         }}
            //       >
            //         <DeleteOutlineIcon fontSize="small" />
            //       </span>
            //     }
            //     label="Delete"
            //     // onClick={() => {
            //     //   deleteProperty({
            //     //     variables: {
            //     //       id: params.row.id
            //     //     }
            //     //   });
            //     // }}
            //   />
          ];
        }
      }
    ],
    []
  );

  const handleClose = () => {
    setSndEmailModalOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography fontSize="22px" fontWeight={500}>
          Project Details
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}>
        <Filters
          filter={propertiesFilter}
          setFilter={setPropertiesFilter}
          setQueryFilter={setQueryFilter}
        />
      </Grid>
      <Grid item container lg={9} md={9} sm={9} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {projects && projects.length > 0 ? (
            <Table
              rows={projects || []}
              columns={columns}
              page={page}
              pageSize={pageSize}
              rowCount={projectsCount || 10}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export function ManageProjectsPage(props: any) {
  return <NavBar mainComponent={<ManageProjectsContent />} />;
}
