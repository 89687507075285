import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton
} from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { colors } from "../../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { useMutation } from "@apollo/client";
import { SET_PASSWORD } from "../../graphql/users";
import images from "../../../assets/images/images";
import { useSnackbar } from "notistack";

const RightPartForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid
  } = props;

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  return (
    <Box
      sx={{
        py: { xs: 5, sm: 3 },
        px: { xs: 5, sm: 10 },
        backgroundColor: { xs: colors.primary, sm: colors.secondary },
        minHeight: "100vh",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.secondary,
          borderRadius: "4px"
        }}
      >
        <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
          <Grid item lg={12} md={12} sm={12}>
            <img src={images.logo_final_full_lite} height={35} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color={colors.dark} fontSize="24px" fontWeight={500}>
              Set Password
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <Typography fontSize="14px">Password</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
            <TextField
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword1 ? "text" : "password"}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 ? (
                        <VisibilityOutlined size="small" />
                      ) : (
                        <VisibilityOffOutlined size="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {touched.password && (
              <Typography fontSize="11px" color={colors.danger}>
                {errors.password}
              </Typography>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <Typography fontSize="14px">Confirm Password</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
            <TextField
              id="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword2 ? "text" : "password"}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      {showPassword2 ? (
                        <VisibilityOutlined size="small" />
                      ) : (
                        <VisibilityOffOutlined size="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {touched.confirmPassword && (
              <Typography fontSize="11px" color={colors.danger}>
                {errors.confirmPassword}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          >
            <Button
              disabled={!values.password || !isValid}
              size="small"
              variant="contained"
              sx={{ width: "50%" }}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const RightPartFormik = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // const setLoading = getStore((state) => state.setLoading);

  const [queryStringParsed, setQueryStringParsed] = useState({
    id: "",
    token: ""
  });

  const [setPassword, { loading }] = useMutation(SET_PASSWORD, {
    onCompleted: (data) => {
      const { setPassword } = data;
      const { message, status } = setPassword;
      if (status) {
        enqueueSnackbar(message, {
          variant: "success"
        });
        setTimeout(() => {
          history.push("/");
        }, 200);
      } else {
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
  });

  // Update the useEffect to set the state with the extracted id and token
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const token = searchParams.get("token");
    console.log({ id }, { token });
    if (id && token) {
      setQueryStringParsed({ id, token });
    } else {
      history.push("/");
    }
  }, [location]);

  // useEffect(() => {
  //   setLoading(loading);
  // }, [loading, setLoading]);

  const RightPartFormWrapped = withFormik({
    mapPropsToValues: () => ({
      password: "",
      confirmPassword: ""
    }),

    validationSchema: Yup.object().shape({
      password: Yup.string().required("Please enter the password"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      )
    }),

    handleSubmit(values, { props }) {
      setPassword({
        variables: {
          id: queryStringParsed.id, // Pass the id from the state
          token: queryStringParsed.token, // Pass the token from the state
          password: values.password
        }
      });
    }
  })(RightPartForm);

  return <RightPartFormWrapped />;
};

export default RightPartFormik;
