import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation createProject(
    $payload: ProjectCreateInput!
    $description: Upload
    $final_proposal: Upload
    $work_order: Upload
  ) {
    createProject(
      payload: $payload
      description_document: $description
      final_proposal: $final_proposal
      work_order: $work_order
    ) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $payload: ProjectUpdateInput!
    $description_document: Upload
    $final_proposal: Upload
    $work_order: Upload
  ) {
    updateProject(
      payload: $payload
      description_document: $description_document
      final_proposal: $final_proposal
      work_order: $work_order
    ) {
      status
      message
    }
  }
`;

export const CHANGE_PROJECT_STATUS = gql`
  mutation changeProjectStatus($payload: ChangeProjectStatusInput!) {
    changeProjectStatus(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_PROJECTS = gql`
  query projects(
    $filter: ProjectFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
    $candidate_to_be_excluded: String
  ) {
    projects(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
      candidate_to_be_excluded: $candidate_to_be_excluded
    ) {
      count
      projects {
        id
        title
        description
        category {
          id
          name
        }
        duration
        durationType
        remuneration
        remunerationType
        createdBy {
          organization {
            name
          }
        }
        currencyType {
          id
          name
        }
        createdDate
        appliedCount
        shortlistedCount
        offeredCount
        rejectedCount
      }
    }
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query Project($id: String!) {
    project(id: $id) {
      id
      title
      workOrder
      finalProposal
      description
      category {
        id
        name
      }
      organization {
        id
        name
      }
      duration
      durationType
      startDate
      endDate
      status
      projectStatus
      domain {
        id
        service {
          id
          name
        }
      }
      outOfPocketExpenses
      gst
      otherFeeType
      otherFee
      creditPeriod
      termsOfPayment
      keyDeliverables
      clientDeliverables {
        deliverable
        deliveryDate
        comments
      }
      isSaved
      createdDate
      createdBy {
        id
        organization {
          id
          name
        }
        username
        firstName
        lastName
      }
      projectSkills {
        skill {
          id
          name
        }
      }
      remuneration
      remunerationType
      currencyType {
        id
        name
      }
      appliedCount
      shortlistedCount
      offeredCount
      rejectedCount
      preferredType
      preferredBp
      preferredBpCategory
      preferredBpCity
      preferredCityName {
        id
        name
      }
      deliveryMode
      city {
        id
        name
      }
      industry {
        id
        name
      }
      org
    }
  }
`;

export const GET_BP_USER_PROJECTS = gql`
  query userProjectStatus(
    $filter: UserProjectFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
  ) {
    userProjectStatus(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
    ) {
      count
      projects {
        id
        title
        org
        endDate
        status
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($ids: [String!]) {
    deleteProject(ids: $ids) {
      status
      message
    }
  }
`;

export const GET_PROJECT_DOCUMENTS = gql`
  query getProjectDocuments($payload: GetDocumentInput!) {
    getProjectDocuments(payload: $payload) {
      document
      documentType
      documentName
    }
  }
`;

export const GET_PROJECTS_FREELANCER = gql`
  query userProjectStatus(
    $filter: UserProjectFilterInput
    $limit: Int
    $page: Int
    $is_applied: Boolean
    $sort_by: String
  ) {
    userProjectStatus(
      filter: $filter
      limit: $limit
      page: $page
      is_applied: $is_applied
      sort_by: $sort_by
    ) {
      count
      projects {
        id
        title
        description
        duration
        durationType
        category {
          name
        }
        remuneration
        remunerationType
        currencyType {
          name
        }
        projectSkills {
          skill {
            name
          }
        }
        projectStatus
        createdDate
        createdBy {
          email
          organization {
            name
          }
        }
        isSaved
        status
        projectStatus
      }
      offeredCount
      rejectedCount
      appliedCount
      shortlistedCount
    }
  }
`;

export const GET_PROJECT_APPLICANTS = gql`
  query projectCandidates(
    $filter: ProjectCandidateFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
  ) {
    projectCandidates(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
    ) {
      count
      projectcandidates {
        id
        project {
          id
          title
          description
          category {
            id
            name
          }
        }
        candidate {
          id
          firstName
          lastName
          username
          email
          mobile
          roles
          profile
          # dob
          # gender
          # address
          # country {
          #   id
          #   name
          # }
          # state {
          #   id
          #   name
          # }
          city {
            id
            name
          }
          # expextedHourlyRate
          # currencyTypeId
          # availableHoursPerWeek
          # availableDays
          # website
          userWorkExperience {
            occupation {
              id
              name
            }
            experience
            certifications
            userSkills {
              skill {
                id
                name
              }
              expertise
            }
          }
          organization {
            name
          }
        }
        createdDate
        status
      }
    }
  }
`;

export const UPDATE_PROJECT_CANDIDATE = gql`
  mutation changeProjectCandidateStatus(
    $payload: ChangeProjectCandidateStatusInput!
  ) {
    changeProjectCandidateStatus(payload: $payload) {
      status
      message
    }
  }
`;

export const MARK_FAVOURITE = gql`
  mutation markProjectAsFavourite($payload: MarkProjectAsFavouriteInput!) {
    markProjectAsFavourite(payload: $payload) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_CANDIDATE = gql`
  mutation createProjectCandidate($payload: [ProjectCandidateCreateInput!]) {
    createProjectCandidate(payload: $payload) {
      status
      message
    }
  }
`;

// -----------------------------------------------------------------------------------------------------------------------

export const GET_JOB = gql`
  query job($id: String!) {
    job(id: $id) {
      id
      title
      jobType
      jobMode
      jobTeamType
      description
      category {
        id
        name
      }
      industry {
        id
        name
      }
      qualification {
        id
        name
      }
      courses
      certificates
      skills
      state {
        id
        name
      }
      city {
        id
        name
      }
      country {
        id
        name
      }
      location
      positions
      salaryConfidential
      minimumRemuneration
      maximumRemuneration
      perks
      minimumExperience
      maximumExperience
      minimumYearOfPassing
      maximumYearOfPassing
      genderPreference
      closure
      startDate
      duration
      confidential
      profile
      createdBy {
        id
        firstName
        lastName
      }
      status
      numberOfViews
      AppliedCount
      ShortlistedCount
      OfferedCount
      RejectedCount
      jobStatus
      isSaved
      modifiedDate
      createdDate
      languages {
        id
        language
        read
        write
        speak
      }
    }
  }
`;

export const GET_JOBS = gql`
  query jobs(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $not_candidate: String
  ) {
    jobs(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      not_candidate: $not_candidate
    ) {
      count
      jobs {
        id
        title
        jobType
        jobMode
        jobTeamType
        description
        category {
          id
          name
        }
        qualification {
          id
          name
        }
        city {
          id
          name
        }
        skills
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        status
        numberOfViews
        createdDate
      }
    }
  }
`;

export const ADD_JOB = gql`
  mutation createjob($payload: JobCreateInput!) {
    createJob(payload: $payload) {
      status
      error
      job {
        id
        title
      }
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($payload: JobUpdateInput!) {
    updateJob(payload: $payload) {
      status
      error
      job {
        id
        title
      }
    }
  }
`;

export const UPDATE_JOB_CANDIDATE_BY_JOB = gql`
  mutation upsertJobCandidate($payload: [JobCandidateUpsertInput!]) {
    upsertJobCandidate(payload: $payload) {
      status
      error
    }
  }
`;

export const JOB_CANDIDATES = gql`
  query jobcandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    jobCandidates(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
      jobcandidates {
        id
        job {
          id
          title
          city {
            id
            name
          }
        }
        candidate {
          id
          firstName
          lastName
          email
          mobile
          skills
        }
        rating
        status
        comments
        createdDate
        history {
          status
          createdDate
        }
      }
    }
  }
`;

export const JOB_CANDIDATES_COUNT = gql`
  query jobcandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    jobCandidates(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
    }
  }
`;

export const GET_JOB_CANDIDATE = gql`
  query getJobCandidate($id: String!) {
    jobCandidate(id: $id) {
      id
      candidate {
        id
        firstName
        lastName
        email
        mobile
        dob
        location
        city {
          id
          name
        }
        state {
          id
          name
        }
        gender
        qualification {
          id
          qualification {
            id
            name
          }
          course {
            id
            name
          }
          collegePercentage
          specialization
          graduationYear
        }
        workExp {
          id
          designation
          company
          functionalArea {
            id
            name
          }
          startDate
          endDate
        }
        workPref {
          id
          jobLocation
          functionalArea {
            id
            name
          }
          industry {
            id
            name
          }
          jobState {
            id
            name
          }
          jobCity {
            id
            name
          }
          jobType1
          jobType2
          minimumExpectation
          maximumExpectation
        }
        skills
        languagesKnown {
          id
          language
          read
          write
          speak
        }
        profile
        facultyRecommendation
      }
      interviews {
        id
        interviewDate
        startTime
        endTime
      }
      comments
      status
      createdDate
      modifiedDate
      history {
        status
        createdDate
      }
      job {
        id
        title
        organization
      }
      interviews {
        id
        startTime
        endTime
        interviewDate
        location
        comments
        status
      }
    }
  }
`;

export const SCHEDULE_INTERVIEW = gql`
  mutation scheduleInterview($payload: InterviewCreateInput!) {
    scheduleInterview(payload: $payload) {
      status
      message
    }
  }
`;

export const EDIT_SCHEDULED_INTERVIEW = gql`
  mutation ediScheduleInterview($payload: InterviewUpdateInput!) {
    updateScheduledInterview(payload: $payload) {
      status
      message
    }
  }
`;

export const DELETE_SCHEDULED_INTERVIEW = gql`
  mutation deleteScheduledInterview($ids: [String!]) {
    deleteScheduledInterview(ids: $ids) {
      status
      message
    }
  }
`;

export const SEARCH_JOBS = gql`
  query searchJobs($filter: JobFilterInput, $limit: Int, $page: Int) {
    searchJobs(filter: $filter, limit: $limit, page: $page) {
      count
      jobs {
        id
        title
        jobType
        description
        city {
          id
          name
        }
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        qualification {
          id
          name
        }
        skills
      }
    }
  }
`;
