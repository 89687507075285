export const colors = {
  fontPrimary: "#1E1E1E",
  primaryBlue: "#02509A",
  lightBlueBackground: "#EDF4F8",
  lightBlueHeader: "#96c9fa",
  fontLight: "#555555",
  primary: "#1B7EB3",
  primaryLight: "#F4F7FC",
  secondary: "#FFFFFF",
  black: "#000000",
  lightGrey: "#ebebeb",

  success: "#11998E",
  warning: "#F09819",
  danger: "#FF5E62",
  // fontPrimary: "#000000",
  fontLighter: "rgba(85, 85, 85, 0.5)",
  lightGray: "#555555",
  primaryFill: "rgba(27, 126, 179, 0.09)",
  successFill: "rgba(17, 153, 142, 0.15)",
  warningFill: "rgba(240, 152, 25, 0.15)",
  dangerFill: "rgba(255, 94, 98, 0.15)",
  chartTitle: "#464255",
  blueBackGround: "#E2EEF5",
  borderColor: "#E3E3E3",
  lightBgColor: "#F7F7F7",
  MediumlightBgColor: "#DBDBDB",
  sucessGreen: "#20BC87",
  iconColor: "#949494",
  gray: "#444444",
  lighterGray: "#A4A5A8",
  strokeGray: "#F5F5F5",
  white: "#ffffff"
};
