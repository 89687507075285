import * as React from "react";
import { TextField, MenuItem } from "@mui/material";

interface INoBorderDropdownProps {
  value: string | number;
  onChange: any;
  bgColor: string;
  textColor: string;
  dropdownArr: any;
  fontSize?: string;
}

const NoBorderDropdown = (props: INoBorderDropdownProps) => {
  const { value, onChange, bgColor, textColor, dropdownArr, fontSize } = props;
  return (
    <TextField
      value={value}
      onChange={onChange}
      variant="outlined"
      select
      sx={{
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "none"
        },
        backgroundColor: bgColor
      }}
      size="small"
      inputProps={{
        sx: {
          color: textColor,
          fontSize: fontSize || "12px",
          fontWeight: 500,
          py: 0.75,
          px: 1.5
        }
      }}
    >
      {dropdownArr &&
        dropdownArr.map((item) => (
          <MenuItem key={item.id} value={item.id} sx={{ fontSize: "13px" }}>
            {item.name}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default NoBorderDropdown;
