import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete
} from "@mui/material";
import { colors } from "../../../styles/colors";
import {
  GET_INDUSTRIES,
  GET_SERVICES,
  GET_STATES,
  GET_CITIES,
  GET_ORGANIZATION_DROPDOWN_OPTIONS
} from "app/graphql/meta";
import { GET_ALL_LOCATIONS } from "app/graphql/users";
import { useQuery } from "@apollo/client";
import { partnerCategoryOptions } from "utils/constants";

interface FiltersProps {
  setFilter: any;
  filter: any;
  setQueryFilter: any;
}

const Filters = (props: FiltersProps) => {
  const { filter, setFilter } = props;

  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);
  const [statesArr, setStatesArr] = React.useState([]);
  const [citiesArr, setCitiesArr] = React.useState([]);
  const [partnerCatogry, setPartnerCatogry] = React.useState([]);
  const [organizationArr, setOrganizationArr] = React.useState([]);

  const { data: organizations } = useQuery(GET_ORGANIZATION_DROPDOWN_OPTIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (organizations && organizations?.organizationDropDown?.organization) {
      console.log(organizations);
      setOrganizationArr(
        organizations?.organizationDropDown?.organization || []
      );
    }
  }, [organizations]);

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {}
  });

  React.useEffect(() => {
    if (industries) {
      console.log(industries);
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" }
  });

  React.useEffect(() => {
    if (states) {
      console.log(states);
      setStatesArr(states?.states || []);
    }
  }, [states]);

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: filter?.stateId },
    skip: !filter?.stateId
  });

  React.useEffect(() => {
    if (cities) {
      console.log(cities);
      setCitiesArr(cities?.cities || []);
    }
  }, [cities]);

  const setFilterVariables = (key, val) => {
    setFilter({ ...filter, [key]: val });
  };

  const [errors, setErrors] = React.useState<any>({});

  // const validate = () => {
  //   let newErrors = {};
  //   let dirty = false;

  //   if (filter.minimumRemuneration__gte) {
  //     if (filter.minimumRemuneration__gte < 0) {
  //       newErrors = {
  //         ...newErrors,
  //         remuneration: "Min Remuneration cannot be less than 0"
  //       };

  //       dirty = true;
  //     }
  //   }

  //   if (filter.maximumRemuneration__lte) {
  //     if (filter.maximumRemuneration__lte < 0) {
  //       newErrors = {
  //         ...newErrors,
  //         remuneration: "Max Remuneration cannot be less than 0"
  //       };
  //       dirty = true;
  //     }
  //   }

  //   if (
  //     filter.minimumRemuneration__gte &&
  //     filter.maximumRemuneration__lte &&
  //     filter.maximumRemuneration__lte < filter.minimumRemuneration__gte
  //   ) {
  //     newErrors = {
  //       ...newErrors,
  //       remuneration: "Min Remuneration cannot be more than Max Remuneration"
  //     };
  //     dirty = true;
  //   }

  //   if (dirty) {
  //     setErrors(newErrors);
  //   } else {
  //     setErrors({});
  //   }

  //   return dirty;
  // };

  const { data: locations, loading: loadingLocations } = useQuery(
    GET_ALL_LOCATIONS,
    {
      variables: {}
    }
  );

  return (
    <Box sx={{ borderRadius: "4px", border: "1px solid #E3E3E3" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Typography fontSize="16px" color={colors.fontLight}>
            FILTERS
          </Typography>
        </Grid>
        <Grid item sx={{ cursor: "pointer" }}>
          <Typography
            fontSize="12px"
            color={colors.primary}
            sx={{ textDecoration: "underline" }}
            onClick={() => {
              setFilter({});
              // if (!validate()) {
              //   props.setQueryFilter({});
              // }
              props.setQueryFilter({});
            }}
          >
            Clear all filters
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{
          height: 1,
          backgroundColor: "#E9E9E9",
          width: "100%"
        }}
      />

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Client
          </Typography>
          <Autocomplete
            id="project__organizationId"
            value={
              filter.project__organizationId &&
              organizations &&
              organizations?.organizationDropDown &&
              organizations?.organizationDropDown?.organization?.length > 0
                ? organizations?.organizationDropDown?.organization?.find(
                    (item: any) => item?.id === filter.project__organizationId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("project__organizationId", value?.id);
            }}
            disableClearable={true}
            options={organizationArr || []}
            getOptionLabel={(option: any) => option.name}
            autoComplete
            sx={{
              backgroundColor: colors.white
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: "4px"
                }}
                placeholder="Choose Client"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Project category
          </Typography>
          <Autocomplete
            id="project__project_category__serviceId"
            autoComplete
            value={
              filter.project__project_category__serviceId &&
              servicesArr &&
              servicesArr?.length > 0
                ? servicesArr?.find(
                    (x: any) =>
                      x.id === filter.project__project_category__serviceId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables(
                "project__project_category__serviceId",
                value?.id
              );
            }}
            options={servicesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose category"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Industry
          </Typography>
          <Autocomplete
            id="project__industryId"
            autoComplete
            value={
              filter.project__industryId &&
              industriesArr &&
              industriesArr?.length > 0
                ? industriesArr?.find(
                    (x: any) => x.id === filter.project__industryId
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables("project__industryId", value?.id);
            }}
            options={industriesArr || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            // sx={{ "& .MuiAutocomplete-option": { backgrounColor: "red" } }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose industry"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Location
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            options={locations?.allCities || []}
            onChange={(_, value: any) =>
              setFilterVariables("project__cityId", value?.id)
            }
            value={
              locations?.allCities && filter.hasOwnProperty("project__cityId")
                ? locations.allCities.find(
                    (x) => x.id === filter["project__cityId"]
                  )
                : {}
            }
            getOptionLabel={(option) => option.name || ""}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose location "
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography fontSize="14px" color={colors.fontLight} mb={0.5}>
            Partner category
          </Typography>
          <Autocomplete
            id="project__preferredBpCategory__contains"
            autoComplete
            value={
              filter.project__preferredBpCategory__contains
                ? partnerCategoryOptions?.find(
                    (x: any) =>
                      x.id === filter.project__preferredBpCategory__contains
                  )
                : null
            }
            onChange={(event: any, value: any) => {
              console.log(value);
              setFilterVariables(
                "project__preferredBpCategory__contains",
                value?.id
              );
            }}
            options={partnerCategoryOptions || []}
            getOptionLabel={(option: any) => option.name || ""}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose partner category"
              />
            )}
          />
        </Grid>
        <Grid
          item
          container
          lg={2}
          md={2}
          sm={2}
          xs={2}
          justifyContent="center"
        >
          <Button
            variant="contained"
            sx={{ width: "70%", my: 2 }}
            onClick={() => {
              // if (!validate()) {
              //   props.setQueryFilter({ ...filter });
              // }
              props.setQueryFilter({ ...filter });
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
