import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Box
} from "@mui/material";
import { colors } from "../../../styles/colors";
import { withFormik } from "formik";
import * as Yup from "yup";

import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CITIES,
  GET_COUNTRIES,
  GET_INDUSTRIES,
  GET_SERVICES,
  GET_STATES
} from "app/graphql/meta";
import { useSnackbar } from "notistack";
import { bsuinessDevStatusArr, projectCategoryArr } from "utils/constants";
import {
  CREATE_ENQUIRY,
  GET_ENQUIRY,
  UPDATE_ENQUIRY
} from "app/graphql/Enquiry";
import getStore from "store/zustandStore";

const ViewEnquiryFormComponent = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setAddEnquiry
  } = props;

  const history = useHistory();

  console.log(errors);

  const [industriesArr, setIndustriesArr] = React.useState([]);
  const [servicesArr, setServicesArr] = React.useState([]);
  const [statesArr, setStatesArr] = React.useState([]);
  const [citiesArr, setCitiesArr] = React.useState([]);

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {}
  });

  React.useEffect(() => {
    if (industries) {
      console.log(industries);
      setIndustriesArr(industries?.industries || []);
    }
  }, [industries]);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {}
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: "1" }
  });

  React.useEffect(() => {
    if (states) {
      console.log(states);
      setStatesArr(states?.states || []);
    }
  }, [states]);

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: values.stateId },
    skip: !values.stateId
  });

  React.useEffect(() => {
    if (cities) {
      console.log(cities);
      setCitiesArr(cities?.cities || []);
    }
  }, [cities]);

  console.log("cityId", values.cityId);
  console.log("stateId", values.stateId);
  console.log("industryId", values.industryId);

  const [projectCategoryValue, setProjectCategoryValue] = React.useState<any>(
    values.projectCategoryId
  );
  const handleOther = (e, list) => {
    let arr = list?.map((item) => item.id.toString());
    setFieldValue("projectCategoryId", arr);
    console.log(list);
    setProjectCategoryValue(list);
  };

  console.log(values);

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: colors.primaryLight,
          borderRadius: "8px",
          pb: 3,
          mb: 1
        }}
        spacing={2.5}
      >
        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Client Name
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="companyName"
              size="small"
              fullWidth
              disabled={true}
              value={values.companyName}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("companyName", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.companyName && errors?.companyName && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.companyName}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              State
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Autocomplete
              id="stateId"
              disabled={true}
              value={
                states && states?.states && states?.states?.length > 0
                  ? states?.states?.find((x: any) => x.id === values?.stateId)
                  : undefined
              }
              // value={
              //   statesArr
              //     ? statesArr?.find((x: any) => x.id === values?.stateId)
              //     : null
              // }
              disableClearable={true}
              // options={
              //   states && states?.states && states?.states?.length > 0
              //     ? states
              //     : []
              // }
              options={statesArr || []}
              onChange={(event: any, value: any) => {
                console.log(value);
                setFieldValue("stateId", value?.id);
              }}
              // onBlur={handleBlur}
              getOptionLabel={(option: any) => option.name}
              sx={{
                backgroundColor: colors.white,
                width: "90%"
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onBlur={handleBlur}
                  sx={{
                    backgroundColor: colors.white,
                    borderRadius: "4px"
                  }}
                />
              )}
            />
            {touched?.stateId && errors?.stateId && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.stateId}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              City
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Autocomplete
              id="cityId"
              autoComplete
              disabled={true}
              disableClearable={true}
              value={
                cities && cities?.cities?.length > 0
                  ? cities?.cities?.find((x: any) => x.id === values?.cityId)
                  : null
              }
              // value={
              //   citiesArr
              //     ? citiesArr?.find((x: any) => x.id === values?.cityId)
              //     : null
              // }
              onChange={(_, value: any) => setFieldValue("cityId", value.id)}
              // options={cities && cities?.cities?.length > 0 ? cities : []}
              options={citiesArr || []}
              getOptionLabel={(option: any) => option.name}
              size="small"
              sx={{
                backgroundColor: colors.white,
                width: "90%"
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            {touched?.cityId && errors?.cityId && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.cityId}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Industry Type
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Autocomplete
              id="industryId"
              disabled={true}
              value={
                industries && industries?.industries?.length > 0
                  ? industries?.industries?.find(
                      (item: any) => item?.id === values.industryId
                    )
                  : null
              }
              // value={
              //   industriesArr?.find(
              //     (item: any) => item?.id === values.industryId
              //   ) || undefined
              // }
              onChange={(event: any, value: any) => {
                console.log(value);
                setFieldValue("industryId", value?.id);
              }}
              disableClearable={true}
              // options={
              //   industries && industries?.industries?.length > 0
              //     ? industries?.industries
              //     : []
              // }
              options={industriesArr || []}
              onBlur={handleBlur}
              getOptionLabel={(option: any) => option.name}
              autoComplete
              sx={{
                backgroundColor: colors.white,
                width: "90%"
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onBlur={handleBlur}
                  sx={{
                    backgroundColor: colors.white,
                    borderRadius: "4px"
                  }}
                />
              )}
            />
            {touched?.industryId && errors?.industryId && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.industryId}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Website Link
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Box width="100%">
              <TextField
                id="websiteLink"
                size="small"
                fullWidth
                disabled={true}
                value={values.websiteLink}
                onChange={(e) => {
                  if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                    setFieldValue("websiteLink", e.target.value);
                  }
                }}
                sx={{
                  backgroundColor: colors.white,
                  input: { color: colors.lightGray },
                  width: "90%"
                }}
                onBlur={handleBlur}
              />
              {touched?.websiteLink && errors?.websiteLink && (
                <Typography
                  sx={{
                    color: colors.danger,
                    fontSize: "11px",
                    fontWeight: 400,
                    mt: 0.5
                  }}
                >
                  {errors?.websiteLink}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Project Category
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Autocomplete
              id="projectCategoryId"
              multiple
              disabled={true}
              value={
                services && services?.services?.length > 0
                  ? services?.services?.filter((x: any) =>
                      values.projectCategoryId.includes(x.id)
                    )
                  : []
              }
              onChange={(e, values) => {
                setFieldValue(
                  "projectCategoryId",
                  values?.map((value) => value.id)
                );
              }}
              disableClearable={true}
              options={servicesArr || []}
              onBlur={handleBlur}
              getOptionLabel={(option: any) => option.name}
              autoComplete
              sx={{
                backgroundColor: colors.white,
                width: "90%"
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onBlur={handleBlur}
                  sx={{
                    borderRadius: "4px"
                  }}
                />
              )}
            />
            {touched?.projectCategoryId && errors?.projectCategoryId && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.projectCategoryId}
              </Typography>
            )}

            {values.projectCategoryId.includes("92") && (
              <Box mt={2} width={"100%"}>
                <TextField
                  id="otherNewCategory"
                  size="small"
                  fullWidth
                  disabled={true}
                  value={values.otherNewCategory}
                  onChange={(e) => {
                    if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                      setFieldValue("otherNewCategory", e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: colors.white,
                    input: { color: colors.lightGray },
                    width: "90%"
                  }}
                  onBlur={handleBlur}
                />
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Requirement
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="requirement"
              size="small"
              fullWidth
              disabled={true}
              value={values.requirement}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("requirement", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.requirement && errors?.requirement && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.requirement}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              SPOC Name
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="spocName"
              size="small"
              fullWidth
              disabled={true}
              value={values.spocName}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("spocName", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.spocName && errors?.spocName && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.spocName}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              SPOC Designation
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="spocDesignation"
              size="small"
              fullWidth
              disabled={true}
              value={values.spocDesignation}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("spocDesignation", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.spocDesignation && errors?.spocDesignation && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.spocDesignation}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              SPOC Email
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="spocEmail"
              size="small"
              fullWidth
              disabled={true}
              value={values.spocEmail}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("spocEmail", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.spocEmail && errors?.spocEmail && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.spocEmail}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              SPOC Mobile
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="spocPhone"
              size="small"
              fullWidth
              disabled={true}
              value={values.spocPhone}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("spocPhone", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.spocPhone && errors?.spocPhone && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.spocPhone}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pr={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              status
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <Autocomplete
              id="enquiryStatus"
              disabled={true}
              value={
                bsuinessDevStatusArr?.find(
                  (item: any) => item?.id === values.enquiryStatus
                ) || undefined
              }
              onChange={(event: any, value: any) => {
                console.log(value);
                setFieldValue("enquiryStatus", value?.id);
              }}
              disableClearable={true}
              options={bsuinessDevStatusArr}
              onBlur={handleBlur}
              getOptionLabel={(option: any) => option.name}
              autoComplete
              sx={{
                backgroundColor: colors.white,
                width: "90%"
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onBlur={handleBlur}
                  sx={{
                    borderRadius: "4px"
                  }}
                />
              )}
            />
            {touched?.enquiryStatus && errors?.enquiryStatus && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.enquiryStatus}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6} pl={2}>
          <Grid item container xs={12} sm={3.5} md={3.5} lg={3.5}>
            <Typography fontSize={15} fontWeight={400}>
              Comments
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={colors.danger}
            ></Typography>
          </Grid>
          <Grid item container xs={12} sm={8.5} md={8.5} lg={8.5}>
            <TextField
              id="comment"
              size="small"
              fullWidth
              disabled={true}
              multiline={true}
              rows={3.5}
              value={values.comment}
              onChange={(e) => {
                if (!/[^\x00-\x7F]+/.test(e.target.value)) {
                  setFieldValue("comment", e.target.value);
                }
              }}
              sx={{
                backgroundColor: colors.white,
                input: { color: colors.lightGray },
                width: "90%"
              }}
              onBlur={handleBlur}
            />
            {touched?.comment && errors?.comment && (
              <Typography
                sx={{
                  color: colors.danger,
                  fontSize: "11px",
                  fontWeight: 400,
                  mt: 0.5
                }}
              >
                {errors?.comment}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container mt={0.5}>
          {/* <Button
            size="small"
            variant="contained"
            sx={{
              width: "130px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              margin: "20px 0px",
              ml: "14px",
              color: "#FFF9F2"
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {values?.id ? "Update" : "Add"}
          </Button> */}
          <Button
            size="small"
            sx={{
              width: "120px",
              height: "38px",
              fontWeight: 400,
              fontSize: "16px",
              margin: "20px 0px",
              ml: "20px",
              color: "#555555",
              border: "1px solid #55555550"
            }}
            onClick={() => {
              setAddEnquiry(false);
            }}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const ViewEnquiry = (props: any) => {
  const { setAddEnquiry, refetchEnquiriesData, enquiryId } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const setLoading = getStore((state: any) => state.setLoading);

  const [enquiryDetails, setEnquiryDetails] = React.useState<any>({});

  const {
    data: enquiryDetailsData,
    loading: enquiryDetailsLoading,
    refetch: refecthEnquiryDetails
  } = useQuery(GET_ENQUIRY, {
    variables: {
      id: enquiryId
    },
    skip: !enquiryId,
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (enquiryDetailsData && enquiryDetailsData?.getEnquiry) {
      setEnquiryDetails(enquiryDetailsData?.getEnquiry);
    }
  }, [enquiryDetailsData]);

  const [createEnquiry, { loading: createEnquiryLoading }] = useMutation(
    CREATE_ENQUIRY,
    {
      onCompleted: (data) => {
        const { createEnquiry } = data;
        const { status, message } = createEnquiry;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          setAddEnquiry(false);
          refetchEnquiriesData();
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      },
      fetchPolicy: "network-only"
    }
  );

  const [updateEnquiry, { loading: updateEnquiryLoading }] = useMutation(
    UPDATE_ENQUIRY,
    {
      onCompleted: (data) => {
        const { updateEnquiry } = data;
        const { status, message } = updateEnquiry;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success"
          });
          setAddEnquiry(false);
          refetchEnquiriesData();
        } else {
          enqueueSnackbar(message, {
            variant: "error"
          });
        }
      },
      fetchPolicy: "network-only"
    }
  );

  React.useEffect(() => {
    setLoading(createEnquiryLoading || updateEnquiryLoading);
  }, [createEnquiryLoading, updateEnquiryLoading, setLoading]);

  console.log("enquiryDetails", enquiryDetails);
  const projectCategoryIds = enquiryDetails?.domain?.map(
    (item) => item?.service?.id
  );

  const otherNewCategoryObj = projectCategoryIds?.includes("92")
    ? enquiryDetails?.domain?.find((item) => item?.service?.id === "92")
    : "";

  console.log("otherNewCategoryObj", otherNewCategoryObj);

  const category = otherNewCategoryObj?.details
    ? JSON.parse(otherNewCategoryObj?.details)
    : "";

  console.log("category", category);

  const ViewEnquiryFormWrapped = withFormik({
    mapPropsToValues: () => ({
      id: enquiryDetails?.id || undefined,
      companyName: enquiryDetails?.companyName || "",
      stateId: enquiryDetails?.state?.id || "",
      cityId: enquiryDetails?.city?.id || "",
      industryId: enquiryDetails?.industryId || "",
      websiteLink: enquiryDetails?.websiteLink || "",
      projectCategoryId: projectCategoryIds || [],
      otherNewCategory: category?.category || "",
      requirement: enquiryDetails?.requirement || "",
      spocName: enquiryDetails?.spocName || "",
      spocDesignation: enquiryDetails?.spocDesignation || "",
      spocEmail: enquiryDetails?.spocEmail || "",
      spocPhone: enquiryDetails?.spocPhone || "",
      enquiryStatus: enquiryDetails?.enquiryStatus || "",
      comment: enquiryDetails?.comment || ""
    }),

    validationSchema: Yup.object().shape({
      companyName: Yup.string().required("This is a required field"),
      // countryId: Yup.string().required("Please select country").nullable(),
      stateId: Yup.string().required("This is a required field").nullable(),
      cityId: Yup.string().required("This is a required field").nullable(),
      industryId: Yup.string().required("This is a required field"),
      websiteLink: Yup.string().required("This is a required field").nullable(),
      // projectCategoryId: Yup.array()
      //   .required("This is a required field")
      //   .nullable(),
      requirement: Yup.string(),
      spocName: Yup.string().required("This is a required field").nullable(),
      spocDesignation: Yup.string().required("This is a required field"),
      spocEmail: Yup.string()
        .required("This is a required field")
        .email("Please enter a valid email id")
        .nullable(),
      spocPhone: Yup.string()
        .required("This is a required field")
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
        .nullable(),
      enquiryStatus: Yup.string()
        .required("This is a required field")
        .nullable(),
      comment: Yup.string()
    }),

    enableReinitialize: true,
    handleSubmit(values) {
      console.log("values", values);
      // const { id, projectCategoryId, otherNewCategory, ...rest } = values;

      // const domainModified = projectCategoryId?.map((item) => ({
      //   serviceId: item,
      //   ...(item === "92" && {
      //     details: JSON.stringify({ category: otherNewCategory })
      //   })
      // }));

      // if (id) {
      //   updateEnquiry({
      //     variables: {
      //       payload: {
      //         id,
      //         ...rest,
      //         domain: domainModified
      //       }
      //     }
      //   });
      // } else {
      //   createEnquiry({
      //     variables: {
      //       payload: {
      //         ...rest,
      //         domain: domainModified
      //       }
      //     }
      //   });
      // }
    }
  })(ViewEnquiryFormComponent);
  return <ViewEnquiryFormWrapped {...props} />;
};

export default ViewEnquiry;
