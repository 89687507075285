import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Box,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import MultipleAddComponent from "../../components/MultipleAddComponent";
import { colors } from "../../../styles/colors";

import { FormikProps, FieldArray } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CATEGORIES, GET_SERVICES } from "../../graphql/meta";
import ProfilePhoto from "./ProfilePhoto";
import AddEducationDetails from "./AddEducationDetails";
import AddWorkExperienceDetails from "./AddWorkExperienceDetails";
import AddProjectExperienceDetails from "./AddProjectExperienceDetails";
import AddCertificationDetails from "./AddCertificationDetails";
import { AddEditTableRowComponent } from "app/components/AddEditTableRowCompoent";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { CREATE_CONSULTANT, UPDATE_CONSULTANT } from "app/graphql/Profile";
import getStore from "store/zustandStore";
import dayjs from "dayjs";

interface IAddQualificationProps {
  editClicked: boolean;
  userQualifications: any;
  setEditClicked: any;
  errors: any;
}

const AddProfessionalDetailsComponent = (
  props: IAddQualificationProps & FormikProps<any>
) => {
  const {
    editClicked,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    userQualifications,
    setEditClicked,
  } = props;

  const [servicesArr, setServicesArr] = React.useState([]);
  const [value, setValue] = React.useState<any>(values.domainExpertise);

  const { data: services } = useQuery(GET_SERVICES, {
    variables: {},
  });

  React.useEffect(() => {
    if (services) {
      console.log(services);
      setServicesArr(services?.services || []);
    }
  }, [services]);

  console.log(errors);

  const handleDomainExpertise = (e, list) => {
    let arr = list?.map((item) => item.Id.toString());
    setFieldValue("domainExpertise", arr);
    console.log(list);
    setValue(list);
  };
  console.log("values", values);

  return (
    <Grid container spacing={2.5}>
      <Grid
        item
        lg={7}
        md={7}
        sm={12}
        xs={12}
        sx={{
          mb: -2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ProfilePhoto {...props} />
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Name<span style={{ color: colors.danger }}>*</span>
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <TextField
          id={"name"}
          value={values?.name || ""}
          onChange={(e) => {
            setFieldValue("name", e.target.value);
          }}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched?.name && errors?.name && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.name}
          </Typography>
        )}
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Brief Profile (100 words)
          <span style={{ color: colors.danger }}>*</span>
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <TextField
          id={"profile"}
          multiline
          rows={3}
          value={values?.profile || ""}
          onChange={(e) => {
            setFieldValue("profile", e.target.value);
          }}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched?.profile && errors?.profile && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.profile}
          </Typography>
        )}
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Years of experience<span style={{ color: colors.danger }}>*</span>
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <TextField
          id={"years"}
          value={values?.years || ""}
          onChange={(e) => {
            setFieldValue("years", e.target.value);
          }}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched?.years && errors?.years && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.years}
          </Typography>
        )}
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Education Details
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AddEducationDetails {...props} />
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Work Experience
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AddWorkExperienceDetails {...props} />
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Project Experience
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AddProjectExperienceDetails {...props} />
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Certification
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AddCertificationDetails {...props} />
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Domain Expertise<span style={{ color: colors.danger }}>*</span>
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Autocomplete
          id="domainExpertiseId"
          multiple // Enable multiple selection
          value={
            services && services?.services?.length > 0
              ? services?.services?.filter((x: any) =>
                  values.domainExpertiseId.includes(x.id)
                )
              : []
          }
          onChange={(e, values) => {
            setFieldValue(
              "domainExpertiseId",
              values?.map((value) => value.id)
            );
          }}
          options={servicesArr || []}
          getOptionLabel={(option: any) => option.name}
          size="small"
          fullWidth
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        {touched?.domainExpertiseId && errors?.domainExpertiseId && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.domainExpertiseId}
          </Typography>
        )}
      </Grid>

      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          LinkedIn Profile
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <TextField
          id={"linkedinProfile"}
          value={values?.linkedinProfile || ""}
          onChange={(e) => {
            setFieldValue("linkedinProfile", e.target.value);
          }}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched?.linkedinProfile && errors?.linkedinProfile && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.linkedinProfile}
          </Typography>
        )}
      </Grid>

      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Personal Website/ Blog
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <TextField
          id={"personalBlog"}
          value={values?.personalBlog || ""}
          onChange={(e) => {
            setFieldValue("personalBlog", e.target.value);
          }}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched?.personalBlog && errors?.personalBlog && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.personalBlog}
          </Typography>
        )}
      </Grid>

      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mb: -2 }}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Youtube/ Any other channel link
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <TextField
          id={"youtubeChannel"}
          value={values?.youtubeChannel || ""}
          onChange={(e) => {
            setFieldValue("youtubeChannel", e.target.value);
          }}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />{" "}
        {touched?.youtubeChannel && errors?.youtubeChannel && (
          <Typography
            sx={{
              color: colors.danger,
              fontSize: "11px",
              fontWeight: 400,
              mt: 0.5,
            }}
          >
            {errors.youtubeChannel}
          </Typography>
        )}
      </Grid>
      <Grid container spacing={2.5}>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{
            mt: 2.5,
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
          }}
        ></Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 2 }}>
          <div
            style={{
              height: 1,
              backgroundColor: "#E9E9E9",
              width: "100%",
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                sx={{ border: "1px solid #55555550", color: "#555555" }}
                disableElevation
                onClick={() => {
                  setEditClicked(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AddProfessionalDetails = (props) => {
  const {
    partnerUserId,
    selectedConsultant,
    setEditClicked,
    refetchProfessionalDetails,
    setSelectedConsultant,
  } = props;

  console.log("partnerUserId", partnerUserId);

  const { enqueueSnackbar } = useSnackbar();
  const setLoading = getStore((state: any) => state.setLoading);

  const [userOccupations, setUserOccupations] = React.useState([]);
  const [profileJSON, setProfileJSON] = React.useState<any>(null);
  const [userSkills, setUserSkills] = React.useState([]);
  const [userLanguages, setUserLanguages] = React.useState([]);
  const [userCertificates, setUserCertificates] = React.useState([]);
  const [userScholarships, setUserScholarships] = React.useState([]);
  const [uploadedVideoIntroduction, setUploadedVideoIntro] =
    React.useState<any>(null);

  const [createConsultant, { loading: createConsultantLoading }] = useMutation(
    CREATE_CONSULTANT,
    {
      onCompleted: (data) => {
        const { createConsultants } = data;
        const { status, message } = createConsultants;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          setEditClicked(false);
          refetchProfessionalDetails();
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
      fetchPolicy: "network-only",
    }
  );

  const [updateConsultant, { loading: updateConsultantLoading }] = useMutation(
    UPDATE_CONSULTANT,
    {
      onCompleted: (data) => {
        const { updateConsultant } = data;
        const { status, message } = updateConsultant;
        if (status) {
          enqueueSnackbar(message, {
            variant: "success",
          });
          setEditClicked(false);
          setSelectedConsultant({});
          refetchProfessionalDetails();
        } else {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      },
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    setLoading(createConsultantLoading || updateConsultantLoading);
  }, [createConsultantLoading, updateConsultantLoading, setLoading]);

  const education = selectedConsultant?.education
    ? JSON.parse(selectedConsultant?.education)
    : null;
  const workExperience = selectedConsultant?.workExperience
    ? JSON.parse(selectedConsultant?.workExperience)
    : null;
  const projects = selectedConsultant?.projects
    ? JSON.parse(selectedConsultant?.projects)
    : null;
  const certifications = selectedConsultant?.certification;
  // ? JSON.parse(selectedConsultant?.certification)
  // : null;

  console.log(selectedConsultant);

  const domainIds = selectedConsultant?.domain?.map(
    (item) => item?.service?.id
  );

  const AddProfessionalDetailsFormWrapped = withFormik({
    mapPropsToValues: () => ({
      id: selectedConsultant?.id || null,
      profilePhoto: null,
      name: selectedConsultant?.name || "",
      profile: selectedConsultant?.profile || "",
      years: selectedConsultant?.years || "",
      education: education ? education : [],
      workExperience: workExperience ? workExperience : [],
      projects: projects ? projects : [],
      certifications: certifications ? certifications : [],
      domainExpertiseId: domainIds || [],
      linkedinProfile: selectedConsultant?.linkdinProfile || "",
      personalBlog: selectedConsultant?.personalBlog || "",
      youtubeChannel: selectedConsultant?.youtubeChannel || "",
    }),

    validationSchema: Yup.object().shape({
      name: Yup.string().required("This is a required field"),
      profile: Yup.string().required("This is a required field"),
      years: Yup.number()
        .min(0, "Minimum possible value is 0")
        .max(85, "Please enter a valid input")
        .required("This is a required field")
        .typeError("Please enter a valid number"),
      domainExpertiseId: Yup.array()
        .min(1, "Choose atleast one Expertise level")
        .of(Yup.string().required("This field is required"))
        .nullable(),
      linkedinProfile: Yup.string(),
      personalBlog: Yup.string(),
      youtubeChannel: Yup.string(),
    }),

    enableReinitialize: true,

    handleSubmit(values) {
      console.log(values);
      console.log("partnerUserId", partnerUserId);

      const domainDetailsModified = values.domainExpertiseId?.map((item) => ({
        serviceId: item,
      }));

      const certificationsModified = values.certifications?.map((item) => {
        console.log(item);
        const { __typename, id: certificateId, issuedOn, ...rest } = item;
        const modifedDate = dayjs(issuedOn).format("YYYY-MM-DD");
        return { issuedOn: modifedDate, ...rest };
      });

      const payload = {
        name: values.name,
        profile: values.profile,
        ...(!isNaN(values.years) && {
          years: parseFloat(values.years),
        }),
        education: values?.education ? JSON.stringify(values?.education) : "",
        workExperience: values?.workExperience
          ? JSON.stringify(values?.workExperience)
          : "",
        projects: values?.projects ? JSON.stringify(values?.projects) : "",
        certification:
          values?.certifications && values?.certifications?.length > 0
            ? certificationsModified
            : [],
        // certification: JSON.stringify(temp),
        // certification: values?.certifications && values?.certifications?.length > 0
        //   ? JSON.stringify(values?.certifications)
        //   : null,
        domain: domainDetailsModified,
        linkdinProfile: values.linkedinProfile,
        personalBlog: values.personalBlog,
        youtubeChannel: values.youtubeChannel,
      };

      const { id } = values;

      if (id) {
        const payloadTemp = { id: values.id, ...payload };

        console.log(payloadTemp);
        updateConsultant({
          variables: {
            payload: payloadTemp,
            profile_picture: values.profilePhoto,
          },
        });
      } else {
        const payloadTemp = { userId: partnerUserId, ...payload };
        createConsultant({
          variables: {
            payload: payloadTemp,
            profile_picture: values.profilePhoto,
          },
        });
      }
    },
  })(AddProfessionalDetailsComponent);

  return (
    <AddProfessionalDetailsFormWrapped
      {...props}
      userQualifications={userOccupations}
      setEditClicked={setEditClicked}
    />
  );
};

export default AddProfessionalDetails;
